import Head from 'next/head';
import { signIn, useSession } from 'next-auth/react';
import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { getRuntimeEnvVar } from 'utils';
import {
  useAutomaticScroll,
  useCookieConsent,
  useMinimalUserInteraction,
  useRedirectOnAboIntent,
  useRedirectOnInvalidUser,
} from 'utils/hooks';

interface AppLogicWrapperProps 
{
  children?: React.ReactNode;
}

const AppLogicWrapper: React.FC<AppLogicWrapperProps> = (props) =>
{
  const { children } = props;

  const hotjarId = getRuntimeEnvVar('HOTJAR_ID') || 'HOTJAR_ID_MISSING';
  const deploymentScope = getRuntimeEnvVar('DEPLOYMENT_SCOPE');
  const mui = useMinimalUserInteraction();
  const { statistics } = useCookieConsent();
  const { data: session } = useSession();

  useRedirectOnAboIntent();
  useRedirectOnInvalidUser();
  useAutomaticScroll();

  useEffect(() =>
  {
    if (session?.error === 'RefreshAccessTokenError')
    {
      signIn(session.provider);
    }
  }, [session]);

  useEffect(() =>
  {
    if (statistics)
    {
      TagManager.initialize({
        gtmId: getRuntimeEnvVar('GOOGLE_ANALYTICS_ID') || 'GTM-000000',
        events: {
          event: 'gtm.js',
          'gtm.start': new Date().getTime(),
        },
      });
    }
  }, [statistics]);

  useEffect(() =>
  {
    function reloadOnChange() 
    {
      if ((window as any).Cookiebot.changed)
      {
        document.location.reload();
      }
    }
    window.addEventListener('CookiebotOnAccept', reloadOnChange);     
    window.addEventListener('CookiebotOnDecline', reloadOnChange);      

    return () => 
    {
      window.removeEventListener('CookiebotOnAccept', reloadOnChange);
      window.removeEventListener('CookiebotOnDecline', reloadOnChange);      
    };
  }, []);

  if (mui)
  {
    return (
      <>
        <Head>
            <script type="text/javascript" dangerouslySetInnerHTML={{ __html: `
              if(!window.cookiebotLoaded){
                window.cookiebotLoaded = true;
                const script = document.createElement("script");
                script.id = "Cookiebot";
                script.src = 'https://consent.cookiebot.com/uc.js';
                script.type = 'text/javascript';
                script.setAttribute('data-cbid', 'f443fe75-15db-4fe0-b810-94e4de8ea58d');
                script.setAttribute('data-framework', 'IAB');
                script.async = true;
                document.head.appendChild(script);
              }
            ` }}/>

          {deploymentScope === 'live' && (
            <script
              type='text/plain'
                data-cookieconsent="statistics">
              {`
                (function(h,o,t,j,a,r){
                  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                  h._hjSettings={hjid:${hotjarId},hjsv:6};
                  a=o.getElementsByTagName('head')[0];
                  r=o.createElement('script');r.async=1;
                  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                  a.appendChild(r);
                })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
              `}
            </script>
          )}
        </Head>
      
        {children}
      </>
    );
  }

  return (<>{ children }</>);
};

export default AppLogicWrapper;
