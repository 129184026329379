import Icon, { IconName } from 'components/atoms/Icon/Icon';
import React from 'react';


import css from './IconButton.module.scss';

interface IconButtonProps
{
  onClick?: () => void;
  icon: IconName;
  disabled?: boolean;
}

const IconButton: React.FC<IconButtonProps> = (props) =>
{
  const {
    onClick = () => {},
    icon,
    disabled = false,
  } = props;

  return (
    <button
      type="button"
      role="button"
      disabled={disabled}
      aria-disabled={disabled}
      onClick={onClick}
      className={css.iconButton}
    > 
      <Icon name={icon} />
    </button>
  );
};

export default IconButton;
