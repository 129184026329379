import { MutationFunction, useMutation } from '@tanstack/react-query';
import { createAxiosInstance } from 'api/request';
import type { CouponInfo } from 'types/user';
import { MutationHook } from 'utils/hooks/mutations/types';
import type { ProductKey } from 'utils/products';

type RequestReturnType = CouponInfo;
type RequestParameters = { product: ProductKey; code: string };

const request: MutationFunction<RequestReturnType, RequestParameters> = async ({ product, code }) =>
{
  const response = await createAxiosInstance().post('/coupon', { product, code });
  return response.data;
};

const useValidateCouponMutation: MutationHook<RequestReturnType, RequestParameters> = (options) =>
{
  return useMutation({
    mutationFn: request,
    ...options,
  });
};


export default useValidateCouponMutation;