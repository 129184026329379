import clsx from 'clsx';
import { Button } from 'components/atoms';
import { useRouter } from 'next/router';
import React from 'react';
import { useTranslation } from 'utils/hooks';


import css from './UserDataForm.module.scss';


const UpgradeAccountButton: React.FC = () =>
{
  const { t } = useTranslation('user');
  const router = useRouter();

  return (
    <section className={clsx(css.container, css.upgradeContainer)}>
      <Button
        type="button"
        variant="primary"
        onClick={() => router.push('/user/upgrade')}
      >
        {t('user:form.actions.upgrade')}
      </Button>
    </section>
  );
};

export default UpgradeAccountButton;
