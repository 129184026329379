import { useQueryClient } from '@tanstack/react-query';
import { Button, LabeledInput } from 'components/atoms';
import { FilterGroupsPersonen, FilterGroupsUnternehmen } from 'components/profiles/ZeitlicherVerlauf/FilterBlocks';
import FilterList from 'components/profiles/ZeitlicherVerlauf/FilterList/FilterList';
import React, { useRef } from 'react';
import { NotificationSettings } from 'types/user';
import { useTranslation } from 'utils/hooks';
import { useToggleEmailNotificationsMutation } from 'utils/hooks/mutations';
import useFilterEmailNotificationsMutation from 'utils/hooks/mutations/useFilterEmailNotificationsMutation';
import useNotificationSettingsQuery, {
  BASE_QUERY_KEY as NotificationQueryKey,
} from 'utils/hooks/queries/useNotificationSettingsQuery';



import css from './UserDataForm.module.scss';


const EmailNotificationsForm: React.FC = () => 
{
  const { t } = useTranslation(['user', 'profiles']);
  const queryClient = useQueryClient();
  const { data: settings } = useNotificationSettingsQuery();
  const saveButton = useRef<HTMLButtonElement | null>(null);

  const receiveEmailsMutation = useToggleEmailNotificationsMutation({
    onMutate: async ({ receiveEmails }) =>
    {
      await queryClient.cancelQueries({ queryKey: [NotificationQueryKey] });
      const previous = queryClient.getQueryData<NotificationSettings>([NotificationQueryKey]);
      if (previous === undefined) return undefined;

      queryClient.setQueryData<NotificationSettings>([NotificationQueryKey], () =>
        ({
          notifyOnFollowedProfileChange: receiveEmails,
          ignoredPersonenÄnderungsItemTypes: previous.ignoredPersonenÄnderungsItemTypes,
          ignoredUnternehmensÄnderungsItemTypes: previous.ignoredUnternehmensÄnderungsItemTypes,
        }));

      return { previous };
    },
    onError: (_, __, context: any) =>
    {
      queryClient.setQueryData([NotificationQueryKey], context.previous);
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: [NotificationQueryKey] }),
  });

  const filterEmailsMutation = useFilterEmailNotificationsMutation({
    onMutate: async ({ type, disabled }) => 
    {
      await queryClient.cancelQueries({ queryKey: [NotificationQueryKey] });
      const previous = queryClient.getQueryData<NotificationSettings>([NotificationQueryKey]);
      if (previous === undefined) return undefined;

      queryClient.setQueryData<NotificationSettings>([NotificationQueryKey], () =>
        ({
          notifyOnFollowedProfileChange: previous.notifyOnFollowedProfileChange,
          ignoredPersonenÄnderungsItemTypes: type === 'personen' ? disabled as FilterGroupsPersonen[] : previous.ignoredPersonenÄnderungsItemTypes,
          ignoredUnternehmensÄnderungsItemTypes: type === 'unternehmen' ? disabled as FilterGroupsUnternehmen[] : previous.ignoredUnternehmensÄnderungsItemTypes,
        }));

      return { previous };
    },
    onError: (_, __, context: any) => 
    {
      queryClient.setQueryData([NotificationQueryKey], context.previous);
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: [NotificationQueryKey] }),
  });

  const emailsEnabled = settings?.notifyOnFollowedProfileChange ?? false;
  const ignoredUnternehmenCategories = settings?.ignoredUnternehmensÄnderungsItemTypes ?? [];
  const ignoredPersonenCategories = settings?.ignoredPersonenÄnderungsItemTypes ?? [];

  return (
        <section id="notifications" className={css.container}>
            <div className={css.sectionHeading}>
                <h2>{t('user:form.notifications.heading')}</h2>
            </div>
            <LabeledInput
                id="useNotifications"
                label={emailsEnabled ? t('user:form.notifications.disable') : t('user:form.notifications.enable')}
                className={css.grid4}
                checkbox
            >
                <input
                    type="checkbox"
                    className="toggle"
                    checked={emailsEnabled}
                    onChange={() => receiveEmailsMutation.mutate({ receiveEmails: !emailsEnabled })}
                />
            </LabeledInput>
            {(emailsEnabled && <div>
                <h3>Unternehmen</h3>
                <FilterList filterType='unternehmen' activeFilters={ignoredUnternehmenCategories}
                            onActiveFiltersChange={(data) => filterEmailsMutation.mutate({
                              type: 'unternehmen',
                              disabled: data as FilterGroupsUnternehmen[],
                            })} toggleAble={false}/>
                <h3>Personen</h3>
                <FilterList filterType='person' activeFilters={ignoredPersonenCategories}
                            onActiveFiltersChange={(data) => filterEmailsMutation.mutate({
                              type: 'personen',
                              disabled: data as FilterGroupsPersonen[],
                            })} toggleAble={false}/>
                <div className={css.saveButtonContainer}><Button ref={saveButton} variant='primary' onClick={()=>saveButton.current?.blur()}>Speichern</Button></div>
            </div>)}
        </section>
  );
};

export default EmailNotificationsForm;
