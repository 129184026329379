import { atom, useAtom } from 'jotai';

const toastNotificationState = atom<string[]>([]);

type UseToastNotifications = [string[], (notification: string) => void];

export default (): UseToastNotifications => 
{
  const [notifications, setNotifications] = useAtom(toastNotificationState);

  const setWithTimeout = (notification: string) => 
  {
    setNotifications(current => [...current, notification]);
    setTimeout(() => 
    {
      setNotifications(current => [...current].slice(1));
    }, 3000);
  };

  return [notifications, setWithTimeout];
};