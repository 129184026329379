import clsx from 'clsx';
import Link from 'next/link';
import React from 'react';
import type { UrlObject } from 'url';

import buttonCss from '../Button/Button.module.scss';
import Icon from '../Icon/Icon';

import css from './Anchor.module.scss';

type AnchorProps = Omit<React.HTMLProps<HTMLAnchorElement>, 'href'> &
{
  href?: string | UrlObject;
  external?: boolean;
  as?: 'button';
  variant?: 'primary' | 'secondary' | 'subtle';
  asVariant?: 'primary' | 'secondary' | 'transparent';
};

const Anchor: React.FC<AnchorProps> = (props) => 
{
  const {
    href,
    external = false,
    children,
    as,
    asVariant = 'primary',
    variant = 'primary',
    className,
    ...rest
  } = props;

  const classes = clsx(
    css.anchor,
    css[variant],
    {
      [css.asButton]: as === 'button',
      [buttonCss.button]: as === 'button',
      [buttonCss[asVariant]]: as === 'button',
    },
    className,
  );

  if (!href && as === undefined && rest.onClick === undefined)
  {
    return (
      <span className={clsx(classes, css.foreign)} {...rest}>
        {children}
      </span>
    );
  }

  if (!href) 
  {
    return (
            <a className={classes} tabIndex={0} {...rest}>
                <>
                    {children}
                    {external && <Icon name="externalLink" className="inline"/>}
                </>
            </a>
    );
  }

  return (
        <Link legacyBehavior href={href} passHref prefetch={false}>
            <a className={classes} tabIndex={0} {...rest}>
                <>
                    {children}
                    {external && <Icon name="externalLink" className="inline"/>}
                </>
            </a>
        </Link>
  );
};

export default Anchor;
