import { Anchor, LabeledInput, Icon, Tooltip, RadioBar } from 'components/atoms';
import React, { forwardRef } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { FormErrors } from 'types';
import { UserRechtlicheDaten } from 'types/user';
import { useTranslation, useUserForm } from 'utils/hooks';
import { UserFormSectionRef } from 'utils/hooks/useUserForm';


import SectionHeading from './SectionHeading';
import css from './UserDataForm.module.scss';

export interface RechtlichesFormProps 
{
  onFormSubmit: (data: any) => Promise<boolean>;
  initialValues?: UserRechtlicheDaten;
  serverSideErrors?: FormErrors;
  reviewMode?: boolean;
  isDirektkauf?: boolean;
  isB2BOnly?: boolean;
  includesPayment?: boolean;
}

const RechtlichesForm = forwardRef<UserFormSectionRef, RechtlichesFormProps>((props, ref) => 
{
  const {
    initialValues = {},
    onFormSubmit,
    reviewMode = false,
    serverSideErrors,
    isDirektkauf = false,
    isB2BOnly = false,
    includesPayment = false,
  } = props;
  const { t, r } = useTranslation(['user', 'forms']);

  const parseDataOnSubmit = (data: UserRechtlicheDaten) => ({ rechtliches: data });

  const {
    editable,
    form: { handleSubmit, register, formState: { errors }, control, watch },
  } = useUserForm<UserRechtlicheDaten>(initialValues, reviewMode, serverSideErrors, ref, parseDataOnSubmit);

  const onSubmit: SubmitHandler<UserRechtlicheDaten> = async (data) => 
  {
    await onFormSubmit(parseDataOnSubmit(data));
  };

  const kundenArt = watch('kundenArt', 'Unternehmen');
  const isUnternehmenskunde = kundenArt === 'Unternehmen';

  return (
    <section id="rechtliches" className={css.container}>
      <form onSubmit={handleSubmit(onSubmit)}>

        {!isDirektkauf && <SectionHeading text={t('user:form.legal.heading')}/>}

        <div className={css.gridContainer}>
          {!isB2BOnly && (<div>
            <p>Ich verwende wirtschaft.at als:</p>
            <RadioBar
              control={control}
              name='kundenArt'
              disabled={!editable}
              rules={{ required: true }}
              options={[
                { label: 'Unternehmen', value: 'Unternehmen' },
                { label: 'Privatperson', value: 'Privatperson' },
              ]}
              defaultValue={'Unternehmen'}
            />
          </div>)}
          {/* AGBs */}
          <LabeledInput
            id="acceptAGBs"
            label={r(includesPayment ? 'user:form.legal.termsAndConditionsWithStripe' : 'user:form.legal.termsAndConditions', {
              terms: <Anchor href={isUnternehmenskunde ? '/agb' : '/agb-b2c'} target='_blank'/>,
              legal: <Anchor href='/datenschutz' target='_blank'/>,
            }, { agbName: isUnternehmenskunde ? t('user:form.legal.b2bAgb') : t('user:form.legal.b2cAgb') })}
            error={errors.acceptAGBs?.message}
            className={css.grid4}
            checkbox
          >
            <input
              type="checkbox"
              disabled={!editable}
              {...register('acceptAGBs', {
                required: { value: !reviewMode, message: t('forms:errors.required') },
              })}
            />
          </LabeledInput>

          {/* Widerruf */}
          {isDirektkauf && !isUnternehmenskunde && (<LabeledInput
            id="acceptWiderruf"
            label={<>
              {t('user:form.legal.widerruf')}
              <Tooltip
                side={'top'}
                align='center'
                content={<div style={{ maxWidth: '50ch' }}>{t('user:form.legal.widerrufLang')}</div>}
              >
                <span>
                  <Icon name="info"/>
                </span>
              </Tooltip>
            </>}
            error={errors.acceptWiderruf?.message}
            className={css.grid4}
            checkbox
          >
            <input
              type="checkbox"
              disabled={!editable}
              {...register('acceptWiderruf', {
                required: { value: !reviewMode, message: t('forms:errors.required') },
              })}
            />
          </LabeledInput>)}

        </div>
      </form>


    </section>
  );
});

export default RechtlichesForm;
