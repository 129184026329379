import { MutationFunction, useMutation } from '@tanstack/react-query';
import { createAxiosInstance } from 'api/request';
import { MutationHook } from 'utils/hooks/mutations/types';

type RequestReturnType = void;
type RequestParameters = void;

const request: MutationFunction<RequestReturnType, RequestParameters> = async () =>
{
  await createAxiosInstance().post('/user/freemium');
};

const useStayFreemiumMutation: MutationHook<RequestReturnType, RequestParameters> = (options) =>
{
  return useMutation({
    mutationFn: request,
    ...options,
  });
};


export default useStayFreemiumMutation;
