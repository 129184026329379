import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { createAxiosInstance } from 'api/request';
import { NotificationSettings } from 'types/user';
import { QueryHook } from 'utils/hooks/queries/types';

type RequestReturnType = NotificationSettings;

const request = async (signal?: AbortSignal) =>
{
  const response = await createAxiosInstance().get('/user/notifications', { signal });
  return response.data;
};

export const BASE_QUERY_KEY = 'notificationSettings';

const useNotificationSettingsQuery: QueryHook<RequestReturnType> = () =>
{
  return useQuery(
    {
      queryKey: [BASE_QUERY_KEY],
      queryFn: ({ signal }) => request(signal),
      refetchOnMount: false,
      placeholderData: keepPreviousData,
    },
  );
};

export default useNotificationSettingsQuery;