import React from 'react';

import About from './blocks/About';
import Blog from './blocks/Blog';
import DataInfo from './blocks/DataInfo';
import Divider from './blocks/Divider';
import FeaturedProfilesCard from './blocks/FeaturedProfilesCard';
import Grid from './blocks/Grid';
import NewsletterCard from './blocks/NewsletterCard';
import PersonalUpdates from './blocks/PersonalUpdates';
import Plus from './blocks/Plus';
import Socials from './blocks/Socials';
import Sources from './blocks/Sources';
import Statistics from './blocks/Statistics';
import StepsCta from './blocks/StepsCta';
import Subscriptions from './blocks/Subscriptions';
import css from './Startseite.module.scss';

const Startseite: React.FC<React.PropsWithChildren> = ({ children }) =>
{
  return (
    <div className={css.container}>
      {children}
    </div>
  );
};

// namespaced blocks
export default Object.assign(Startseite, {
  About,
  Blog,
  DataInfo,
  Divider,
  FeaturedProfilesCard,
  Grid,
  NewsletterCard,
  PersonalUpdates,
  Plus,
  Socials,
  Sources,
  Statistics,
  StepsCta,
  Subscriptions,
});
