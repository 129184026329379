import clsx from 'clsx';
import { Anchor, Icon, Referenz } from 'components/atoms';
import Link from 'next/link';
import React from 'react';
import { Referenz as ReferenzDto } from 'types';
import { useTranslation } from 'utils/hooks';

import css from '../Startseite.module.scss';

interface FeaturedProfilesCardProps
{
  profiles: ReferenzDto[];
  label: string;
  showAllHref?: string;
}

const FeaturedProfilesCard: React.FC<FeaturedProfilesCardProps> = ({ label, profiles, showAllHref }) =>
{
  const { t } = useTranslation();

  return (
    <div className={clsx(css.card, css.featuredProfiles)}>
      <h3> {showAllHref !== undefined ? (
        <Anchor href={showAllHref} variant={'subtle'}>
          {label}
        </Anchor>
      ) : label}</h3>
      <ul className={css.referenzList}>
        {profiles.map((profile) => (
          <Referenz key={profile.id} data={profile}>
            {({ ref, href, iconName }) => (
              <li>
                <Link href={href} prefetch={false}>
                    <Icon name={iconName} />
                    <span className={css.name}>{ref.name}</span>
                </Link>
              </li>
            )}
          </Referenz>
        ))}
      </ul>
      {showAllHref !== undefined && (
        <Anchor href={showAllHref} className={css.visit}>
          {t('startseite:featured.all')}
        </Anchor>
      )}
    </div>
  );
};

export default FeaturedProfilesCard;
