import { useRouter } from 'next/router';
import React from 'react';
import { deserializeQueryParam } from 'utils';
import { useTranslation } from 'utils/hooks';
import { TranslationKey } from 'utils/hooks/useTranslation';

import css from './Direktkauf.module.scss';

function calculateRemainingHours(expiresAt: string)
{
  const delta = new Date(expiresAt).getTime() - new Date().getTime();
  const seconds = Math.floor(delta / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  return hours;
}

const DirektkaufTokenBanner: React.FC = () =>
{
  const { r } = useTranslation();
  const router = useRouter();

  const isTokenPresent = deserializeQueryParam(router.query.token) !== undefined;
  const expiresAt = deserializeQueryParam(router.query.expiresAt);
  const tokenError = deserializeQueryParam(router.query.tokenError);

  if (!isTokenPresent || tokenError === 'InvalidToken')
  {
    return null;
  }

  return (
    <div className={css.tokenBanner}>
      {expiresAt && <p>{r('direktkauf:banner.Valid', { hours: calculateRemainingHours(expiresAt) })}</p>}
      {tokenError && <p>{r(`direktkauf:banner.${tokenError}` as TranslationKey)}</p>}
    </div>
  );
};

export default DirektkaufTokenBanner;
