import { LabeledInput } from 'components/atoms';
import React, { forwardRef, useEffect } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { FormErrors } from 'types';
import { UserUnternehmensDaten } from 'types/user';
import { useTranslation, useUserForm } from 'utils/hooks';
import { UserFormSectionRef } from 'utils/hooks/useUserForm';

import FormActions from './FormActions';
import SectionHeading from './SectionHeading';
import css from './UserDataForm.module.scss';

export interface UnternehmensDatenFormProps
{
  onFormSubmit: (data: any) => Promise<boolean>;
  initialValues?: Partial<UserUnternehmensDaten>;
  reviewMode?: boolean;
  serverSideErrors?: FormErrors;
}

export interface UnternehmensDatenFormFields extends UserUnternehmensDaten
{}

const UnternehmensDatenForm = forwardRef<UserFormSectionRef, UnternehmensDatenFormProps>((props, ref) =>
{
  const { reviewMode = false, initialValues = {}, onFormSubmit, serverSideErrors } = props;
  const { t } = useTranslation(['user', 'forms']);


  const parseDataOnSubmit = (data: UnternehmensDatenFormFields) =>
  {
    const { ...sanitized } = data;

    return ({ unternehmensDaten: sanitized });
  };

  const istAusgefüllt = !!initialValues;

  const defaultValues: Partial<UnternehmensDatenFormFields> = {
    ...initialValues,
  };

  const {
    editable,
    setEditable,
    cancelEditing,
    form: { handleSubmit, register, setValue, resetField, clearErrors, formState: { errors } },
  } = useUserForm<UnternehmensDatenFormFields>(defaultValues, reviewMode, serverSideErrors, ref, parseDataOnSubmit);


  const onSubmit: SubmitHandler<UnternehmensDatenFormFields> = async (data) =>
  {
    const success = await onFormSubmit(parseDataOnSubmit(data));
    if (success) setEditable(!reviewMode);
  };

  useEffect(() =>
  {
    resetField('fbNr');
    setValue('fbNr', '');
    clearErrors('fbNr');
  }, [setValue]);

  return (
    <section id="usage" className={css.container}>
      <form onSubmit={handleSubmit(onSubmit)}>

            <SectionHeading
              text={t('user:form.companyData.heading')}
              editable={editable}
              setEditable={setEditable}
              istAusgefüllt={istAusgefüllt}
            />

            {(istAusgefüllt || editable) && (
            <div className={css.gridContainer}>
              {/* Firmenname */}
              <LabeledInput
                id="firmenname"
                label={t('user:form.companyData.name.label')}
                error={errors.firmenname?.message}
                className={css.grid2}
              >
                <input
                  type="text"
                  readOnly={!editable}
                  autoComplete="organization"
                  placeholder={editable ? t('user:form.companyData.name.placeholder') : ''}
                  {...register('firmenname', {
                    required: t('forms:errors.required'),
                  })}
                />
              </LabeledInput>
              <div className={css.grid2} />

              {/* FbNr */}
              <LabeledInput
                id="fbNr"
                label={t('user:form.companyData.fbNr.label')}
                error={errors.fbNr?.message}
                className={css.grid2}
              >
                <input
                  type="text"
                  readOnly={!editable}
                  autoComplete="off"
                  placeholder={editable ? t('user:form.companyData.fbNr.placeholder') : '---'}
                  {...register('fbNr')}
                />
              </LabeledInput>

              {/* UID */}
              <LabeledInput
                id="uid"
                label={t('user:form.companyData.uid.label')}
                error={errors.uid?.message}
                className={css.grid2}
              >
                <input
                  type="text"
                  readOnly={!editable}
                  autoComplete="off"
                  placeholder={editable ? t('user:form.companyData.uid.placeholder') : '---'}
                  {...register('uid')}
                />
              </LabeledInput>

              </div>
            )}

        <FormActions
          cancelEditing={cancelEditing}
          visible={reviewMode && editable}
        />
    
      </form>
    </section>
  );
});

export default UnternehmensDatenForm;
