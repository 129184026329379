import { LabeledInput } from 'components/atoms';
import React, { forwardRef } from 'react';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { FormErrors } from 'types';
import { RechnungsAdresse } from 'types/user';
import { useTranslation, useUserForm } from 'utils/hooks';
import { UserFormSectionRef } from 'utils/hooks/useUserForm';

import AdresseForm from './AdresseForm';
import FormActions from './FormActions';
import SectionHeading from './SectionHeading';
import css from './UserDataForm.module.scss';

export interface RechnungsAdresseFormProps
{
  onFormSubmit: (data: any) => Promise<boolean>;
  initialValues?: RechnungsAdresse | null;
  reviewMode?: boolean;
  serverSideErrors?: FormErrors;
  hideHeading?: boolean;
}

export interface RechnungsAdresseFormFields extends RechnungsAdresse
{
  useRechnungsAdresse: boolean;
}

const RechnungsAdresseForm = forwardRef<UserFormSectionRef, RechnungsAdresseFormProps>((props, ref) =>
{
  const { reviewMode = false, initialValues = {}, onFormSubmit, serverSideErrors, hideHeading } = props;
  const { t } = useTranslation('user');

  const parseDataOnSubmit = (data: RechnungsAdresseFormFields) =>
  {
    const { useRechnungsAdresse, ...sanitized } = data;

    if (sanitized.anrede === 'NotSelected')
    {
      sanitized.anrede = null;
    }

    return ({ rechnungsAdresse: useRechnungsAdresse ? sanitized : null, removeRechnungsAdresse: !useRechnungsAdresse });
  };

  const istAusgefüllt = !!initialValues;
  
  const defaultValues = {
    useRechnungsAdresse: istAusgefüllt,
    ...initialValues,
  };

  const {
    editable,
    setEditable,
    cancelEditing,
    form,
    form: { handleSubmit, register, watch, formState: { errors } },
  } = useUserForm<RechnungsAdresseFormFields>(defaultValues, reviewMode, serverSideErrors, ref, parseDataOnSubmit);

  const useRechnungsAdresse = watch('useRechnungsAdresse');

  const onSubmit: SubmitHandler<RechnungsAdresseFormFields> = async (data) =>
  {
    const success = await onFormSubmit(parseDataOnSubmit(data));
    if (success) setEditable(!reviewMode);
  };

  return (
      // @ts-ignore
      <FormProvider {...form}>
      <section id="rechnungsAdresse" className={css.container}>
        <form onSubmit={handleSubmit(onSubmit)}>

        {!hideHeading && (
          <SectionHeading
            text={t('user:form.billingAddress.heading')}
            editable={editable}
            setEditable={setEditable}
            istAusgefüllt={istAusgefüllt}
          />
        )}

          {(istAusgefüllt || editable) && (
          <div className={css.gridContainer}>

            {/* Optionale Rechnungsadresse */}
            <LabeledInput
              id="useRechnungsAdresse"
              label={t('user:form.billingAddress.useBillingAddress')}
              className={css.grid4}
              checkbox
            >
              <input
                type="checkbox"
                disabled={!editable}
                className="toggle"
                {...register('useRechnungsAdresse')}
              />
            </LabeledInput>

            {useRechnungsAdresse && (
              <>

                {/* Anrede */}
                <LabeledInput
                  id="anrede"
                  label={t('user:form.billingAddress.salution.label')}
                  error={errors.anrede?.message}
                  className={css.grid2}
                >
                  <>{editable ? <select
                      {...register('anrede')}
                      defaultValue="NotSelected"
                      disabled={!editable}
                    >
                      <option value="NotSelected">{t('user:form.billingAddress.salution.values.NotSelected')}</option>
                      <option value="Firma">{t('user:form.billingAddress.salution.values.Firma')}</option>
                      <option value="Frau">{t('user:form.billingAddress.salution.values.Frau')}</option>
                      <option value="Herr">{t('user:form.billingAddress.salution.values.Herr')}</option>
                      <option value="Divers">{t('user:form.billingAddress.salution.values.Divers')}</option>
                      <option value="KeineAngabe">{t('user:form.billingAddress.salution.values.KeineAngabe')}</option>
                    </select> :
                    <input
                      {...register('anrede')}
                      type="text"
                      readOnly={true}
                      autoComplete="off"
                      placeholder={ '---'}
                    />
                  }</>
                </LabeledInput>

                <div className={css.grid2}/>

                {/* Empfänger */}
                <LabeledInput
                  id="rechnungsAdresseEmpfänger"
                  label={t('user:form.billingAddress.recipient.label')}
                  error={errors.empfänger?.message}
                  className={css.grid2}
                >
                  <input
                    type="text"
                    readOnly={!editable}
                    autoComplete="off"
                    placeholder={editable ? t('user:form.billingAddress.recipient.placeholder') : '---'}
                    {...register('empfänger')}
                  />
                </LabeledInput>

                {/* Email */}
                <LabeledInput
                  id="rechnungsAdresseEmail"
                  label={t('user:form.billingAddress.email.label')}
                  error={errors.email?.message}
                  className={css.grid2}
                >
                  <input
                    type="email"
                    readOnly={!editable}
                    autoComplete="off"
                    placeholder={editable ? t('user:form.billingAddress.email.placeholder') : '---'}
                    {...register('email')}
                  />
                </LabeledInput>

                <AdresseForm editable={editable} />

                {/* Interne Referenz */}
                <LabeledInput
                  id="interneReferenz"
                  label={t('user:form.billingAddress.internalReference.label')}
                  error={errors.interneReferenz?.message}
                  className={css.grid2}
                >
                  <input
                    type="text"
                    readOnly={!editable}
                    autoComplete="off"
                    placeholder={editable ? t('user:form.billingAddress.internalReference.placeholder') : '---'}
                    {...register('interneReferenz')}
                  />
                </LabeledInput>

              </>
            )}

          </div>
          )}

        <FormActions
          cancelEditing={cancelEditing}
          visible={reviewMode && editable}
        />

        </form>
      </section>
    </FormProvider>
  );
});

export default RechnungsAdresseForm;
