import { MutationFunction, useMutation } from '@tanstack/react-query';
import { createAxiosInstance } from 'api/request';
import { ReferenzType } from 'types';
import { MutationHook } from 'utils/hooks/mutations/types';

type RequestReturnType = void;
type RequestParameters = { id: string; type: ReferenzType; wantsToFollow: boolean };

const request: MutationFunction<RequestReturnType, RequestParameters> = async ({ id, type, wantsToFollow }) =>
{
  if (wantsToFollow)
  {
    await createAxiosInstance().post('/user/subscriptions', { id, type });
  }
  else
  {
    await createAxiosInstance().delete(`/user/subscriptions/${id}`);
  }
};

const useToggleFollowProfileMutation: MutationHook<RequestReturnType, RequestParameters> = (options) =>
{
  return useMutation({
    mutationFn: request,
    ...options,
  });
};


export default useToggleFollowProfileMutation;