import { QueryFunction, useQuery, useQueryClient } from '@tanstack/react-query';
import { createAxiosInstance } from 'api/request';
import { useSession } from 'next-auth/react';
import { useEffect } from 'react';
import { User } from 'types/user';
import { QueryHook } from 'utils/hooks/queries/types';

type RequestReturnType = User;

const request: QueryFunction<RequestReturnType> = async ({ signal }) =>
{
  const response = await createAxiosInstance().get('/user', { signal });
  return response.data;
};

const useUserQuery: QueryHook<RequestReturnType> = () =>
{
  const { data: session, status } = useSession();
  const queryClient = useQueryClient();

  useEffect(() =>
  {
    if (status !== 'authenticated')
    {
      queryClient.setQueryData(['user'], undefined);
    }

    queryClient.invalidateQueries({ queryKey: ['user'] });
  }, [status, session?.expires, queryClient]);

  return useQuery({
    queryKey: ['user'],
    queryFn: request,
    enabled: status === 'authenticated',
    gcTime: 60 * 60 * 1000,
    refetchOnMount: false,
  });
};

export default useUserQuery;