import { useQuery } from '@tanstack/react-query';
import { getWizardSearchResults } from 'api/search';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { WizardResultBase } from 'types/wizard';
import { deserializeQueryParam } from 'utils';
import { useBrowserStorage } from 'utils/hooks';

const useSearchWizard = <T extends WizardResultBase<any>>(
  type: 'unternehmen' | 'personen' | 'marken',
  filters: Record<string, string>,
  defaultValue: T,
) =>
{
  const router = useRouter();
  const [cache, setCache] = useBrowserStorage<Record<string, Record<string, number>>>('wss.detailsuche-cache', {});
  const [data, setData] = useState<T>({ ...defaultValue, count: cache[getCacheKey()] ?? (defaultValue as any).count });
  
  const view = deserializeQueryParam(router.query.view) || type;
  const page = parseInt(deserializeQueryParam(router.query.page) || '1', 10);
  const limit = parseInt(deserializeQueryParam(router.query.limit) || '15', 10);

  const {
    data: apiData,
    isPending: isLoading,
    refetch: fetchData,
  } = useQuery({
    queryKey:['wizard', type, filters, view, page, limit],
    queryFn: () => getWizardSearchResults<T>(type, filters, view, cache[getCacheKey()] === undefined, page, limit),
  });

  const resultCount = Object.entries(data.count || {}).reduce((count, [key, value]) => key.endsWith('ItemCount') ? count + value : count, 0);
  const categoryCount = Object.entries(data.count || {}).filter(([key, value]) => key.endsWith('ItemCount') && value !== 0).length;

  useEffect(() =>
  {
    fetchData();
  }, [router]);

  useEffect(() =>
  {
    if (!apiData) return;

    if ((apiData as any).count)
    {
      setCache({ ...cache, [getCacheKey()]: (apiData as any).count });
    }

    const newData = Object.entries(apiData).reduce((prev, [key, value]) => ({ ...prev, [key]: value ?? (data as any)[key] }), {}) as T;
    setData({ ...newData, count: cache[getCacheKey()] ?? (apiData as any).count });
  }, [apiData]);

  function getCacheKey(): string
  {
    return Object.entries({ type, ...filters }).map(([key, value]) => `${key}=${value}`).join(';');
  }

  function reset()
  {
    setData(defaultValue);
  }

  return {
    view,
    page,
    limit,
    data,
    isLoading,
    reset,
    count: { results: resultCount, categories: categoryCount },
  };
};

export default useSearchWizard;