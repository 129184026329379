import { LabeledInput } from 'components/atoms';
import React, { forwardRef } from 'react';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { RechnungsAdresse } from 'types/user';
import { useTranslation, useUserForm } from 'utils/hooks';
import { UserFormSectionRef } from 'utils/hooks/useUserForm';

import AdresseForm from './AdresseForm';
import css from './UserDataForm.module.scss';

export interface RechnungsAdresseDirektkaufFormProps
{
  onFormSubmit: (data: any) => Promise<boolean>;
}

export interface RechnungsAdresseDirektkaufFormFields extends RechnungsAdresse
{
  useRechnungsAdresse: boolean;
}

const RechnungsAdresseDirektkaufForm = forwardRef<UserFormSectionRef, RechnungsAdresseDirektkaufFormProps>((props, ref) =>
{
  const { onFormSubmit } = props;
  const { t } = useTranslation('user');

  const parseDataOnSubmit = (data: RechnungsAdresseDirektkaufFormFields) =>
  {
    const { useRechnungsAdresse, ...sanitized } = data;

    if (sanitized.anrede === 'NotSelected')
    {
      sanitized.anrede = null;
    }

    return ({ rechnungsAdresse: useRechnungsAdresse ? sanitized : null });
  };
  
  const {
    form,
    form: { handleSubmit, register, watch, formState: { errors } },
  } = useUserForm<RechnungsAdresseDirektkaufFormFields>({}, false, {}, ref, parseDataOnSubmit);

  const useRechnungsAdresse = watch('useRechnungsAdresse');

  const onSubmit: SubmitHandler<RechnungsAdresseDirektkaufFormFields> = async (data) =>
  {
    await onFormSubmit(parseDataOnSubmit(data));
  };

  return (
    <FormProvider {...form}>
      <section id="rechnungsAdresse" className={css.container}>
        <form onSubmit={handleSubmit(onSubmit)}>

        <div className={css.gridContainer}>

          {/* Optionale Rechnungsadresse */}
          <LabeledInput
            id="useRechnungsAdresse"
            label={t('user:form.billingAddress.useBillingAddress')}
            className={css.grid4}
            checkbox
          >
            <input
              type="checkbox"
              className="toggle"
              {...register('useRechnungsAdresse')}
            />
          </LabeledInput>

          {useRechnungsAdresse && (
            <>

              {/* Anrede */}
              <LabeledInput
                id="anrede"
                label={t('user:form.billingAddress.salution.label')}
                error={errors.anrede?.message}
                className={css.grid2}
              >
                <select
                  {...register('anrede')}
                  defaultValue="NotSelected"
                >
                  <option value="NotSelected">{t('user:form.billingAddress.salution.values.NotSelected')}</option>
                  <option value="Firma">{t('user:form.billingAddress.salution.values.Firma')}</option>
                  <option value="Frau">{t('user:form.billingAddress.salution.values.Frau')}</option>
                  <option value="Herr">{t('user:form.billingAddress.salution.values.Herr')}</option>
                  <option value="Divers">{t('user:form.billingAddress.salution.values.Divers')}</option>
                  <option value="KeineAngabe">{t('user:form.billingAddress.salution.values.KeineAngabe')}</option>
                </select>
              </LabeledInput>

              {/* Empfänger */}
              <LabeledInput
                id="rechnungsAdresseEmpfänger"
                label={t('user:form.billingAddress.recipient.labelDirektkauf')}
                error={errors.empfänger?.message}
                className={css.grid2}
              >
                <input
                  type="text"
                  autoComplete="off"
                  placeholder={t('user:form.billingAddress.recipient.placeholderDirektkauf')}
                  {...register('empfänger', {
                    required: t('forms:errors.required'),
                  })}
                />
              </LabeledInput>

              <AdresseForm editable />

              {/* Interne Referenz */}
              <LabeledInput
                id="interneReferenz"
                label={t('user:form.billingAddress.internalReference.label')}
                error={errors.interneReferenz?.message}
                className={css.grid2}
              >
                <input
                  type="text"
                  autoComplete="off"
                  placeholder={t('user:form.billingAddress.internalReference.placeholder')}
                  {...register('interneReferenz')}
                />
              </LabeledInput>

            </>
          )}
          
        </div>
        </form>
      </section>
    </FormProvider>
  );
});

export default RechnungsAdresseDirektkaufForm;
