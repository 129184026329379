import { useCallback, useEffect, useRef, useState } from 'react';

function useStateCallback<T>(initialState: T) 
{
  const [state, setState] = useState<T>(initialState);
  const callbackRef = useRef<(() => void) | null>(null);

  const setStateCallback = useCallback((newState: T, cb?: () => void) => 
  {
    callbackRef.current = cb || null;
    setState(newState);
  }, []);

  useEffect(() => 
  {
    if (callbackRef.current) 
    {
      callbackRef.current();
      callbackRef.current = null;
    }
  }, [state]);

  return [state, setStateCallback] as const;
}

export default useStateCallback;