import { Anchor, Logo } from 'components/atoms';
import { Head } from 'components/molecules';
import { useRouter } from 'next/router';
import React from 'react';


import css from './CustomErrorPage.module.scss';

// ############################################################################
// Die Texte sind hier hardcoded da NextJS in der momentanen Version (12.2)
// bei custom Error Pages keine Data-Fetching Methoden erlaubt.
// Somit können keine Translations geladen werden.
// ############################################################################

const CustomErrorPage: React.FC = () =>
{
  const router = useRouter();

  return (
    <>
      <Head title='Ein Fehler ist aufgetreten!'>
        <meta name='robots' content='noindex' />
      </Head>

      <div className={css.container}>
        <div className={css.content}>

          <div className={css.logoContainer}>
            <Logo />
          </div>

          <div>
            <h1>Ein Fehler ist aufgetreten!</h1>
            <p>Beim Verarbeiten Ihrer Anfrage ist ein Fehler aufgetreten.<br />Bitte probieren Sie es später erneut.</p>
            <Anchor onClick={router.back}>Zurück zur vorherigen Seite</Anchor>
          </div>

        </div>
      </div>
    </>
  );
};

export default CustomErrorPage;
