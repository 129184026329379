import React from 'react';
import { useTranslation } from 'utils/hooks';

import css from '../Startseite.module.scss';

const Sources: React.FC = () =>
{
  const { t } = useTranslation();
  return (
    <div className={css.sources}>
      <h3>{t('startseite:sources.heading')}</h3>
      <ul>
          {(t('startseite:sources.list', { returnObjects: true }) as string[]).map((item) => (
          <li key={item}>{item}</li>
          ))}
      </ul>
    </div>
  );
};

export default Sources;
