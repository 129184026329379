import React, { ReactElement } from 'react';

interface ShowProps 
{
  when?: boolean;
  whenNot?: boolean;
  fallback?: ReactElement<any, any>;
  children: React.ReactNode;
}

const Show: React.FC<ShowProps> = (props) => 
{
  const { when = true, whenNot = false, fallback, children } = props;
  return (!when || whenNot) ? (fallback || null) : <>{children}</>;
};

export default Show;
