import clsx from 'clsx';
import React, { ReactNode } from 'react';

import css from './LabeledInput.module.scss';

interface LabeledInputProps
{
  id: string;
  label: string | ReactNode | ReactNode[];
  error?: string;
  info?: string | ReactNode | ReactNode[];
  checkbox?: boolean;
  className?: string;
  variant?: 'grid' | 'standalone';
  children?: React.ReactNode;
}

const LabeledInput: React.FC<LabeledInputProps> = (props) =>
{
  const {
    id,
    label,
    error,
    info,
    checkbox = false,
    variant = 'grid',
    className,
    children,
  } = props;

  if (checkbox)
  {
    return (
      <div className={clsx(css.checkbox, css[variant], className)}>
        {error && <small className={css.error}>{error}</small>}
        {info && !error && <small>{info}</small>}
        <div className={css.row}>
          {React.Children.only(React.cloneElement(children as any, { id }))}
          <label htmlFor={id}>{label}</label>
        </div>
      </div>
    );
  }

  return (
    <div className={clsx(css.container, css[variant], className)}>
      <label htmlFor={id}>{label}</label>
      {React.Children.only(React.cloneElement(children as any, { id }))}
      {error && <small className={css.error}>{error}</small>}
      {info && !error && <small>{info}</small>}
    </div>
  );
};

export default LabeledInput;
