import React from 'react';
import type { PersonalFeedItem } from 'types/änderungen';

import css from './PersonalFeed.module.scss';
import PersonalFeedItemUI from './PersonalFeedItem';

interface PersonalFeedProps
{
  feed: PersonalFeedItem[];
  isPreview?: boolean;
}

const PersonalFeed: React.FC<PersonalFeedProps> = (props) =>
{
  const { feed, isPreview } = props;

  return (
    <ul className={css.container}>
      {[...feed].sort((a, b) => b.date.localeCompare(a.date)).map((item) => (
        <PersonalFeedItemUI
          key={item.id}
          isPreview={isPreview}
          item={item}
        />
      ))}
    </ul>
  );
};

export default PersonalFeed;
