import { Anchor, LabeledInput } from 'components/atoms';
import React, { forwardRef } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { FormErrors } from 'types';
import { UserNewsletterDaten } from 'types/user';
import { useTranslation, useUserForm } from 'utils/hooks';
import { UserFormSectionRef } from 'utils/hooks/useUserForm';

import FormActions from './FormActions';
import SectionHeading from './SectionHeading';
import css from './UserDataForm.module.scss';

export interface NewsletterFormProps
{
  onFormSubmit: (data: any) => Promise<boolean>;
  initialValues?: UserNewsletterDaten;
  reviewMode?: boolean;
  serverSideErrors?: FormErrors;
}

const NewsletterForm = forwardRef<UserFormSectionRef, NewsletterFormProps>((props, ref) =>
{
  const { reviewMode = false, initialValues = {}, onFormSubmit, serverSideErrors } = props;
  const { t, r } = useTranslation('user');

  const parseDataOnSubmit = (data: UserNewsletterDaten) => ({ newsletter: data });

  const defaultValues = {
    ...initialValues,
    recieveAll: (initialValues as UserNewsletterDaten).recieveAll || false,
  };

  const {
    editable,
    setEditable,
    cancelEditing,
    form: { handleSubmit, register, formState: { errors } },
  } = useUserForm<UserNewsletterDaten>(defaultValues, reviewMode, serverSideErrors, ref, parseDataOnSubmit);

  const onSubmit: SubmitHandler<UserNewsletterDaten> = async (data) =>
  {
    const success = await onFormSubmit(parseDataOnSubmit(data));
    if (success) setEditable(!reviewMode);
  };

  return (   
    <section id="newsletter" className={css.container}>
      <form onSubmit={handleSubmit(onSubmit)}>

        <SectionHeading
          text={t('user:form.newsletter.heading')}
          editable={editable}
          setEditable={setEditable}
        />

        <div className={css.gridContainer}>

          {/* Newsletter */}
          <LabeledInput
            id="newsletterInput"
            label={r('user:form.newsletter.label', { legal: <Anchor href='/datenschutz' /> })}
            error={errors.recieveAll?.message}
            className={css.grid4}
            checkbox
          >
            <input
              type="checkbox"
              disabled={!editable}
              {...register('recieveAll')}
            />
          </LabeledInput>

        </div>

        <FormActions
          cancelEditing={cancelEditing}
          visible={reviewMode && editable}
        />

      </form>
    </section>
  );
});

export default NewsletterForm;
