import { MutationFunction, useMutation } from '@tanstack/react-query';
import { createAxiosInstance } from 'api/request';
import { MutationHook } from 'utils/hooks/mutations/types';

type RequestReturnType = void;
type RequestParameters = { id: string };

const request: MutationFunction<RequestReturnType, RequestParameters> = async ({ id }) =>
{
  await createAxiosInstance().post(`/feed/${id}/markAsRead`);
};

const useMarkNotificationAsReadMutation: MutationHook<RequestReturnType, RequestParameters> = (options) =>
{
  return useMutation({
    mutationFn: request,
    ...options,
  });
};


export default useMarkNotificationAsReadMutation;
