import { createAxiosInstance } from 'api/request';
import { SearchCount, SuggestResults } from 'types/suche';


export async function getSuggestions(term: string, signal?: AbortSignal): Promise<SuggestResults>
{
  const response = await createAxiosInstance({ timeout: 120_000 })
    .get(`/search/suggest?term=${encodeURIComponent(term)}`, { signal });

  return response.data;
}

export async function getSearchCount(term: string, signal?: AbortSignal): Promise<SearchCount>
{
  const response = await createAxiosInstance({ timeout: 120_000 })
    .get(`/search/count?term=${encodeURIComponent(term)}`, { signal });

  return response.data;
}

export async function getWizardSearchResults<T>(
  type: string,
  filters: Record<string, string>,
  view: string,
  count: boolean = true,
  page: number = 1,
  limit: number = 25,
): Promise<T>
{
  const params = new URLSearchParams({
    view,
    page: page.toString(),
    limit: limit.toString(),
    count: count ? 'true' : 'false',
    ...filters,
  });

  const response = await createAxiosInstance({ timeout: 120_000 }).get(`/search/wizard/${type}?${params}`);
  return response.data;
}