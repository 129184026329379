import { useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { Icon, Referenz } from 'components/atoms';
import Link from 'next/link';
import React from 'react';
import { FeedItem, PersonalFeedItem, PersonenFeedItemType, UnternehmensFeedItemType } from 'types/änderungen';
import { useTranslation } from 'utils/hooks';
import { useMarkNotificationAsReadMutation } from 'utils/hooks/mutations';
import { BASE_QUERY_KEY as NotificationCountQueryKey } from 'utils/hooks/queries/useNotificationCountQuery';
import { TranslationKey } from 'utils/hooks/useTranslation';


import css from './PersonalFeed.module.scss';

const NUM_ÄNDERUNGEN_IN_PREVIEW = 2;

interface PersonalFeedItemProps
{
  item: PersonalFeedItem;
  isPreview?: boolean;
}

const PersonalFeedItemUI: React.FC<PersonalFeedItemProps> = (props) =>
{
  const { item, isPreview } = props;
  const { t, r } = useTranslation(['user', 'änderungen', 'common']);

  const queryClient = useQueryClient();
  const mutation = useMarkNotificationAsReadMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [NotificationCountQueryKey] }),
  });
  
  const translatePersonenItem = (feedItem: FeedItem<PersonenFeedItemType>) =>
  {
    const { type, payload } = feedItem;
    return r(`änderungen:feed.person.${type}`, undefined, { payload });
  };

  const translateUnternehmensItem = (feedItem: FeedItem<UnternehmensFeedItemType>) =>
  {
    const { type, payload } = feedItem;
    let preProcessedPayload = payload;

    switch (type)
    {
      case UnternehmensFeedItemType.AufsichtsratAdded:
      case UnternehmensFeedItemType.AufsichtsratChanged:
      case UnternehmensFeedItemType.AufsichtsratDeleted:
      case UnternehmensFeedItemType.FunktionAdded:
      case UnternehmensFeedItemType.FunktionChanged:
      case UnternehmensFeedItemType.FunktionDeleted:
      {
        const art = t(`funktionen:list.${payload.art}` as TranslationKey);
        preProcessedPayload = { ...preProcessedPayload, art };
        break;
      }
    }

    return r(`änderungen:feed.unternehmen.${type}`, undefined, { payload: preProcessedPayload });
  };

  const translateFunc = item.ref.type === 'Unternehmen' ? translateUnternehmensItem : translatePersonenItem;

  const onItemClick = () =>
  {
    if (!item.isRead)
    {
      mutation.mutate({ id: item.id });
    }
  };

  return (
    <li>
      <Referenz data={item.ref}>
        {({ ref, href, iconName }) => (
          <Link legacyBehavior href={`${href}/plus/verlauf`} passHref>
            <a
              className={clsx(css.item, { [css.read]: item.isRead })}
              onClick={onItemClick}
            >
    
              <div className={css.iconContainer}>
                <Icon name={iconName} />
              </div>
    
              <div className={css.content}>
                <span className={css.name}>{t('user:feed.item.title', { name: ref.name, date: item.date })}</span>
                <ul>
                  {[...item.items].splice(0, isPreview ? NUM_ÄNDERUNGEN_IN_PREVIEW : item.items.length).map((feedItem, index) => (
                    <li key={item.date + index}>{translateFunc(feedItem as any)}</li>
                  ))}
                  {isPreview && item.items.length > NUM_ÄNDERUNGEN_IN_PREVIEW && (
                    <li>{r('common:generic.andNMore', { n: item.items.length - NUM_ÄNDERUNGEN_IN_PREVIEW })}</li>
                  )}
                </ul>
              </div>
              
              <span className={css.details}>
                {!isPreview && <span>{t('common:actions.details')}</span>}
                <Icon name='chevronRight' />
              </span>
      
            </a>
          </Link>
        )}
      </Referenz>
    </li>
  );
};

export default PersonalFeedItemUI;
