import { IdProvider } from '@radix-ui/react-id';
import { Provider as TooltipProvider } from '@radix-ui/react-tooltip';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AppLogicWrapper } from 'components/molecules';
import nextI18NextConfig from 'i18n/next-i18n.config';
import countries from 'i18n-iso-countries';
import { Provider as JotaiProvider } from 'jotai';
import type { AppProps } from 'next/app';
import { SessionProvider } from 'next-auth/react';
import { appWithTranslation } from 'next-i18next';
import NextNProgress from 'nextjs-progressbar';
import 'styles/global.scss';
import 'styles/inputs.scss';
import { PlusPageProvider } from 'utils/context/plusPage';
import { MuiProvider } from 'utils/hooks/useMinimalUserInteraction';


// Register locales for localized country lists
countries.registerLocale(require('i18n-iso-countries/langs/de.json'));

const queryClient = new QueryClient();

// @ts-ignore
const App = ({ Component, pageProps: { session, ...pageProps }, router }: AppProps) =>
{
  const iframe = router.pathname === '/captable/[id]';

  if (iframe)
  {
    return  <PlusPageProvider> <Component {...pageProps} /></PlusPageProvider>;
  }
  return (
    <IdProvider>
      <QueryClientProvider client={queryClient}>
        <SessionProvider session={session}>
          <JotaiProvider>
            <TooltipProvider>
              <PlusPageProvider>
                <MuiProvider>
                    <AppLogicWrapper>

                      {/* Styling for progress bar found in global.scss */}
                      <NextNProgress options={{ showSpinner: false }} showOnShallow={false} />

                      {/* Main Component */}
                      <Component {...pageProps} />
                      
                    </AppLogicWrapper>
                </MuiProvider>
              </PlusPageProvider>
            </TooltipProvider>
          </JotaiProvider>
        </SessionProvider>
      </QueryClientProvider>
    </IdProvider>
  );
};

export default appWithTranslation(App, nextI18NextConfig);
