import { MutationFunction, useMutation } from '@tanstack/react-query';
import { createAxiosInstance } from 'api/request';
import { MutationHook } from 'utils/hooks/mutations/types';

type RequestReturnType = void;
type RequestParameters = { email: string; name?: string };

const request: MutationFunction<RequestReturnType, RequestParameters> = async ({ email, name }) =>
{
  await createAxiosInstance().post('/newsletter', { email, name });
};

const useSubscribeToNewsletterMutation: MutationHook<RequestReturnType, RequestParameters> = (options) =>
{
  return useMutation({
    mutationFn: request,
    ...options,
  });
};


export default useSubscribeToNewsletterMutation;
