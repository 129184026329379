import { Anchor, Button } from 'components/atoms';
import React from 'react';
import { useTranslation } from 'utils/hooks';

import css from './UserDataForm.module.scss';

interface FormActionsProps
{
  cancelEditing: () => void;
  visible: boolean;
}

const FormActions: React.FC<FormActionsProps> = (props) =>
{
  const { cancelEditing, visible } = props;
  const { t } = useTranslation('user');

  if (!visible) return null;

  return (
    <div className={css.sectionActions}>
      <Anchor onClick={cancelEditing}>{t('user:form.actions.cancel')}</Anchor>
      <Button
      type="submit">{t('user:form.actions.save')}</Button>
    </div>
  );
};

export default FormActions;
