const intervalPlural = require('i18next-intervalplural-postprocessor');
const path = require('path');

const format = require('./format');

module.exports = {
  i18n: {
    locales: ['de'],
    defaultLocale: 'de',
  },
  defaultNS: 'common',
  localePath: path.resolve('./public/static/locales'),
  serializeConfig: false,
  reloadOnPrerender: process.env.NODE_ENV !== 'production',
  interpolation: {
    escapeValue: false,
    format,
  },
  use: [intervalPlural],
};