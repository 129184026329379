import { Anchor } from 'components/atoms';
import Image from 'next/legacy/image';
import React from 'react';
import { isTestEnvironment } from 'utils';
import { useTranslation } from 'utils/hooks';

import css from '../Startseite.module.scss';

const Plus: React.FC = () =>
{
  const { t, r } = useTranslation();
  return (
    <section className={css.section}>
      <h2>{t('startseite:plus.heading')}</h2>
      <div className={css.plus}>
        <div className={css.left}>
          <p>{r('startseite:plus.description')}</p>
          
          <ul>
            {(t('startseite:plus.list', { returnObjects: true }) as string[]).map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
          
          {isTestEnvironment() && (<>
            <Anchor as="button" href="/u/demo-123456v/plus">
              {t('startseite:plus.demo')}
            </Anchor>
                <p className={css.or}>{t('startseite:plus.or')}</p>
          </>)}
          <Anchor as="button" href="/produkte">
            {isTestEnvironment() ? t('startseite:plus.cta') : 'Jetzt Produkte vergleichen'}
          </Anchor>

        </div>

        <Image
          src="/static/images/plus.png"
          alt=""
          width={512}
          height={366}
          draggable={false}
          objectFit="contain"
        />
      </div>
    </section>
  );
};

export default Plus;
