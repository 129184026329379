import { QueryFunction, useQuery } from '@tanstack/react-query';
import { createAxiosInstance } from 'api/request';
import { WssStatistics } from 'types/api';
import { QueryHook } from 'utils/hooks/queries/types';

type RequestReturnType = WssStatistics;

const request: QueryFunction<RequestReturnType> = async ({ signal }) =>
{
  const response = await createAxiosInstance().get('/statistics', { signal });
  return response.data;
};

export const BASE_QUERY_KEY = 'statistics';

const useStatisticsQuery: QueryHook<RequestReturnType> = () =>
{
  return useQuery({
    queryKey: [BASE_QUERY_KEY],
    queryFn: request,
    gcTime: 60 * 60 * 1000,
  });
};

export default useStatisticsQuery;