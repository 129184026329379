import FilterFillIcon from '@remixicons/react/fill/FilterIcon';
import MoneyEuroCircleIcon from '@remixicons/react/fill/MoneyEuroCircleIcon';
import MoreIcon from '@remixicons/react/fill/MoreIcon';
import StarFilledIcon from '@remixicons/react/fill/StarIcon';
import AccountCircleIcon from '@remixicons/react/line/AccountCircleIcon';
import AddIcon from '@remixicons/react/line/AddIcon';
import ArrowDownIcon from '@remixicons/react/line/ArrowDownIcon';
import ArrowDownSIcon from '@remixicons/react/line/ArrowDownSIcon';
import ArrowLeftIcon from '@remixicons/react/line/ArrowLeftIcon';
import ArrowLeftSIcon from '@remixicons/react/line/ArrowLeftSIcon';
import ArrowRightIcon from '@remixicons/react/line/ArrowRightIcon';
import ArrowRightSIcon from '@remixicons/react/line/ArrowRightSIcon';
import ArrowUpSIcon from '@remixicons/react/line/ArrowUpSIcon';
import AwardIcon from '@remixicons/react/line/AwardIcon';
import BankIcon from '@remixicons/react/line/BankIcon';
import BuildingIcon from '@remixicons/react/line/BuildingIcon';
import CheckDoubleIcon from '@remixicons/react/line/CheckDoubleIcon';
import CheckIcon from '@remixicons/react/line/CheckIcon';
import CloseIcon from '@remixicons/react/line/CloseIcon';
import ExternalLinkIcon from '@remixicons/react/line/ExternalLinkIcon';
import FilterLineIcon from '@remixicons/react/line/FilterIcon';
import FullscreenExitIcon from '@remixicons/react/line/FullscreenExitIcon';
import FullscreenIcon from '@remixicons/react/line/FullscreenIcon';
import GlobalIcon from '@remixicons/react/line/GlobalIcon';
import HistoryIcon from '@remixicons/react/line/HistoryIcon';
import InformationIcon from '@remixicons/react/line/InformationIcon';
import LinksIcon from '@remixicons/react/line/LinksIcon';
import Lock2Icon from '@remixicons/react/line/Lock2Icon';
import LockUnlock from '@remixicons/react/line/LockUnlockIcon';
import LogoutCircleRIcon from '@remixicons/react/line/LogoutCircleRIcon';
import MailSend from '@remixicons/react/line/MailSendIcon';
import RadarIcon from '@remixicons/react/line/RadarIcon';
import RefreshIcon from '@remixicons/react/line/RefreshIcon';
import ScissorsIcon from '@remixicons/react/line/ScissorsIcon';
import SearchIcon from '@remixicons/react/line/SearchIcon';
import SeedlingIcon from '@remixicons/react/line/SeedlingIcon';
import Settings4Icon from '@remixicons/react/line/Settings4Icon';
import StarIcon from '@remixicons/react/line/StarIcon';
import SubtractIcon from '@remixicons/react/line/SubtractIcon';
import clsx from 'clsx';
import React from 'react';

import PushPinFill from './icons/fill/PushPin';
import Mindmap from './icons/line/Mindmap';
import OrganizationChart from './icons/line/OrganizationChart';
import PushPinLine from './icons/line/PushPin';
import Shapes from './icons/line/Shapes';


const IconDict = {
  arrowLeft: ArrowLeftIcon,
  arrowRight: ArrowRightIcon,
  arrowDown: ArrowDownIcon,
  building: BuildingIcon,
  bank: BankIcon,
  close: CloseIcon,
  dots: MoreIcon,
  externalLink: ExternalLinkIcon,
  info: InformationIcon,
  person: AccountCircleIcon,
  plus: AddIcon,
  minus: SubtractIcon,
  search: SearchIcon,
  lock: Lock2Icon,
  unlock: LockUnlock,
  moneyEuroCircle: MoneyEuroCircleIcon,
  chevronLeft: ArrowLeftSIcon,
  chevronRight: ArrowRightSIcon,
  chevronUp: ArrowUpSIcon,
  chevronDown: ArrowDownSIcon,
  minimize: FullscreenExitIcon,
  maximize: FullscreenIcon,
  star: StarIcon,
  starFilled: StarFilledIcon,
  link: LinksIcon,
  settings: Settings4Icon,
  logout: LogoutCircleRIcon,
  check: CheckIcon,
  checkDouble: CheckDoubleIcon,
  premium: AwardIcon,
  radar: RadarIcon,
  global: GlobalIcon,
  history: HistoryIcon,
  seedling: SeedlingIcon,
  refresh: RefreshIcon,
  scissors: ScissorsIcon,
  profile: OrganizationChart,
  pinLine: PushPinLine,
  pinFill: PushPinFill,
  filterLine: FilterLineIcon,
  filterFill: FilterFillIcon,
  mindmap: Mindmap,
  shapes: Shapes,
  mail: MailSend,
};

export type IconName = keyof typeof IconDict;

interface IconProps 
{
  name: IconName;
  className?: string;
}

const Icon: React.FC<IconProps> = (props) => 
{
  const { name, className } = props;
  const IconComponent = IconDict[name];

  return (
        <IconComponent className={clsx('icon', className)}/>
  );
};

export default Icon;
