import { useQueryClient } from '@tanstack/react-query';
import { Anchor, Button, Dialog } from 'components/atoms';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { UserStatus } from 'types/user';
import { useToastNotifications, useTranslation } from 'utils/hooks';
import { useStayFreemiumMutation } from 'utils/hooks/mutations';
import { useStripePaymentPortalSessionQuery } from 'utils/hooks/queries';


type StatusDialogProps = {
  status: UserStatus;
};

const StatusDialog: React.FC<StatusDialogProps> = ({ status }) =>
{
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const { data: stripePaymentPortalSession } = useStripePaymentPortalSessionQuery();
  const queryClient = useQueryClient();
  const stayFreemiumMutation = useStayFreemiumMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['user'] }),
  });
  const router = useRouter();
  const [, addToastNotification] = useToastNotifications();
  
  function changePaymentData()
  {
    if (!stripePaymentPortalSession)
    {
      addToastNotification(t('user:payment.errorOpenPortal'));
      return;
    }

    router.push(stripePaymentPortalSession);
  }

  useEffect(() =>
  {
    setOpen([UserStatus.InvalidPayment, UserStatus.FirstPaymentRequired].includes(status));
  }, [status]);

  const freeButton = <Anchor key='free' variant='primary' onClick={() => stayFreemiumMutation.mutate()}>{t('user:form.actions.downgrade')}</Anchor>;

  const upgradeButton = <Anchor key='upgrade' as='button' asVariant='primary'
                                href='/user/upgrade'>{t('user:form.actions.upgrade')}</Anchor>;

  const paymentDataButton = <Button onClick={() => changePaymentData()}>
    {t('user:payment.changeData')}
  </Button>;

  const actionButtons = [
    freeButton,
    status === UserStatus.FirstPaymentRequired ? upgradeButton : null,
    status === UserStatus.InvalidPayment ? paymentDataButton : null,
  ];

  return <Dialog
    open={isOpen}
    onOpenChange={setOpen}
    header={t('user:form.konto.header')}
    actions={actionButtons}
  >
    {status === UserStatus.FirstPaymentRequired && t('user:form.konto.firstPayment')}
    {status === UserStatus.InvalidPayment && t('user:form.konto.invalidPayment')}
  </Dialog>;
};

export default StatusDialog;
