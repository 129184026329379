import clsx from 'clsx';
import React, { forwardRef, PropsWithChildren } from 'react';

import css from './Button.module.scss';

interface ButtonProps 
{
  onClick?: () => void;
  variant?: 'primary' | 'secondary' | 'transparent';
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
}

const Button = forwardRef<HTMLButtonElement, PropsWithChildren<ButtonProps>>((props, ref) => 
{
  const {
    onClick = () => 
    {
    },
    variant = 'primary',
    type = 'button',
    disabled = false,
    children,
  } = props;

  return (
        <button
            type={type}
            role="button"
            disabled={disabled}
            aria-disabled={disabled}
            onClick={onClick}
            className={clsx(css.button, { [css[variant]]: !disabled })}
            ref={ref}
        >
            {children}
        </button>
  );
});

export default Button;
