import { ForwardedRef, useEffect, useImperativeHandle, useState } from 'react';
import type { DeepPartial, UnpackNestedValue } from 'react-hook-form';
import { FieldValues, Path, useForm } from 'react-hook-form';
import { FormErrors } from 'types';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useTranslation } from 'utils/hooks';
import { TranslationKey } from 'utils/hooks/useTranslation';

export interface UserFormSectionRef
{
  submit: () => Promise<null | Record<string, any>>;
}

const useUserForm = <T extends FieldValues>(
  defaultValues: UnpackNestedValue<DeepPartial<T>>,
  reviewMode: boolean,
  serverSideErrors: FormErrors = {},
  ref: ForwardedRef<UserFormSectionRef> = null,
  parseDataOnSubmit: (data: UnpackNestedValue<T>) => Record<string, any> = (data) => data,
  mode: 'register' | 'update' | 'view' = 'register',
) =>
{
  const { t } = useTranslation(['forms']);

  const [editable, setEditable] = useState<boolean>(!reviewMode);

  // @ts-ignore
  const form = useForm<T>({ defaultValues, mode: 'onChange', reValidateMode: 'onChange' });

  // update editable state if reviewMode changes
  useEffect(() => setEditable(!reviewMode), [reviewMode]);

  // set errors when server side errors update
  useDeepCompareEffect(() =>
  {
    form.clearErrors();
    Object.entries(serverSideErrors).forEach((entry) =>
    {
      const [key, errors] = entry;

      let message = t(`forms:errors.${errors[0]}` as TranslationKey) as string;

      if (mode === 'update' && errors[0] === 'emailInUse')
      {
        message = t('forms:errors.emailInUseProfile');
      }

      form.setError(key as Path<T>, { type: 'value', message }, { shouldFocus: true });
    });
  }, [serverSideErrors]);

  // reset form if default values change
  useDeepCompareEffect(() =>
  {
    form.clearErrors();
    // @ts-ignore
    form.reset({ ...defaultValues });
  }, [defaultValues]);

  async function submit(): Promise<null | Record<string, any>>
  {
    let values = null;
    // @ts-ignore
    await form.handleSubmit((data) => values = parseDataOnSubmit(data))();
    return values;
  }

  function cancelEditing()
  {
    // @ts-ignore
    form.reset({ ...defaultValues });
    setEditable(false);
  }

  // expose submit function to reference
  useImperativeHandle(ref, () => ({ submit }));

  return { editable, setEditable, submit, cancelEditing, form };
};

export default useUserForm;