import clsx from 'clsx';
import React, { ReactNode } from 'react';
import { useTranslation } from 'utils/hooks';
import { useStatisticsQuery } from 'utils/hooks/queries';

import css from './Statistics.module.scss';

interface StatisticsProps {}

interface Statistic 
{
  key: string;
  label: string | ReactNode | ReactNode[];
  value?: number;
  type: 'additive' | 'total' | 'subtractive';
}

const Statistics: React.FC<StatisticsProps> = () => 
{
  const { t, r } = useTranslation(['dashboard', 'formats']);
  const { data } = useStatisticsQuery(); 

  const statistics: Statistic[] = [
    { key: 'unternehmenChangesLastWeek', label: t('dashboard:tiles.statistics.unternehmenChangesLastWeek'), type: 'total', value: data?.unternehmenChangesLastWeek },
    { key: 'countUnternehmen', label: r('dashboard:tiles.statistics.countUnternehmen'), type: 'total', value: data?.countUnternehmen },
    { key: 'countPersonen', label: r('dashboard:tiles.statistics.countPersonen'), type: 'total', value: data?.countPersonen },
  ];

  function getFormattedValue(stat: Statistic) 
  {
    if (stat.value === undefined) return '-';

    const prefix = (() => 
    {
      switch (stat.type) 
      {
        case 'additive':
          return '+ ';
        case 'subtractive':
          return '- ';
        default:
          return '';
      }
    })();

    return `${prefix}${t('formats:statistic', { value: stat.value })}`;
  }

  return (
    <div className={clsx('selection-on-muted', css.container)}>
      {statistics.map((stat) => (
        <div key={stat.key} className={css.statistic}>
          <span>{getFormattedValue(stat)}</span>
          {stat.label}
        </div>
      ))}
    </div>
  );
};

export default Statistics;
