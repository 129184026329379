import clsx from 'clsx';
import { Button, Disclaimer, Show } from 'components/atoms';
import Image from 'next/legacy/image';
import { useRouter } from 'next/router';
import React from 'react';
import { UserStatus } from 'types/user';
import { useToastNotifications, useTranslation } from 'utils/hooks';
import { usePaymentInfoQuery, useStripePaymentPortalSessionQuery, useUserQuery } from 'utils/hooks/queries';

import css from './UserDataForm.module.scss';

const PaymentForm: React.FC = () =>
{
  const { t } = useTranslation('user');
  const router = useRouter();
  const [, addToastNotification] = useToastNotifications();
  const { data: user } = useUserQuery();

  const { data: paymentInfo, isError } = usePaymentInfoQuery();
  const { data: stripePaymentPortalSession } = useStripePaymentPortalSessionQuery();

  function changePaymentData()
  {
    if (!stripePaymentPortalSession)
    {
      addToastNotification(t('user:payment.errorOpenPortal'));
      return;
    }
    
    router.push(stripePaymentPortalSession);
  }

  return (
    <section id="payment" className={clsx(css.container, css.paymentForm)}>

      <div className={clsx(css.sectionHeading, css.paymentInfo)}>
        <h2>{t('user:payment.heading')}</h2>
        <Image
          src="/static/images/stripe.svg"
          width={100}
          height={40}
          alt="Stripe Logo"
          draggable={false}
        />
      </div>

      <Show when={user?.status && user.status === UserStatus.FirstPaymentRequired}>
        <Disclaimer text={t('user:payment.firstPaymentRequired')} />
      </Show>

      <Show when={user?.status && user.status === UserStatus.InvalidPayment}>
        <Disclaimer text={t('user:payment.invalidPayment')} />
      </Show>

      <Show when={!user?.status || (user.status && ![UserStatus.FirstPaymentRequired, UserStatus.Freemium].includes(user.status))}>
        <div>
          {isError && (
            <p>{t('user:payment.errorLoadingData')}</p>
          )}

          {paymentInfo && (
            <p>
              {t('user:payment.currentPaymentMethod', { type: paymentInfo.type })}
              &emsp;

              {/* Card */}
              {paymentInfo.card && (
                <span style={{ fontWeight: 'bold' }}>
                  {paymentInfo.card!.brand.toUpperCase()}
                  &emsp;
                  {`**** **** **** ${paymentInfo.card!.last4}`}
                </span>
              )}

              {/* SEPA Debit */}
              {paymentInfo.sepaDebit && (
                <span style={{ fontWeight: 'bold' }}>
                  SEPA
                  &emsp;
                  {`**** **** **** ${paymentInfo.sepaDebit.last4}`}
                </span>
              )}
            </p>
          )}

          {paymentInfo && !paymentInfo.aboCancelled && (
            <p>
              {t('user:payment.nextPayment', { date: paymentInfo.nextDate })}
            </p>
          )}
        
          <p>{t('user:payment.dataDisclaimer')}</p>
          <Button onClick={() => changePaymentData()}>
            {t('user:payment.changeData')}
          </Button>

        </div>
      </Show>
    
    </section>
  );
};

export default PaymentForm;
