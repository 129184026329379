import clsx from 'clsx';
import { Anchor, Icon, Referenz } from 'components/atoms';
import Link from 'next/link';
import React from 'react';
import { clamp } from 'utils';
import { useTranslation } from 'utils/hooks';
import { useUserQuery } from 'utils/hooks/queries';

import css from '../Startseite.module.scss';

const Subscriptions: React.FC = () =>
{
  const { t } = useTranslation();
  const { data: user } = useUserQuery();

  const x = clamp(user?.subscriptions.length || 0, 0, 5);
  const n = user?.subscriptions.length || 0;

  return (
    <div className={clsx(css.card, css.subscriptions)}>
      <h3>{t('startseite:subscriptions.heading')}</h3>

      {(!user?.subscriptions || user?.subscriptions.length === 0) && (
        <p>{t('startseite:subscriptions.noContent')}</p>
      )}

      {user?.subscriptions && user.subscriptions.length > 0 && (
        <>
          <ul className={css.referenzList}>
            {[...user.subscriptions].reverse().splice(0, 5).map((subscription) => (
              <Referenz key={subscription.ref.id} data={subscription.ref}>
                {({ ref, href, iconName }) => (
                  <li>
                    <Link href={href}>
                        <Icon name={iconName} />
                        <span className={css.name}>{ref.name}</span>
                    </Link>
                  </li>
                )}
              </Referenz>
            ))}
          </ul>

          <p>
            {t('formats:xOfN', { x, n })} - <Anchor href='/user/subscriptions'>{t('common:actions.viewAll')}</Anchor>
          </p>
        </>
      )}
    </div>
  );
};

export default Subscriptions;
