import { LabeledInput } from 'components/atoms';
import countries from 'i18n-iso-countries';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Adresse } from 'types';
import { useTranslation } from 'utils/hooks';

import css from './UserDataForm.module.scss';

interface AdresseFormProps
{
  editable: boolean;
}

const AdresseForm: React.FC<AdresseFormProps> = (props) =>
{
  const { editable } = props;
  const { t } = useTranslation('forms');
  const router = useRouter();
  const locale = router.locale || 'de';

  const countryList = useMemo(() => countries.getNames(locale, { select: 'official' }), [locale]);
 
  const { register, formState: { errors } } = useFormContext<Adresse>();

  return (   
    <>      

      {/* Land */}
      <LabeledInput
        id="länderCode"
        label={t('forms:address.country.label')}
        error={errors.länderCode?.message}
        className={css.grid1}
      >
        <select
          disabled={!editable}
          autoComplete="country"
          {...register('länderCode', {
            required: { value: true, message: t('forms:errors.required') },
          })}
        >
          <optgroup label={t('forms:address.country.suggestions')}>
            <option value="AT">{countries.getName('AT', locale)}</option>
            <option value="DE">{countries.getName('DE', locale)}</option>
            <option value="CH">{countries.getName('CH', locale)}</option>
          </optgroup>
          <optgroup label={t('forms:address.country.all')}>
            {Object.entries(countryList).map((country) => (
              <option key={country[0]} value={country[0]}>{country[1]}</option>
            ))}
          </optgroup>
        </select>
      </LabeledInput>
      
      {/* PLZ */}
      <LabeledInput
        id={'plz'}
        label={t('forms:address.zip.label')}
        error={errors.plz?.message}
        className={css.grid1}
      >
        <input
          type="text"
          readOnly={!editable}
          autoComplete="postal-code"
          placeholder={t('forms:address.zip.placeholder')}
          {...register('plz', {
            required: { value: true, message: t('forms:errors.required') },
          })}
        />
      </LabeledInput>

      {/* Ort */}
      <LabeledInput
        id="ort"
        label={t('forms:address.city.label')}
        error={errors.ort?.message}
        className={css.grid2}
      >
        <input
          type="text"
          readOnly={!editable}
          autoComplete="address-level2"
          placeholder={t('forms:address.city.placeholder')}
          {...register('ort', {
            required: { value: true, message: t('forms:errors.required') },
          })}
        />
      </LabeledInput>

      {/* Straße */}
      <LabeledInput
        id="straße"
        label={t('forms:address.address.label')}
        error={errors.straße?.message}
        className={css.grid4}
      >
        <input
          type="text"
          readOnly={!editable}
          autoComplete="address-line1"
          placeholder={t('forms:address.address.placeholder')}
          {...register('straße', {
            required: { value: true, message: t('forms:errors.required') },
          })}
        />
      </LabeledInput>
      
    </>
  );
};

export default AdresseForm;
