import clsx from 'clsx';
import { Icon, Show, Tooltip } from 'components/atoms';
import React, { ReactNode } from 'react';
import { useTranslation } from 'utils/hooks';


import css from './ContentRow.module.scss';

interface ContentRowProps 
{
  title: string | ReactNode | ReactNode[];
  tooltip?: string;
  id?: string;
  groupItem?: boolean;
  hideActions?: boolean;
  actionRenderer?: () => JSX.Element | ReactNode | ReactNode[];
  children: React.ReactNode;
}

const ContentRow: React.FC<ContentRowProps> = (props) => 
{
  const { t } = useTranslation('common');

  const {
    title,
    tooltip,
    id,
    groupItem = false,
    hideActions = false,
    children,
    actionRenderer,
  } = props;

  function scrollToTop() 
  {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
        <>
            <div id={id} className={clsx(css.contentRow, { [css.groupItem]: groupItem })}>

                {/* Title */}
                <dt className={css.titleContainer}>
                  <span>{title}</span>
                  {tooltip !== undefined && (<Tooltip content={tooltip}>
                    <span><Icon name={'info'} /></span>
                  </Tooltip>)}
                  {actionRenderer !== undefined && actionRenderer()}
                </dt>

                {/* Content */}
                <dd className={css.content}>
                    {children}
                </dd>
            </div>

            <Show when={!groupItem && !hideActions}>
                <div className={css.actions}>
                    <button
                        aria-label={t('common:actions.goToTopLabel')}
                        className={css.top}
                        onClick={scrollToTop}
                    >
                        <Icon name="chevronUp"/>
                        <span>{t('common:actions.goToTop')}</span>
                    </button>
                </div>
            </Show>

        </>
  );
};

export default ContentRow;
