import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { useEffect } from 'react';
import { UserStatus } from 'types/user';

import useUserQuery from './queries/useUserQuery';

const useRedirectOnInvalidUser = () =>
{
  const { data: user, status: queryStatus } = useUserQuery();
  const session = useSession();
  const router = useRouter();

  useEffect(() =>
  {
    if (session.status !== 'authenticated' || queryStatus === 'pending')
    {
      return;
    }
    if (!user)
    {
      if (router.pathname !== '/user/register/onboarding')
      {
        router.replace('/user/register/onboarding');
      }
      return;
    }
    
    if (![UserStatus.Active, UserStatus.Freemium].includes(user.status) && !router.pathname.startsWith('/user'))
    {
      router.replace('/user');
    }
    
  }, [user?.status, router, session.status, queryStatus]);
};

export default useRedirectOnInvalidUser;