import React from 'react';

const AuftragAt: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1000 190"
    width={120}
  >
    <path d="M87.22,102.48c-1.15.26-2.32.49-3.53.68s-2.37.38-3.48.54a20.941,20.941,0,0,0-5.75,1.67,9.08,9.08,0,0,0-3.63,2.91,7.21,7.21,0,0,0-1.24,4.24,6.62,6.62,0,0,0,2.67,5.68,11.18,11.18,0,0,0,6.78,2,15.37,15.37,0,0,0,7-1.57,12.85,12.85,0,0,0,5-4.34,11.26,11.26,0,0,0,1.86-6.44V100.7a14.27,14.27,0,0,1-2.47,1C89.41,101.94,88.36,102.21,87.22,102.48Z" fill="#fff" fillRule="evenodd"/>
    <path d="M166.25,0H23.75A23.75,23.75,0,0,0,0,23.75v142.5A23.75,23.75,0,0,0,23.75,190h142.5A23.75,23.75,0,0,0,190,166.25V23.75A23.75,23.75,0,0,0,166.25,0ZM116.6,134.55H94.07V124.07h-.58a21.55,21.55,0,0,1-5.12,6.47,21.06,21.06,0,0,1-7.3,4,31.28,31.28,0,0,1-9.43,1.32,31.52,31.52,0,0,1-12.75-2.43,19.48,19.48,0,0,1-8.77-7.34q-3.21-4.92-3.21-12.37a23.19,23.19,0,0,1,2.2-10.58,18.82,18.82,0,0,1,6.08-7A28.9,28.9,0,0,1,64.1,92a59.649,59.649,0,0,1,10.78-1.89c4.27-.39,7.72-.8,10.33-1.25A14.33,14.33,0,0,0,90.91,87a4,4,0,0,0,1.79-3.55v-.24a7,7,0,0,0-2.59-5.83,11,11,0,0,0-7-2.06,12.94,12.94,0,0,0-7.64,2.06A9.08,9.08,0,0,0,71.79,83L49.7,82.25A26.1,26.1,0,0,1,54.77,70,27.35,27.35,0,0,1,66,61.46a43.29,43.29,0,0,1,17.3-3.11A49.24,49.24,0,0,1,96.57,60a33.59,33.59,0,0,1,10.58,4.9,22.67,22.67,0,0,1,7,7.86,22.25,22.25,0,0,1,2.47,10.62Zm39-4.89a13.64,13.64,0,0,1-4.65,4.6,12,12,0,0,1-6.24,1.71,12.37,12.37,0,0,1-9-3.72,12.06,12.06,0,0,1-3.7-9,11.89,11.89,0,0,1,3.7-8.89,12.65,12.65,0,0,1,17.88,0,12.07,12.07,0,0,1,3.89,8.89,12.22,12.22,0,0,1-1.92,6.41Z" fill="#fff" fillRule="evenodd"/>
    <path d="M316.23,59.59a37.45,37.45,0,0,0-12-5.08A56.819,56.819,0,0,0,291.15,53a51.73,51.73,0,0,0-17.48,2.82,34.56,34.56,0,0,0-13.37,8.44,32.34,32.34,0,0,0-7.85,13.94L272.54,81a17,17,0,0,1,6.22-8.14q4.61-3.45,12.51-3.45,7.49,0,11.47,3.69t4,10.4v.35a5,5,0,0,1-2.29,4.55,18.74,18.74,0,0,1-7.28,2.14q-5,.69-13,1.52a91.448,91.448,0,0,0-12.87,2.29A39.92,39.92,0,0,0,260.12,99a22.14,22.14,0,0,0-7.79,8.09q-2.85,5.06-2.85,12.78,0,9,4,15.09a25.06,25.06,0,0,0,10.94,9.25A37.59,37.59,0,0,0,280,147.3a35.67,35.67,0,0,0,12.46-2,26.42,26.42,0,0,0,8.85-5.29,26.1,26.1,0,0,0,5.5-7.11h.71v12.54h20.69V84.35q0-9.09-3.3-15.16A26.17,26.17,0,0,0,316.23,59.59Zm-9.45,53.11a17.78,17.78,0,0,1-2.58,9.3,19,19,0,0,1-7.34,6.86,23.27,23.27,0,0,1-11.24,2.59,18.77,18.77,0,0,1-11.05-3q-4.35-3-4.34-9a10.3,10.3,0,0,1,2.2-6.81,14.39,14.39,0,0,1,6-4.13,37.75,37.75,0,0,1,8.62-2.14l5.05-.71q2.91-.42,5.88-1a49.492,49.492,0,0,0,5.38-1.25,9.82,9.82,0,0,0,3.42-1.55Z" fill="#fff"/>
    <path d="M405.51,107.06a21.45,21.45,0,0,1-2.88,11.56,18.84,18.84,0,0,1-7.34,6.92,19.66,19.66,0,0,1-9.21,2.32,16.24,16.24,0,0,1-12.48-5.11q-4.77-5.11-4.76-13.79V54.15H347.32v58.14q0,10.93,3.87,18.63a27.32,27.32,0,0,0,10.73,11.71,30.72,30.72,0,0,0,15.84,4q10.28,0,17.32-4.87a27.5,27.5,0,0,0,10.14-12.54h.95v16.22H427V54.15H405.51Z" fill="#fff"/>
    <path d="M543.29,128.28a21.442,21.442,0,0,1-3.72.29,13.57,13.57,0,0,1-4.87-.83,6.87,6.87,0,0,1-3.54-3.12,13.32,13.32,0,0,1-1.33-6.63V70.8h18V54.15h-18V32.28H508.31V54.15H474.24V47c0-3.56.82-6.31,2.47-8.23s4.47-2.88,8.47-2.88a22.61,22.61,0,0,1,4.63.44c1.39.3,2.54.59,3.45.86l4.28-16.64a57.619,57.619,0,0,0-6.42-1.6,49.221,49.221,0,0,0-9.93-.89,33.55,33.55,0,0,0-14.38,3A23.89,23.89,0,0,0,456.5,30.2q-3.84,6.12-3.83,15.34v8.61h-13.5V70.8h13.5v74.65h21.57V70.8h34.07v50.76q-.06,8.56,3.71,14.26a22.35,22.35,0,0,0,10.26,8.42,34.78,34.78,0,0,0,14.56,2.46,40.68,40.68,0,0,0,7.76-.83,48.54,48.54,0,0,0,4.9-1.31l-3.62-16.82C545.28,127.9,544.42,128.08,543.29,128.28Z" fill="#fff"/>
    <path d="M606.36,52.85a23.49,23.49,0,0,0-13.94,4.31,22.77,22.77,0,0,0-8.59,12.21h-1V54.15H562v91.3h21.52V91.78A19.62,19.62,0,0,1,586.2,81.5a18.94,18.94,0,0,1,7.35-7A21.82,21.82,0,0,1,604.1,72a44.858,44.858,0,0,1,5.67.38,23.5,23.5,0,0,1,4.31.87V53.44a31.369,31.369,0,0,0-3.71-.44C608.88,52.9,607.54,52.85,606.36,52.85Z" fill="#fff"/>
    <path d="M685.57,59.59a37.39,37.39,0,0,0-11.94-5.08A57,57,0,0,0,660.49,53,51.73,51.73,0,0,0,643,55.79a34.63,34.63,0,0,0-13.37,8.44,32.34,32.34,0,0,0-7.85,13.94L641.89,81a17,17,0,0,1,6.21-8.14q4.6-3.45,12.51-3.45,7.49,0,11.47,3.69t4,10.4v.35a5,5,0,0,1-2.29,4.55,18.741,18.741,0,0,1-7.28,2.14q-5,.69-13,1.52a91.453,91.453,0,0,0-12.87,2.29A39.921,39.921,0,0,0,629.46,99a22,22,0,0,0-7.78,8.09q-2.86,5.06-2.86,12.78,0,9,4,15.09a25,25,0,0,0,10.93,9.25,37.61,37.61,0,0,0,15.61,3.12,35.66,35.66,0,0,0,12.45-2,26.47,26.47,0,0,0,8.86-5.29,26.059,26.059,0,0,0,5.49-7.11h.72v12.54h20.68V84.35q0-9.09-3.3-15.16a26.17,26.17,0,0,0-8.69-9.6Zm-9.45,53.11a17.779,17.779,0,0,1-2.58,9.34,18.931,18.931,0,0,1-7.34,6.86,23.24,23.24,0,0,1-11.2,2.59,18.77,18.77,0,0,1-11.05-3q-4.35-3-4.34-9a10.3,10.3,0,0,1,2.2-6.81,14.391,14.391,0,0,1,6-4.13,37.879,37.879,0,0,1,8.62-2.14c1.43-.19,3.11-.43,5.05-.71s3.9-.6,5.89-1a49.847,49.847,0,0,0,5.37-1.25,9.82,9.82,0,0,0,3.42-1.55Z" fill="#fff"/>
    <path d="M778.21,69.25H777a36.57,36.57,0,0,0-4.7-7.1,25.92,25.92,0,0,0-8.3-6.48Q758.83,53,750.8,53a35.19,35.19,0,0,0-19.08,5.37A37.18,37.18,0,0,0,718.14,74.1q-5,10.37-5,25.41t5,24.81a34.88,34.88,0,0,0,13.55,14.86,37.2,37.2,0,0,0,19.06,5,31.3,31.3,0,0,0,13-2.38,25.36,25.36,0,0,0,8.38-5.89,30.824,30.824,0,0,0,4.85-6.83h1.07v17.3q0,9.79-6,14.2T756.93,165a28.3,28.3,0,0,1-10.73-1.75,19.72,19.72,0,0,1-6.75-4.34,23.242,23.242,0,0,1-3.8-5l-19.38,4.7a28.49,28.49,0,0,0,7,11.35,35.759,35.759,0,0,0,13.38,8.27,57.24,57.24,0,0,0,19.91,3.09,60.14,60.14,0,0,0,22-3.78,33.43,33.43,0,0,0,15.28-11.41q5.58-7.63,5.59-19.11V54.15H778.21Zm-2.5,45.12a20.55,20.55,0,0,1-7.31,9.57,22.1,22.1,0,0,1-23.57-.15,21.159,21.159,0,0,1-7.31-9.75,40,40,0,0,1-2.47-14.65,41.778,41.778,0,0,1,2.44-14.77,22.71,22.71,0,0,1,7.25-10.26,21,21,0,0,1,23.75-.14,22.05,22.05,0,0,1,7.25,10.07,42.412,42.412,0,0,1,2.47,15.1,40.422,40.422,0,0,1-2.5,14.98Z" fill="#fff"/>
    <path d="M830,120.73a12.78,12.78,0,0,0-9.28,3.8,12.21,12.21,0,0,0-3.8,9.16,12.34,12.34,0,0,0,3.8,9.27,12.78,12.78,0,0,0,9.28,3.8,12.34,12.34,0,0,0,6.44-1.75,13.641,13.641,0,0,0,4.76-4.73,12.82,12.82,0,0,0,1.87-6.59,13.24,13.24,0,0,0-13.07-13Z" fill="#fff"/>
    <path d="M923.18,59.59a37.539,37.539,0,0,0-11.95-5.08A57,57,0,0,0,898.09,53a51.67,51.67,0,0,0-17.47,2.82,34.67,34.67,0,0,0-13.38,8.44A32.32,32.32,0,0,0,859.4,78.2L879.49,81a17.06,17.06,0,0,1,6.21-8.14q4.6-3.45,12.51-3.45,7.49,0,11.47,3.69t4,10.4v.35a5,5,0,0,1-2.29,4.55,18.859,18.859,0,0,1-7.28,2.14q-5,.69-13,1.52a91.453,91.453,0,0,0-12.87,2.29A39.739,39.739,0,0,0,867.07,99a22.06,22.06,0,0,0-7.79,8.09q-2.85,5.06-2.85,12.78,0,9,4,15.09a25,25,0,0,0,10.94,9.25A37.53,37.53,0,0,0,887,147.3a35.66,35.66,0,0,0,12.45-2,26,26,0,0,0,14.36-12.4h.71v12.54H935.2V84.35q0-9.09-3.3-15.16a26.23,26.23,0,0,0-8.72-9.6Zm-9.45,53.11a17.789,17.789,0,0,1-2.59,9.34,18.86,18.86,0,0,1-7.34,6.86,23.23,23.23,0,0,1-11.23,2.59,19.025,19.025,0,0,1-11.06-3q-4.34-3-4.34-9a10.3,10.3,0,0,1,2.2-6.81,14.52,14.52,0,0,1,6-4.13A37.88,37.88,0,0,1,894,106.4c1.43-.19,3.11-.43,5-.71s3.91-.6,5.89-1a50.619,50.619,0,0,0,5.38-1.25,10,10,0,0,0,3.42-1.55Z" fill="#fff"/>
    <path d="M996.37,127.74q-.89.24-2.58.54a21.443,21.443,0,0,1-3.72.29,13.53,13.53,0,0,1-4.87-.83,6.84,6.84,0,0,1-3.54-3.12,13.321,13.321,0,0,1-1.33-6.63V70.8h18V54.15h-18V32.28H958.81V54.15h-13V70.8h13v50.76q-.06,8.56,3.71,14.26a22.35,22.35,0,0,0,10.26,8.42,34.751,34.751,0,0,0,14.56,2.46,40.68,40.68,0,0,0,7.76-.83,49.286,49.286,0,0,0,4.9-1.31Z" fill="#fff"/>
  </svg>
);

export default AuftragAt;
