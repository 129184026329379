import Image from 'next/legacy/image';
import React from 'react';
import { getRuntimeEnvVar } from 'utils';
import { useTranslation } from 'utils/hooks';
import { useLatestBlogPostsQuery, useStickyBlogPostsQuery } from 'utils/hooks/queries';
import { BlogPost } from 'utils/hooks/queries/types';

import css from '../Startseite.module.scss';

const Blog: React.FC = () => 
{ 
  const { t } = useTranslation();
  const { data: latest } = useLatestBlogPostsQuery();
  const { data: sticky } = useStickyBlogPostsQuery();
  //corrupted Data should not break the plattform
  function postIsValid(post: BlogPost) 
  {
    return (
      post &&
      post.yoast_head_json &&
      //check Image
      post.yoast_head_json.og_image &&
      post.yoast_head_json.og_image.length !== 0 &&
      post.yoast_head_json.og_image[0] &&
      post.yoast_head_json.og_image[0].url &&
      //check content
      post.title &&
      post.title.rendered &&
      post.excerpt &&
      post.excerpt.rendered &&
      post.link
    );
  }
  const stickyPostIds = (sticky ?? []).map((post) => post.id);
  const displayedPosts: Required<BlogPost>[] = [...(sticky ?? []), ...(latest ?? []).filter((post) => !stickyPostIds.includes(post.id))].filter(postIsValid).slice(0, 3).map(post => post as Required<BlogPost>);

  return (
    <section className={css.section}>
      <h2>
        <a
          href={getRuntimeEnvVar('BLOG_URL')}
          className={css.blogTitle}
          target="_blank"
          rel="noreferrer">
            {t('startseite:blog.heading')}
        </a>
        </h2>
      <ul className={css.blogPostsContainer}>
        {displayedPosts.map((post) => (
          <li key={`blog_post_${post.id}`} className={css.blogPost}>

            {
              <Image
                src={post.yoast_head_json.og_image[0].url}
                alt={post.title.rendered}
                draggable={false}
                width={400}
                height={225}
              />
            }

            <h4 dangerouslySetInnerHTML={{ __html: post.title.rendered }} />
            <div className={css.excerpt} dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} />
            <a
              href={post.link}
              className={css.visit}
              target="_blank"
              rel="noreferrer"
            >
              {t('startseite:blog.post.cta')}
            </a>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default Blog;
