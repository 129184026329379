import clsx from 'clsx';
import React from 'react';

interface DividerProps
{
  fullWidth?: boolean;
}

const Divider: React.FC<DividerProps> = ({ fullWidth = true }) => (
  <hr className={clsx({ 'full-width-ignore-bounds': fullWidth })} />
);

export default Divider;