import { useEffect, useState } from 'react';
import { Size } from 'types';

function useWindowSize(): Size & { isMobile: boolean } 
{
  const [windowSize, setWindowSize] = useState<Size>({ width: undefined, height: undefined });

  useEffect(() => 
  {
    function handleResize() 
    {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { ...windowSize, isMobile: windowSize.width !== undefined && windowSize.width <= 580 };
}

export default useWindowSize;
