import clsx from 'clsx';
import React from 'react';
import { UnternehmensStatus } from 'types';
import { useTranslation } from 'utils/hooks';


import css from './QuickSearch.module.scss';

interface StatusTagProps 
{
  status: UnternehmensStatus;
}

const StatusTag: React.FC<StatusTagProps> = (props) => 
{
  const { status } = props;
  const { t } = useTranslation('common');

  return (
        <span className={clsx(css.status, css[status])}>
      {t(`common:profiles.status.${status}`)}
    </span>
  );
};

export default StatusTag;
