import clsx from 'clsx';
import { Show } from 'components/atoms';
import { ToastNotificationStack } from 'components/molecules';
import Footer from 'components/molecules/Footer/Footer';
import Header from 'components/molecules/Header/Header';
import React from 'react';
import { useShowSearch } from 'utils/hooks/state';

import Suche from '../Suche/Suche';

import css from './PageLayout.module.scss';

interface PageLayoutProps
{
  textContentPage?: boolean;
  children: React.ReactNode | React.ReactNode[];
}

const PageLayout: React.FC<PageLayoutProps> = (props) =>
{
  const { textContentPage = false, children } = props;
  const { showSearch } = useShowSearch();

  return (
    <div className={css.layout}>
      <div className={css.contentContainer}>
        <Header />

        <Show when={showSearch}>
          <div className={css.searchContainer}>
            <Suche />
          </div>
          {/* <hr className='full-width-ignore-bounds' /> */}
        </Show>

        <main className={clsx(css.content, { 'text-max-width': textContentPage })}>
          { children }
        </main>
      </div>
      <Footer />

      <ToastNotificationStack />

    </div>
  );
};

export default PageLayout;
