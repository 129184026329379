import React from 'react';
import { useTranslation } from 'utils/hooks';

import css from './Badge.module.scss';

interface BadgeProps
{
  count: number;
  max?: number;
}

const Badge: React.FC<BadgeProps> = (props) =>
{
  const { count, max = 99 } = props;
  const { t } = useTranslation();

  const displayedCount = Math.min(count, max);
  const suffix = displayedCount < count ? '+' : '';

  return (
    <span className={css.badge}>
      {`${t('formats:number', { value: displayedCount })}${suffix}`}
    </span>
  );
};

export default Badge;
