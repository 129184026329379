import { keepPreviousData, useQuery, UseQueryResult } from '@tanstack/react-query';
import { createAxiosInstance } from 'api/request';
import { PaginatedResult } from 'types';
import { UnternehmenSearchResult } from 'types/suche';

type RequestReturnType = PaginatedResult<UnternehmenSearchResult>;

const request = async (term: string, page: number, limit: number, signal?: AbortSignal) =>
{
  const params = new URLSearchParams({
    term,
    page: page.toString(),
    limit: limit.toString(),
  });

  const response = await createAxiosInstance().get(`/search/unternehmen?${params.toString()}`, { signal });
  return response.data;
};

export const BASE_QUERY_KEY = 'unternehmenSearch';

const useUnternehmenSearchQuery = (
  term: string,
  page: number,
  limit: number,
): UseQueryResult<RequestReturnType, unknown> =>
{
  return useQuery(
    {
      queryKey: [BASE_QUERY_KEY, term, page, limit],
      queryFn: ({ signal }) => request(term, page, limit, signal),
      placeholderData: keepPreviousData,
    },
  );
};

export default useUnternehmenSearchQuery;