import React, { useEffect, useState } from 'react';

/**
 * Minimal User Interaction (MUI) Context
 * This context changes it's value to true if a typical user interaction
 * e.g. scrolling, moving the mouse, etc. has been performed.
 * Used to hide content from search engines.
 */

export const MuiContext = React.createContext(false);

export const MuiProvider: React.FC<React.PropsWithChildren> = ({ children }) =>
{
  const [userHasInteracted, setUserHasInteracted] = useState<boolean>(false);

  const onUserInteractionEvent = () => 
  {
    setUserHasInteracted(true);
    cleanup();
  };

  const cleanup = () => 
  {
    document.removeEventListener('focus', onUserInteractionEvent);
    document.removeEventListener('mousemove', onUserInteractionEvent);
    document.removeEventListener('touchstart', onUserInteractionEvent);
    document.removeEventListener('scroll', onUserInteractionEvent);
  };

  useEffect(() => 
  {
    document.addEventListener('focus', onUserInteractionEvent, { passive: true });
    document.addEventListener('mousemove', onUserInteractionEvent, { passive: true });
    document.addEventListener('touchstart', onUserInteractionEvent, { passive: true });
    document.addEventListener('scroll', onUserInteractionEvent, { passive: true });
    return cleanup;
  }, []);

  return (
        <MuiContext.Provider value={userHasInteracted}>
            {children}
        </MuiContext.Provider>
  );
};

export default () => React.useContext(MuiContext);