import { useEffect, useState } from 'react';
import { Size } from 'types';

function useInitialWindowSize(): Size 
{
  const [windowSize, setWindowSize] = useState<Size>({ width: undefined, height: undefined });

  useEffect(() => 
  {

    setWindowSize({ width: window.innerWidth, height: window.innerHeight });
  }, []);

  return { ...windowSize };
}

export default useInitialWindowSize;
