import { useRouter } from 'next/router';
import { useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

interface DeepLinkTarget
{
  type: string;
  target: string;
}

const useDeepLinkTarget = (typeFilter?: string[]) =>
{
  const [target, setTarget] = useState<DeepLinkTarget | undefined>();
  const router = useRouter();

  useDeepCompareEffect(() =>
  {
    const matches = router.asPath.match(/#dlt-[a-z]+-[a-zA-Z0-9\-]+/);

    if (!matches || matches.length < 1)
    {
      setTarget(undefined);
      return;
    }

    const params = matches[0].split('-');

    if (typeFilter && !typeFilter.includes(params[1]))
    {
      setTarget(undefined);
      return;
    }

    setTarget({ type: params[1], target: params.splice(2).join('-') });

    const yOffset = (document.getElementById('marken')?.getBoundingClientRect().top || 0) + window.scrollY - 100;
    window.scrollTo({ top: yOffset });

  }, [router.asPath, typeFilter]);

  return target;
};

export default useDeepLinkTarget;