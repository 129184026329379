import clsx from 'clsx';
import router, { useRouter } from 'next/router';
import React, { ChangeEventHandler } from 'react';
import { clamp } from 'utils';
import { useBrowserStorage, useTranslation } from 'utils/hooks';

import Icon from '../Icon/Icon';

import css from './Pagination.module.scss';

interface PaginationProps 
{
  totalResults: number;
  activePage: number;
  limit?: number;
  scrollTop?: boolean;
  shallow?: boolean;
}

const LIMIT_OPTIONS = [15, 30, 50, 100];

const Pagination: React.FC<PaginationProps> = (props) => 
{
  const { totalResults, activePage, limit = 30, scrollTop = false, shallow = true } = props;
  const { query, pathname } = useRouter();
  const { t } = useTranslation('common');
  const [, setResultLimit] = useBrowserStorage<number>('wss.result-limit', -1, 'localStorage');

  const totalPages = Math.ceil(totalResults / limit);

  const activeIndex = activePage - 1;

  async function onPageChange(page: number)
  {
    const newQuery = { ...query };
    await router.push({ pathname, query: { ...newQuery, page } }, undefined, { shallow, scroll: scrollTop });
  }

  function renderPage(i: number) 
  {
    return (
            <button
                type="button"
                key={`pagination_item_${i + 1}`}
                className={clsx(css.page, { [css.active]: i === activeIndex })}
                onClick={() => onPageChange(i + 1)}
            >
                {i + 1}
            </button>
    );
  }

  function renderMore(key: string) 
  {
    return (
            <span key={`pagination_more_${key}`} className={css.more}>
        <Icon name="dots"/>
      </span>
    );
  }

  function getPaginationBounds(): [number, number] 
  {
    let lower = 1;
    let upper = totalPages - 2;

    if (activeIndex < 4) 
    {
      lower = 1;
      upper = clamp(4, 0, totalPages - 2);
    }

    if (totalPages > 5 && activeIndex > totalPages - 5) 
    {
      upper = totalPages - 2;
      lower = totalPages - 5;
    }

    if (activeIndex >= 4 && activeIndex <= totalPages - 5) 
    {
      lower = activeIndex - 1;
      upper = activeIndex + 1;
    }

    return [lower, upper];
  }

  function getPageButtons(): JSX.Element[] 
  {
    const buttons = [];

    buttons.push(renderPage(0));

    if (lowerLimit !== 1) 
    {
      buttons.push(renderMore('lower'));
    }

    for (let i = lowerLimit; i <= upperLimit; i++) 
    {
      buttons.push(renderPage(i));
    }

    if (upperLimit !== totalPages - 2) 
    {
      buttons.push(renderMore('upper'));
    }

    if (totalPages > 1) 
    {
      buttons.push(renderPage(totalPages - 1));
    }

    return buttons;
  }

  const onLimitChange: ChangeEventHandler<HTMLSelectElement> = (event) => 
  {
    const newLimit = parseInt(event.target.value, 10);
    const lastItemIndex = Math.min(limit * activePage, totalResults);
    const newPage = Math.ceil(lastItemIndex / newLimit);
    setResultLimit(newLimit);
    router.replace({ pathname, query: { ...query, page: newPage, limit: newLimit } });
  };

  const [lowerLimit, upperLimit] = getPaginationBounds();
  const pageButtons = getPageButtons();

  return (
        <div className={css.container}>
            <nav className={css.pagination}>

                <button
                    type="button"
                    className={css.chevron}
                    disabled={activeIndex === 0}
                    onClick={() => onPageChange(activePage - 1)}
                >
                    <Icon name="chevronLeft"/>
                </button>

                {pageButtons}

                <button
                    type="button"
                    className={css.chevron}
                    disabled={activeIndex === totalPages - 1}
                    onClick={() => onPageChange(activePage + 1)}
                >
                    <Icon name="chevronRight"/>
                </button>

            </nav>

            <select onChange={onLimitChange} defaultValue={limit}>

                {!LIMIT_OPTIONS.includes(limit) && (
                    <option value={limit}>
                        {t('common:actions.showRows', { count: limit })}
                    </option>
                )}

                {LIMIT_OPTIONS.map((opt) => (
                    <option key={opt} value={opt}>
                        {t('common:actions.showRows', { count: opt })}
                    </option>
                ))}

            </select>

        </div>
  );
};

export default Pagination;
