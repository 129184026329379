import React from 'react';

const GooglePay: React.FC = () => (
    <svg
   version="1.1"
   id="G_Pay_Acceptance_Mark"
   x="0px"
   y="0px"
   viewBox="0 0 752 400"
   enableBackground="new 0 0 1094 742"
   xml-space="preserve"
   height={28}
   xmlns="http://www.w3.org/2000/svg"
   xmlns-svg="http://www.w3.org/2000/svg"><defs
   id="defs17" />
<path
   id="Base_1_"
   fill="#ffffff"
   d="M 552,0 H 200 C 89.999999,0 0,90 0,200 v 0 c 0,110 89.999999,200 200,200 h 352 c 110,0 200,-90 200,-200 v 0 C 752,90 662,0 552,0 Z" />
<path
   id="Outline"
   fill="#3c4043"
   d="m 552,16.2 c 24.7,0 48.7,4.9 71.3,14.5 21.9,9.3 41.5,22.6 58.5,39.5 16.9,16.9 30.2,36.6 39.5,58.5 9.6,22.6 14.5,46.6 14.5,71.3 0,24.7 -4.9,48.7 -14.5,71.3 -9.3,21.9 -22.6,41.5 -39.5,58.5 -16.9,16.9 -36.6,30.2 -58.5,39.5 -22.6,9.6 -46.6,14.5 -71.3,14.5 H 200 c -24.7,0 -48.7,-4.9 -71.3,-14.5 -21.9,-9.3 -41.500001,-22.6 -58.500001,-39.5 -16.9,-16.9 -30.2,-36.6 -39.5,-58.5 -9.6,-22.6 -14.5,-46.6 -14.5,-71.3 0,-24.7 4.9,-48.7 14.5,-71.3 9.3,-21.9 22.6,-41.5 39.5,-58.5 C 87.099999,53.3 106.8,40 128.7,30.7 151.3,21.1 175.3,16.2 200,16.2 H 552 M 552,0 H 200 C 89.999999,0 0,90 0,200 v 0 c 0,110 89.999999,200 200,200 h 352 c 110,0 200,-90 200,-200 v 0 C 752,90 662,0 552,0 Z" />
<g
   id="G_Pay_Lockup_1_"
   transform="translate(-170.7,-170)">
	<g
   id="Pay_Typeface_3_">
		<path
   id="Letter_p_3_"
   fill="#3c4043"
   d="m 529.3,384.2 v 60.5 H 510.1 V 295.3 H 561 c 12.9,0 23.9,4.3 32.9,12.9 9.2,8.6 13.8,19.1 13.8,31.5 0,12.7 -4.6,23.2 -13.8,31.7 -8.9,8.5 -19.9,12.7 -32.9,12.7 h -31.7 z m 0,-70.5 v 52.1 h 32.1 c 7.6,0 14,-2.6 19,-7.7 5.1,-5.1 7.7,-11.3 7.7,-18.3 0,-6.9 -2.6,-13 -7.7,-18.1 -5,-5.3 -11.3,-7.9 -19,-7.9 h -32.1 z" />
		<path
   id="Letter_a_3_"
   fill="#3c4043"
   d="m 657.9,339.1 c 14.2,0 25.4,3.8 33.6,11.4 8.2,7.6 12.3,18 12.3,31.2 v 63 h -18.3 v -14.2 h -0.8 c -7.9,11.7 -18.5,17.5 -31.7,17.5 -11.3,0 -20.7,-3.3 -28.3,-10 -7.6,-6.7 -11.4,-15 -11.4,-25 0,-10.6 4,-19 12,-25.2 8,-6.3 18.7,-9.4 32,-9.4 11.4,0 20.8,2.1 28.1,6.3 v -4.4 c 0,-6.7 -2.6,-12.3 -7.9,-17 -5.3,-4.7 -11.5,-7 -18.6,-7 -10.7,0 -19.2,4.5 -25.4,13.6 l -16.9,-10.6 c 9.3,-13.5 23.1,-20.2 41.3,-20.2 z m -24.8,74.2 c 0,5 2.1,9.2 6.4,12.5 4.2,3.3 9.2,5 14.9,5 8.1,0 15.3,-3 21.6,-9 6.3,-6 9.5,-13 9.5,-21.1 -6,-4.7 -14.3,-7.1 -25,-7.1 -7.8,0 -14.3,1.9 -19.5,5.6 -5.3,3.9 -7.9,8.6 -7.9,14.1 z" />
		<path
   id="Letter_y_3_"
   fill="#3c4043"
   d="m 808.2,342.4 -64,147.2 H 724.4 L 748.2,438.1 706,342.4 h 20.9 l 30.4,73.4 h 0.4 l 29.6,-73.4 z" />
	</g>
	<g
   id="G_Mark_1_">
		<path
   id="Blue_500"
   fill="#4285f4"
   d="m 452.93,372 c 0,-6.26 -0.56,-12.25 -1.6,-18.01 h -80.48 v 33 L 417.2,387 c -1.88,10.98 -7.93,20.34 -17.2,26.58 v 21.41 h 27.59 C 443.7,420.08 452.93,398.04 452.93,372 Z" />
		<path
   id="Green_500_1_"
   fill="#34a853"
   d="m 400.01,413.58 c -7.68,5.18 -17.57,8.21 -29.14,8.21 -22.35,0 -41.31,-15.06 -48.1,-35.36 h -28.46 v 22.08 c 14.1,27.98 43.08,47.18 76.56,47.18 23.14,0 42.58,-7.61 56.73,-20.71 z" />
		<path
   id="Yellow_500_1_"
   fill="#fabb05"
   d="m 320.09,370.05 c 0,-5.7 0.95,-11.21 2.68,-16.39 v -22.08 h -28.46 c -5.83,11.57 -9.11,24.63 -9.11,38.47 0,13.84 3.29,26.9 9.11,38.47 l 28.46,-22.08 c -1.73,-5.18 -2.68,-10.69 -2.68,-16.39 z" />
		<path
   id="Red_500"
   fill="#e94235"
   d="m 370.87,318.3 c 12.63,0 23.94,4.35 32.87,12.85 l 24.45,-24.43 c -14.85,-13.83 -34.21,-22.32 -57.32,-22.32 -33.47,0 -62.46,19.2 -76.56,47.18 l 28.46,22.08 c 6.79,-20.3 25.75,-35.36 48.1,-35.36 z" />
	</g>
</g>
</svg>
);

export default GooglePay;
