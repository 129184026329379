import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'utils/hooks';

import css from '../Startseite.module.scss';

const About: React.FC = () =>
{
  const { t } = useTranslation();
  return (
    <section className={clsx(css.section, css.about)}>
      <h2>{t('startseite:about.heading')}</h2>
      <p>{t('startseite:about.text')}</p>
    </section>
  );
};

export default About;
