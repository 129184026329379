import * as Dialog from '@radix-ui/react-dialog';
import { Icon } from 'components/atoms';
import React from 'react';


import css from './Dialog.module.scss';

interface DetailsProps 
{
  open: boolean;
  onOpenChange: (value: boolean) => void;
  header: string;
  children: React.ReactNode | React.ReactNode[];
  actions?: React.ReactNode | React.ReactNode[];
}

const DialogWrapper: React.FC<DetailsProps> = ({ open, header, children, actions = null, onOpenChange }) =>
{
  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Portal>
        <Dialog.Overlay className={css.overlay}>
          <Dialog.Content className={css.content}>
            <Dialog.Close className={css.close}>
              <Icon name='close' />
            </Dialog.Close>
            <h2>{header}</h2>
            {children}
            {actions && (
              <div className={css.actions}>
                {actions}
              </div>
            )}
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default DialogWrapper;
