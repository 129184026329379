import { atom, useAtom } from 'jotai';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { deserializeQueryParam } from 'utils';

const direktkaufState = atom<'info' | 'buy' | 'success' | undefined>(undefined);

export default () =>
{
  const [state, setState] = useAtom(direktkaufState);
  const router = useRouter();

  useEffect(() =>
  {
    const direktkaufSuccess = deserializeQueryParam(router.query.direktkaufSuccess);
    
    if (direktkaufSuccess === 'true')
    {
      setState('success');
    }
  }, [router]);


  return [state, setState] as const;
};