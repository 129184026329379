/* eslint-disable @typescript-eslint/indent */

import { ISO8601Date, Referenz } from 'types';

export interface FeedItem<T extends UnternehmensFeedItemType | PersonenFeedItemType>
{
  type: T;
  payload: any;
}

export type PersonalFeedItem = {
  id: string;
  date: ISO8601Date;
  isRead?: boolean;
  ref: Referenz;
  items: FeedItem<any>[];
};

export const enum UnternehmensFeedItemType
{
  UnternehmenEingetragen = 'UnternehmenEingetragen',
  RechtsformChanged = 'RechtsformChanged',
  StatusGelöscht = 'StatusGelöscht',
  StatusInLiquidation = 'StatusInLiquidation',
  StatusNichtMehrInLiquidation = 'StatusNichtMehrInLiquidation',
  AufsichtsratAdded = 'AufsichtsratAdded',
  AufsichtsratChanged = 'AufsichtsratChanged',
  AufsichtsratDeleted = 'AufsichtsratDeleted',
  FunktionAdded = 'FunktionAdded',
  FunktionChanged = 'FunktionChanged',
  FunktionDeleted = 'FunktionDeleted',
  PersonNameChanged = 'PersonNameChanged',
  GesellschafterAdded = 'GesellschafterAdded',
  GesellschafterChanged = 'GesellschafterChanged',
  GesellschafterDeleted = 'GesellschafterDeleted',
  NameChanged = 'NameChanged',
  AdresseChanged = 'AdresseChanged',
  SitzChanged = 'SitzChanged',
  AktieChanged = 'AktieChanged',
  AktieDeleted = 'AktieDeleted',
  AuslandsInfoChanged = 'AuslandsInfoChanged',
  JabStichtagChanged = 'JabStichtagChanged',
  JahresabschlussAdded = 'JahresabschlussAdded',
  KabStichtagChanged = 'KabStichtagChanged',
  KapitalChanged = 'KapitalChanged',
  UnternehmenswertanteilChanged = 'UnternehmenswertanteilChanged',
  RechtstatsacheAdded = 'RechtstatsacheAdded',
  OtsAussendungPublished = 'OtsAussendungPublished',
  ScheinunternehmenAnnouncementAdded = 'ScheinunternehmenAnnouncementAdded',
  InsolvenzBeschlussAdded = 'InsolvenzBeschlussAdded',
  GeschäftszweigeChanged = 'GeschäftszweigeChanged',
  StiftungszweckChanged = 'StiftungszweckChanged',
  VertretungsbefugnisChanged = 'VertretungsbefugnisChanged',
  NationalesPatentAdded = 'NationalesPatentAdded',
  NationalesPatentChanged = 'NationalesPatentChanged',
  NationaleMarkeAdded = 'NationaleMarkeAdded',
  NationaleMarkeChanged = 'NationaleMarkeChanged',
  EuPatentAdded = 'EuPatentAdded',
  EuPatentChanged = 'EuPatentChanged',
  GewerbeberechtigungAdded = 'GewerbeberechtigungAdded',
  GewerbeberechtigungChanged = 'GewerbeberechtigungChanged',
  GewerbeberechtigungDeleted = 'GewerbeberechtigungDeleted',
  RelatedUnternehmenAdded = 'RelatedUnternehmenAdded',
  RelatedUnternehmenChanged = 'RelatedUnternehmenChanged',
  RelatedUnternehmenDeleted = 'RelatedUnternehmenDeleted',
  RelatedUnternehmenWortlautChanged = 'RelatedUnternehmenWortlautChanged',
}

export const enum PersonenFeedItemType
{
  PersonenDatenChanged = 'PersonenDatenChanged',
  FunktionAdded = 'FunktionAdded',
  FunktionChanged = 'FunktionChanged',
  FunktionDeleted = 'FunktionDeleted',
  GesellschafterAdded = 'GesellschafterAdded',
  GesellschafterChanged = 'GesellschafterChanged',
  GesellschafterDeleted = 'GesellschafterDeleted',
  UnternehmensWortlautChanged = 'UnternehmensWortlautChanged',
  UnternehmenGelöscht = 'UnternehmenGelöscht',
  GisaAdded = 'GisaAdded',
  GisaChanged = 'GisaChanged',
  GisaRemoved = 'GisaRemoved',
  GewerbeGeschäftsführerAdded = 'GewerbeGeschäftsführerAdded',
  GewerbeGeschäftsführerChanged = 'GewerbeGeschäftsführerChanged',
  GewerbeGeschäftsführerRemoved = 'GewerbeGeschäftsführerRemoved',
}