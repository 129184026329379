import React from 'react';
import { useToastNotifications } from 'utils/hooks';

import css from './ToastNotificationStack.module.scss';

const ToastNotificationStack: React.FC = () => 
{
  const [notifications] = useToastNotifications();

  if (notifications.length < 1) 
  {
    return null;
  }

  return (
        <div className={css.container}>

            {notifications.map((notification, index) => (
                <div key={`${notification}_${index}`} className={css.toast}>
                    {notification}
                </div>
            ))}

        </div>
  );
};

export default ToastNotificationStack;
