import type { PersonenDaten } from './personen';

export type ISO8601Date = string;
export type ISO8601DateTime = string;

export type FormErrors = Record<string, string[]>;

export type RecursivePartial<T> = {
  [P in keyof T]?:
  T[P] extends (infer U)[] ? RecursivePartial<U>[] :
    T[P] extends object ? RecursivePartial<T[P]> :
      T[P];
};

export type Size = {
  width?: number;
  height?: number;
};

export type GeoLocation = {
  latitude: number;
  longitude: number;
};

export type AdresseRM = {
  ort: string;
  straße: string;
  plz: string;
  länderCode: string;
  full: string;
  normalized: string;
  geolocation: GeoLocation | null;
};

export type Gemeinde = {
  gkz: string;
  bundesland: Bundesland;
  politischerBezirk: string;
};

export type Adresse = {
  straße: string;
  ort: string;
  plz: string;
  länderCode: string;
};

export type Geld = {
  währung: string;
  menge: number;
};

export type ÄnderungsArt = 'Neu' | 'Geändert' | 'Gelöscht' | 'Unverändert';

export enum Bundesland
  {
  Burgenland = 'Burgenland',
  Kärnten = 'Kärnten',
  Niederösterreich = 'Niederösterreich',
  Oberösterreich = 'Oberösterreich',
  Salzburg = 'Salzburg',
  Steiermark = 'Steiermark',
  Tirol = 'Tirol',
  Vorarlberg = 'Vorarlberg',
  Wien = 'Wien',
}

export enum Funktion
  {
  AbwicklerLiquidator = 'AbwicklerLiquidator',
  Alleinaktionär = 'Alleinaktionär',
  Aufsichtsperson = 'Aufsichtsperson',
  Aufsichtsratsmitglied = 'Aufsichtsratsmitglied',
  Ausgleichsverwalter = 'Ausgleichsverwalter',
  BesondererVerwalter = 'BesondererVerwalter',
  EhemannEhefrau = 'EhemannEhefrau',
  EinstweiligerVerwalter = 'EinstweiligerVerwalter',
  GerichtlicherErwachsenenVertreter = 'GerichtlicherErwachsenenVertreter',
  GeschäftsführenderDirektor = 'GeschäftsführenderDirektor',
  Geschäftsführer = 'Geschäftsführer',
  Geschäftsleiter = 'Geschäftsleiter',
  Gesellschafter = 'Gesellschafter',
  GesetzlicherVertreter = 'GesetzlicherVertreter',
  Hauptbevollmächtigter = 'Hauptbevollmächtigter',
  Inhaber = 'Inhaber',
  InländischerVertreter = 'InländischerVertreter',
  Insolvenzverwalter = 'Insolvenzverwalter',
  Kammerkommissar = 'Kammerkommissar',
  Kommanditist = 'Kommanditist',
  Komplementär = 'Komplementär',
  Masseverwalter = 'Masseverwalter',
  Mitglied = 'Mitglied',
  MittlerweiligerStellvertreter = 'MittlerweiligerStellvertreter',
  None = 'None',
  Obmann = 'Obmann',
  ObmannStellvertreter = 'ObmannStellvertreter',
  Prokurist = 'Prokurist',
  Pächter = 'Pächter',
  Regierungskommissar = 'Regierungskommissar',
  Revisionsverband = 'Revisionsverband',
  Sachwalter = 'Sachwalter',
  SachwalterAbgb = 'SachwalterAbgb',
  Sanierungsverwalter = 'Sanierungsverwalter',
  Stellvertreter = 'Stellvertreter',
  StellvertreterDesVorsitzenden = 'StellvertreterDesVorsitzenden',
  StändigerVertreter = 'StändigerVertreter',
  Treuhänder = 'Treuhänder',
  UnbeschränktHaftenderGesellschafter = 'UnbeschränktHaftenderGesellschafter',
  Unknown = 'Unknown',
  VertreterDesRuhendenNachlasses = 'VertreterDesRuhendenNachlasses',
  VertretungsbefugtesOrgan = 'VertretungsbefugtesOrgan',
  Verwaltungsratsmitglied = 'Verwaltungsratsmitglied',
  VorläufigerVerwalter = 'VorläufigerVerwalter',
  Vorsitzender = 'Vorsitzender',
  Vorstand = 'Vorstand',
  Zwangsverwalter = 'Zwangsverwalter',
}

export interface FunktionsTimelineZeitpunkt
{
  date: ISO8601Date;
  items: FunktionsTimelineItem[];
}

export interface PremiumInfoDto
{}

export type AdditionalPlusInfoPage<T> = {
  id: string;
  name: string;
  hauptPersonenDaten: null;
  datum?: ISO8601Date;
  items: T;
  premiumInfo: PremiumInfoDto;
  tier: Tier;
} | {
  id: string;
  name: undefined;
  hauptPersonenDaten: PersonenDaten;
  datum?: ISO8601Date;
  items: T;
  premiumInfo: PremiumInfoDto;
  tier: Tier;
};

export type FunktionsTimelineDto = AdditionalPlusInfoPage<FunktionsTimelineZeitpunkt[]>;

export interface FunktionsTimelineItem
{
  key: string;
  änderungsArt: ÄnderungsArt;
  ref: Referenz;
  funktion: Funktion;
  zeichnungsberechtigung?: string;
  nextIndex: number;
}

export interface BeteiligungsTimelineItem
{
  key: string;
  änderungsArt: ÄnderungsArt;
  ref: Referenz;
  anteil: number;
  kapital: Geld;
}

export type InsolvenzBeschluss = {
  bekanntmachungsDatum: ISO8601Date;
  beschlussDatum: ISO8601Date;
  titel: string;
  text: string;
};

export type Insolvenz = {
  ediktId: string;
  aktenzeichen: string;
  gericht: string;
  verfahrensart: 'Konkursverfahren' | 'MitEigenverwaltung' | 'OhneEigenverwaltung' | 'Konkurseröffnungsverfahren' | 'AusländischesInsolvenzverfahren';
  beschlüsse: InsolvenzBeschluss[];
};

export type PatentVeröffentlichungRM = {
  veröffentlichungsNummer: string;
  veröffentlichungsArt: string;
  ausgabedatum: string;
};

export type PatentErfinderRM = {
  name: string;
  land: string;
  ort: string;
};

export type PatentRM = {
  id: string;
  art: 'National' | 'Europäisch';
  anmeldeNummer: string | null;
  titel: string;
  anmeldeDatum: string | null;
  löschWirkDatum: string | null;
  löschEintragungDatum: string | null;
  status: string;
  ipc1: string | null;
  ipcs: string[];
  veröffentlichungen: PatentVeröffentlichungRM[];
  erfinder: PatentErfinderRM[];
  atPublDatum: string | null;
  erteilPublDatum: string | null;
  eNummer: string | null;
  sprache: string | null;
};

export type Gewerbeberechtigung = {
  gisaNr: string;
  wortlaut: string;
  startDatum: ISO8601Date;
};

export type GewerbeberechtigungInhaber = Gewerbeberechtigung & {
  verwendetVon: Referenz[];
  geschäftsführer: Referenz | null;
};

export type GewerbeberechtigungNutzer = Gewerbeberechtigung & {
  geschäftsführer: Referenz | null;
  inhaber: Referenz | null;
};

export type GewerbeberechtigungGeschäftsführer = Gewerbeberechtigung & {
  inhaber: Referenz | null;
};

export type NetzwerkDto = {
  gesellschafter: BeteiligungsTimelineItem[];
  beteiligungen: BeteiligungsTimelineItem[];
};

export type FeaturedProfiles = {
  newest: Referenz[];
  top: Referenz[];
  neueintragungen: Referenz[];
  insolvenzen: Referenz[];
  scheinunternehmen: Referenz[];
};

export enum UnternehmensStatus 
  {
  Unbekannt = 'Unbekannt',
  Aktiv = 'Aktiv',
  Gelöscht = 'Gelöscht',
  InLiquidation = 'InLiquidation',
}

export type Tier = 'Free' | 'Freemium' | 'Plus';

export type KurzAdresse = {
  plz: string | null;
  ort: string | null;
};

export interface PaginatedResult<TResult> 
{
  results: TResult[];
  totalResults: number;
}

export interface Referenz 
{
  id: string;
  type: ReferenzType;
  name: string;
  isIndexed: boolean;
}

export enum ReferenzType 
  {
  NatürlichePerson = 'NatürlichePerson',
  JuristischePerson = 'JuristischePerson',
  Unternehmen = 'Unternehmen',
  AusländischesUnternehmen = 'AusländischesUnternehmen',
}