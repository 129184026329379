import React from 'react';

import css from '../Startseite.module.scss';

interface GridProps
{
  id?: string;
  columns?: number;
  gap?: string;
  children: React.ReactNode;
}

const Grid: React.FC<GridProps> = ({ columns = 3, gap, children, id }) =>
{
  return (
    <div
      id={id}
      className={css.grid}
      style={{ gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`, gap }}
    >
      {children}
    </div>
  );
};

export default Grid;
