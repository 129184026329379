import { Badge } from 'components/atoms';
import React from 'react';
import { useNotificationCountQuery } from 'utils/hooks/queries';

const NotificationCountBadge: React.FC = () =>
{
  const { data } = useNotificationCountQuery();

  if (!data || data === 0)
  {
    return null;
  }

  return (<Badge count={data} max={99} />);
};

export default NotificationCountBadge;
