import { Anchor } from 'components/atoms';
import type { Namespace, ParseKeys } from 'i18next';
import { Trans, useTranslation } from 'next-i18next';
import type { PropsWithChildren, ReactNode } from 'react';
import React from 'react';


export type TranslationKey = ParseKeys<Namespace>;
export type TranslationNamespace = Namespace;
export type RichText = string | ReactNode | ReactNode[];

export type RichTextComponent = React.ReactElement<any, string | React.JSXElementConstructor<any>>;

type RichTextFunction = (
  key: ParseKeys<Namespace>,
  components?: Record<string, any>,
  values?: Record<string, any>,
) => ReactNode | ReactNode[] | string;

const Filter: React.FC<PropsWithChildren> = ({ children }) => 
{
  if (children && Array.isArray(children) && children.length > 0)
  {
    return <strong className="text-secondary">{children}</strong>;
  }
  else 
  {
    return <span className="text-muted">Alle</span>;
  }
};

const useCustomTranslation = (namespaces?: Namespace) => 
{
  const translation = useTranslation(namespaces);

  const defaultRichTextComponents: Record<string, RichTextComponent> = {
    auszug: <Anchor href="https://www.auszug.at" external target="_blank"/>,
    contact: <Anchor href="mailto:kontakt@wirtschaft.at"/>,
    products: <Anchor href="https://www.wirtschaft.at/produkte"/>,
    b: <strong/>,
    br: <br/>,
    ul: <ul/>,
    ol: <ol/>,
    li: <li/>,
    space: <>&nbsp;</>,
    plus: <span className='plus'/>,
    filter: <Filter />,
    danger: <strong className='text-danger'/>,
  };

  const r: RichTextFunction = (key, components, values) => 
  {
    const comp = { ...defaultRichTextComponents, ...(components || {}) };

    return (
            <Trans
                i18nKey={key as any}
                components={comp}
                values={values}
                count={values?.count || undefined}
            />
    );
  };

  return { ...translation, r } as const;
};

export default useCustomTranslation;