import NHead from 'next/head';
import { useRouter } from 'next/router';
import React, { PropsWithChildren } from 'react';
import { getHostname } from 'utils';
import { useTranslation } from 'utils/hooks';


interface HeadProps 
{
  title?: string;
  description?: string;
  children?: React.ReactNode;
}

const Head: React.FC<HeadProps> = (props: PropsWithChildren<HeadProps>) => 
{
  const { t } = useTranslation('common');

  const { title, description, children } = props;

  const router = useRouter();

  const hostname = getHostname();
  const currentPage = hostname + router.asPath;

  const metaTitle = title ? `${title} - wirtschaft.at` : 'wirtschaft.at';
  const metaDesc = description || t('common:meta.description');

  return (
        <NHead>

            <title>{metaTitle}</title>
            <meta name="description" content={metaDesc}/>

            <meta property="og:title" content={metaTitle}/>
            <meta property="og:description" content={metaDesc}/>
            <meta property="og:url" content={currentPage}/>
            <meta property="og:image" content={`${hostname}/static/images/OG_512x512.jpg`}/>
            <meta property="og:image:type" content="image/jpg"/>
            <meta property="og:image:alt" content="wirtschaft.at Logo"/>
            <meta property="og:image:width" content="512"/>
            <meta property="og:image:height" content="512"/>
            <meta property="og:site_name" content="wirtschaft.at"/>
            <meta property="og:type" content="website"/>

            <meta name="twitter:title" content={metaTitle}/>
            <meta name="twitter:description" content={metaDesc}/>
            <meta name="twitter:url" content={currentPage}/>
            <meta name="twitter:image" content={`${hostname}/static/images/OG_512x256.jpg`}/>
            <meta name="twitter:image:type" content="image/jpg"/>
            <meta name="twitter:image:alt" content="wirtschaft.at Logo"/>
            <meta name="twitter:image:width" content="512"/>
            <meta name="twitter:image:height" content="256"/>
            <meta name="twitter:card" content="summary_large_image"/>

            {children}
        </NHead>
  );
};

export default Head;
