import React from 'react';

const MasterCard: React.FC = () => (
   <svg
      height={24}
      viewBox="0 0 116.6205 72.141432"
      version="1.1"
      id="svg1351"
      xmlns="http://www.w3.org/2000/svg"
      xmlns-svg="http://www.w3.org/2000/svg">
     <defs
        id="defs1355" />
     <g
        id="g1349"
        transform="translate(-17.819262,-17.927877)">
       <rect
          width="152.407"
          height="108"
          fill="none"
          id="rect1337"
          x="0"
          y="0" />
       <g
          id="g1347">
         <rect
            x="60.411701"
            y="25.6968"
            width="31.5"
            height="56.6064"
            fill="#ff5f00"
            id="rect1339" />
         <path
            d="m 382.20839,306 a 35.9375,35.9375 0 0 1 13.7499,-28.3032 36,36 0 1 0 0,56.6064 A 35.938,35.938 0 0 1 382.20839,306 Z"
            transform="translate(-319.79649,-252)"
            fill="#eb001b"
            id="path1341" />
         <path
            d="m 454.20349,306 a 35.99867,35.99867 0 0 1 -58.2452,28.3032 36.00518,36.00518 0 0 0 0,-56.6064 35.99867,35.99867 0 0 1 58.2452,28.3032 z"
            transform="translate(-319.79649,-252)"
            fill="#f79e1b"
            id="path1343" />
         <path
            d="m 450.76889,328.3077 v -1.1589 h 0.4673 v -0.2361 h -1.1901 v 0.2361 h 0.4675 v 1.1589 z m 2.3105,0 v -1.3973 h -0.3648 l -0.41959,0.9611 -0.41971,-0.9611 h -0.365 v 1.3973 h 0.2576 v -1.054 l 0.3935,0.9087 h 0.2671 l 0.39351,-0.911 v 1.0563 z"
            transform="translate(-319.79649,-252)"
            fill="#f79e1b"
            id="path1345" />
       </g>
     </g>
   </svg>
   
);

export default MasterCard;
