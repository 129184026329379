import { MutationFunction, useMutation } from '@tanstack/react-query';
import { createAxiosInstance } from 'api/request';
import { FilterGroupsPersonen, FilterGroupsUnternehmen } from 'components/profiles/ZeitlicherVerlauf/FilterBlocks';
import { MutationHook } from 'utils/hooks/mutations/types';


type RequestReturnType = void;
type RequestParameters = {
  type: 'unternehmen';
  disabled: FilterGroupsUnternehmen[];
} | {
  type: 'personen';
  disabled: FilterGroupsPersonen[];
};

const request: MutationFunction<RequestReturnType, RequestParameters> = async ({ type, disabled }) =>
{
  await createAxiosInstance().post(`/user/notifications/filter/${type}`, { disabled });
};

const useFilterEmailNotificationsMutation: MutationHook<RequestReturnType, RequestParameters> = (options) =>
{
  return useMutation({
    mutationFn: request,
    ...options,
  });
};

export default useFilterEmailNotificationsMutation;
