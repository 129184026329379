import React from 'react';

interface SpacerProps
{
  width?: number;
  height?: number;
}

const Spacer: React.FC<SpacerProps> = (props) =>
{
  const { width, height } = props;

  return (
    <div style={{ width: `${width}rem`, height: `${height}rem` }} />
  );
};

export default Spacer;
