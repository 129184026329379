import React from 'react';

const Visa: React.FC = () => (
   <svg
   version="1.1"
   id="Layer_1"
   x="0px"
   y="0px"
   viewBox="0 0 1924.6659 626.08956"
   xml-space="preserve"
   height={21}
   xmlns="http://www.w3.org/2000/svg"
   xmlns-svg="http://www.w3.org/2000/svg"><defs
   id="defs9" />
<path
   fill="#1434CB"
   d="m 733.28342,13.416266 -251.37,599.740004 h -164 l -123.7,-478.62 c -7.51,-29.48 -14.04,-40.280004 -36.88,-52.700004 -37.29,-20.23 -98.870003,-39.21 -153.0500033,-50.99 l 3.68,-17.43 H 271.95342 c 33.65,0 63.9,22.4 71.54,61.15 l 65.33,347.040004 161.46,-408.200004 h 163 z M 1375.8634,417.34627 c 0.66,-158.29 -218.88,-167.01 -217.37,-237.72 0.47,-21.52 20.96,-44.4 65.81,-50.24 22.23,-2.91 83.48,-5.13 152.95,26.84 l 27.25,-127.180004 c -37.33,-13.55 -85.36,-26.5899998 -145.12,-26.5899998 -153.35,0 -261.26998,81.5199998 -262.17998,198.2500038 -0.99,86.34 77.02998,134.52 135.80998,163.21 60.47,29.38 80.76,48.26 80.53,74.54 -0.43,40.23 -48.23,57.99 -92.9,58.69 -77.98,1.2 -123.22998,-21.1 -159.29998,-37.87 l -28.12,131.39 c 36.25,16.63 103.15998,31.14 172.52998,31.87 162.99,0 269.61,-80.51 270.11,-205.19 m 404.94,195.81 h 143.49 l -125.25,-599.740004 h -132.44 c -29.78,0 -54.9,17.34 -66.02,44 l -232.81,555.740004 h 162.91 l 32.34,-89.58 h 199.05 z m -173.11,-212.5 81.66,-225.18 47,225.18 z M 954.95342,13.416266 826.66342,613.15627 h -155.14 l 128.34,-599.740004 z"
   id="path4" />
</svg>

);

export default Visa;
