import { Anchor } from 'components/atoms';
import { signIn } from 'next-auth/react';
import React from 'react';
import { useTranslation } from 'utils/hooks';

import Störer from '../Störer/Störer';

import css from './PremiumCta.module.scss';

const PremiumCta: React.FC = () =>
{
  const { t, r } = useTranslation();

  return (
    <>
      <div className={css.cta}>

        <div className={css.störerContainer}>
          <Störer><span>{`${t('common:premium.störer')}*`}</span></Störer>
        </div>

        <p>{r('common:premium.cta')}</p>

        <div className={css.row}>
          <button
            type="button"
            className={css.login}
            onClick={() => signIn('azure-ad-b2c')}
          >
            {t('common:user.login')}
          </button>
          <Anchor as="button" href="/produkte">
            {t('common:premium.button')}
          </Anchor>
        </div>

      </div>

      <small>{r('common:premium.legal')}</small>
    </>
  );
};

export default PremiumCta;
