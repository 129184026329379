import { QueryFunction, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { getRuntimeEnvVar } from 'utils';
import type { BlogPost, QueryHook } from 'utils/hooks/queries/types';

type RequestReturnType = BlogPost[];

const request: QueryFunction<RequestReturnType> = async ({ signal }) =>
{
  const url = getRuntimeEnvVar('BLOG_URL');
  const params = new URLSearchParams({ 'per_page': '3', 'sticky': 'true' });
  const response = await axios.get(`${url}/wp-json/wp/v2/posts?${params.toString()}`, { signal });
  return response.data;
};

export const BASE_QUERY_KEY = 'wirtschaft-at-blog-sticky';

const useStickyBlogPostsQuery: QueryHook<RequestReturnType> = () =>
{
  return useQuery({
    queryKey: [BASE_QUERY_KEY],
    queryFn: request,
    gcTime: 60 * 60 * 1000,
  });
};

export default useStickyBlogPostsQuery;