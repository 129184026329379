import clsx from 'clsx';
import React, { useRef, useState } from 'react';
import { Props, ScrollMenu } from 'react-horizontal-scrolling-menu';
import { Link } from 'react-scroll';

import Arrow from './Arrow';
import css from './TabNavigation.module.scss';

export type TabNavigationItem = {
  text: string;
  href: string;
  hidden?: boolean;
};

interface TabNavigationProps 
{
  items: TabNavigationItem[];
  initialActive?: number;
  renderItem?: (item: TabNavigationItem, index: number, isActive: boolean, setActiveItem: (index: number) => void) => React.ReactElement;
}

const TabNavigation: React.FC<TabNavigationProps> = (props) => 
{
  const { items, initialActive = 0, renderItem } = props;

  const [buttonsVisible, setButtonsVisible] = useState<boolean>(false);
  const [activeItem, setActiveItem] = useState<number>(initialActive);
  const scrollRef: NonNullable<Props['apiRef']> | React.MutableRefObject<null> = useRef(null);

  const visibleItems = items.filter((item) => !item.hidden);

  const defaultRenderItem = (item: TabNavigationItem, index: number, isActive: boolean): React.ReactElement =>
    (
      <Link
        to={item.href}
        className={clsx(css.item, { [css.active]: isActive })}
        id={`tab-navigation-${item.href}`}
        key={`tab-navigation-${item.href}`}
        onSetActive={() => scrollToItem(index, `tab-navigation-${item.href}`)}
        offset={-109}
        smooth
        spy
      >
        {item.text}
      </Link>
    );

  function scrollToItem(index: number, id: string) 
  {
    setActiveItem(index);
    if (!scrollRef.current) return;

    const element = scrollRef.current.getItemElementById(id);

    if (element) 
    {
      scrollRef.current.scrollToItem(element);
    }
  }

  return (
    <nav className={clsx(css.navigation, { [css.buttonsVisible]: buttonsVisible })}>
      <ScrollMenu
        LeftArrow={<Arrow type="prev" numOfItems={visibleItems.length} setButtonsVisible={setButtonsVisible}/>}
        RightArrow={<Arrow type="next" numOfItems={visibleItems.length} setButtonsVisible={setButtonsVisible}/>}
        scrollContainerClassName={css.scrollContainer}
        apiRef={scrollRef as unknown as Props['apiRef']}
      >
        {visibleItems.map((item, index) =>
          renderItem ? renderItem(item, index, activeItem == index, setActiveItem) : defaultRenderItem(item, index, activeItem == index),
        )}
      </ScrollMenu>
    </nav>
  );
};

export default TabNavigation;
