import useBrowserStorage from './useBrowserStorage';
import useWindowSize from './useWindowSize';

const usePaginationLimit = (): number => 
{
  const [resultLimit] = useBrowserStorage<number>('wss.result-limit', -1, 'localStorage');
  const { isMobile } = useWindowSize();

  if (resultLimit > 0) 
  {
    return resultLimit;
  }

  return isMobile ? 15 : 30;
};

export default usePaginationLimit;