import { Anchor, AuftragAtLogo, AuszugAtLogo, Logo, Show } from 'components/atoms';
import { signIn, useSession } from 'next-auth/react';
import React from 'react';
import { signOut } from 'utils/functions';
import { useTranslation, useUserTier } from 'utils/hooks';

import css from './Footer.module.scss';

const Footer: React.FC = () =>
{
  const { t } = useTranslation('common');
  const { status } = useSession();
  const tier = useUserTier();

  const buildNumber = process.env.NEXT_PUBLIC_BUILD_NUMBER;

  return (
    <footer className={css.footer}>
      <span className={css.build}>{`Build: ${buildNumber}`}</span>

      <div className={css.footerContainer}>
        <Anchor
          aria-label="wirtschaft.at"
          href="/">
          <Logo variant="light"/>
        </Anchor>


        <Show when={status === 'authenticated'}>
          <nav className={css.userAnchors}>
            <Anchor href="/user">{t('common:user.myProfile')}</Anchor>
            <Anchor href="/user/subscriptions">
              {t('common:user.mySubscriptions')}
            </Anchor>
            <button onClick={() => signOut({ signOutIdentityProvider: true })}>{t('common:user.logout')}</button>
          </nav>
        </Show>

        <Show when={status !== 'authenticated'}>
          <nav className={css.anchors}>
              <button
                type="button"
                onClick={() => signIn('azure-ad-b2c')}>
                {t('common:user.login')}
              </button>
            <Anchor href="/user/register">{t('common:footer.register')}</Anchor>
          </nav>
        </Show>

        <nav className={css.anchors}>
          {tier !== 'plus' && (
            <Anchor href='/produkte'>
              {t('common:footer.products')}
            </Anchor> )}
          <Anchor href="/faq">{t('common:footer.faq')}</Anchor>
          <Anchor href="https://www.wirtschaft.at/blog" target='_blank'>{t('common:footer.blog')}</Anchor>
          <Anchor href="/agb">{t('common:footer.termsAndConditions')}</Anchor>
          <Anchor href="/impressum">{t('common:footer.imprint')}</Anchor>
          <Anchor href="/datenschutz">{t('common:footer.legal')}</Anchor>
          <Anchor href="/cookie-policy">{t('common:footer.cookiePolicy')}</Anchor>

          <Anchor target="_blank"
                  href="https://www.facebook.com/wirtschaft.auszug">{t('common:footer.facebook')}</Anchor>
          <Anchor target="_blank"
                  href="https://www.linkedin.com/company/wirtschaft-at/">{t('common:footer.linkedIn')}</Anchor>
        </nav>
      </div>

      <div className={css.footerSecondaryContainer}>
        <div className={css.mediengruppe}>
          {t('common:footer.plattformFrom')}
          <span>
            Mediengruppe
            <br/>
            Wiener Zeitung
          </span>
          &copy; {new Date().getFullYear()}
        </div>

        <div className={css.otherServices}>
          {t('common:footer.otherServices')}
          <ul>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.auftrag.at"
                aria-label="auftrag.at">
                <AuftragAtLogo/>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.auszug.at"
                aria-label="auszug.at">
                <AuszugAtLogo/>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
