import { QueryFunction, useQuery } from '@tanstack/react-query';
import { createAxiosInstance } from 'api/request';
import { PaymentInfo } from 'types/user';
import { QueryHook } from 'utils/hooks/queries/types';

type RequestReturnType = PaymentInfo;

const request: QueryFunction<RequestReturnType> = async ({ signal }) =>
{
  const response = await createAxiosInstance().get('/user/payment/stripe-payment-info', { signal });
  return response.data;
};

export const BASE_QUERY_KEY = 'paymentInfo';

const usePaymentInfoQuery: QueryHook<RequestReturnType> = () =>
{
  return useQuery({
    queryKey: [BASE_QUERY_KEY],
    queryFn: request,
  });
};

export default usePaymentInfoQuery;