import clsx from 'clsx';
import { ActivityIndicator, Anchor, IconButton, Show } from 'components/atoms';
import { PersonalFeed } from 'components/user';
import React from 'react';
import { clamp } from 'utils';
import { usePaginationLimit, useTranslation } from 'utils/hooks';
import { usePersonalFeedQuery } from 'utils/hooks/queries';

import css from '../Startseite.module.scss';

const Subscriptions: React.FC = () =>
{
  const { t } = useTranslation();
  const limit = usePaginationLimit();

  const { data, isLoading, isError, refetch } = usePersonalFeedQuery(0, 4);

  const x = clamp(data?.results.length || 0, 0, 4);
  const n = data?.totalResults || 0;

  return (
    <div className={clsx(css.card, css.personalUpdates)}>
      <h3>{t('user:feed.heading')}</h3>

      <Show when={isLoading}>
        <ActivityIndicator size='md' variant='secondary' />
      </Show>

      <Show when={isError}>
        <p>{t('user:feed.error')}</p>
        <IconButton icon='refresh' onClick={refetch} />
      </Show>

      {data && data.results.length === 0 && (
        <p>{t('user:feed.noContent')}</p>
      )}

      {data && data.results.length > 0 && (
        <>
          <PersonalFeed feed={data.results} isPreview />
          <p>
            {t('formats:xOfN', { x, n })} - <Anchor href={`/user/updates?page=1&limit=${limit}`}>{t('common:actions.viewAll')}</Anchor>
          </p>
        </>
      )}

    </div>
  );
};

export default Subscriptions;
