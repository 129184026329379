import { MutationFunction, useMutation } from '@tanstack/react-query';
import { createAxiosInstance } from 'api/request';
import type { ReferenzType } from 'types';
import type { RechnungsAdresse } from 'types/user';
import { MutationHook } from 'utils/hooks/mutations/types';

type RequestReturnType = { url: string };
type RequestParameters = {
  profileId: string;
  profileType: ReferenzType;
  email: string;
  rechnungsAdresse: RechnungsAdresse | null;
};

const request: MutationFunction<RequestReturnType, RequestParameters> = async (params) =>
{
  const response = await createAxiosInstance().post('/direktkauf', { ...params });
  return response.data;
};

const useDirektkaufMutation: MutationHook<RequestReturnType, RequestParameters> = (options) =>
{
  return useMutation({
    mutationFn: request,
    ...options,
  });
};

export default useDirektkaufMutation;