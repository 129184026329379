import * as Tabs from '@radix-ui/react-tabs';
import { Anchor, Show } from 'components/atoms';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { clamp } from 'utils';
import { useTranslation, useWindowSize } from 'utils/hooks';

import FirmenWizard from './FirmenWizard';
import css from './SearchWizard.module.scss';

export interface CategoryWizardProps<T>
{
  id: string;
  onSubmit: (type: string, filters: Record<string, string>) => void;
  onMinimize: () => void;
  defaultValues?: Partial<T>;
}

interface SearchWizardProps
{
  defaultValues?: any;
  defaultMinimized?: boolean;
  defaultSelected?: string;
  onWizardSubmit?: () => void;
}

let lastSelected = '';

const SearchWizard: React.FC<SearchWizardProps> = (props) =>
{
  const {
    defaultValues,
    defaultMinimized = false,
    defaultSelected = 'unternehmen',
    onWizardSubmit = () => {},
  } = props;

  const { width } = useWindowSize();
  const [expandedTabCount, setExpandedTabCount] = useState<number>(0);
  const [selectedTab, setSelectedTab] = useState<string>(defaultSelected);
  const [minimized, setMinimized] = useState<boolean>(defaultMinimized);
  const router = useRouter();
  const { t, r } = useTranslation('search');

  const tabs = [
    { value: 'unternehmen', label: t('search:wizard.unternehmen.tabs.unternehmen.label') },
    // { value: 'personen', label: t('search:wizard.unternehmen.tabs.personen.label') },
    // { value: 'marken', label: t('search:wizard.unternehmen.tabs.marken.label') },
    // { value: 'patente', label: t('search:wizard.unternehmen.tabs.patente.label') },
  ];

  const collapsedTabs = [...tabs];
  const expandedTabs = collapsedTabs.splice(0, expandedTabCount);

  useEffect(() => 
  {
    // TODO: find good values for responsivness
    // TODO: make dropdown 100% width on mobile
    setExpandedTabCount(clamp(Math.floor((width ? width - 400 : 0) / 100), 0, 4));
  }, [width]);

  function onSubmit(type: string, filters: Record<string, string>)
  {
    const sanitized: Record<string, string> = {};

    Object.entries(filters).forEach(([key, value]) =>
    {
      if (value && value.length > 0)
      {
        sanitized[key] = value;
      }
    });

    if (Object.keys(sanitized).length > 0)
    {
      const query = new URLSearchParams({ ...sanitized, view: type, page: '1', limit: '15', scroll: 'results' });
      onWizardSubmit();
      router.push(`/detailsuche/${type}?${query}`, undefined, { shallow: false });
    }
  }

  return (
    <div className={css.container}>
      <Tabs.Root
        orientation="horizontal"
        className={css.root}
        onValueChange={(value) => 
        {
          lastSelected = selectedTab;
          setSelectedTab(value);
        }}
        value={selectedTab}
      >
        
        <Tabs.List className={css.list}>

          {/* Render expanded tabs as Buttons */}
          {expandedTabs.map((tab) => (
            <Tabs.Trigger
              key={tab.value}
              value={tab.value}
              onClick={() => 
              {
                setMinimized(tab.value === lastSelected && !minimized);
                lastSelected = tab.value;
              }}
            >
              {tab.label}
            </Tabs.Trigger>
          ))}

          {/* Render collapsed tabs as Dropdown */}
          {collapsedTabs.length > 0 && (
            <Tabs.Trigger
              value={collapsedTabs[0].value}
              asChild
            >
              <select
                onChange={(e) =>
                {
                  setMinimized(false);
                  setSelectedTab(e.target.value);
                }}
                role="tab"
                aria-selected={collapsedTabs.some((tab) => tab.value === selectedTab)}
              >
                {collapsedTabs.map((tab) => (
                  <option key={tab.value} value={tab.value}>{tab.label}</option>
                ))}
              </select>
            </Tabs.Trigger>
          )}

          <Show when={minimized}>
            <Anchor
              className={css.expand}
              onClick={() => setMinimized(false)}
            >
              {t('search:wizard.actions.maximize')}
            </Anchor>
          </Show>

        </Tabs.List>

        <Show whenNot={minimized}>

          <p className={css.description}>{r('search:wizard.description')}</p>
          
          <FirmenWizard
            id="unternehmen"
            onSubmit={onSubmit}
            defaultValues={defaultSelected === 'unternehmen' ? defaultValues : undefined}
            onMinimize={() => setMinimized(true)}
          />

          {/* <PersonenWizard
            id="personen"
            onSubmit={onSubmit}
            defaultValues={defaultSelected === 'personen' ? defaultValues : undefined}
            onMinimize={() => setMinimized(true)}
          /> */}

          {/* <MarkenWizard
            id="marken"
            onSubmit={onSubmit}
            defaultValues={defaultSelected === 'marken' ? defaultValues : undefined}
            onMinimize={() => setMinimized(true)}
          /> */}

          {/* <PatenteWizard
            id="patente"
            onSubmit={onSubmit}
            defaultValues={defaultSelected === 'patente' ? defaultValues : undefined}
            onMinimize={() => setMinimized(true)}
          /> */}

        </Show>
        
      </Tabs.Root>
    </div>
  );
};

export default SearchWizard;
