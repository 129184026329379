import { PersonenFeedItemType, UnternehmensFeedItemType } from 'types/änderungen';

export enum FilterGroupsPersonen 
  {
  Basisinformationen = 'Basisinformationen',
  Funktionen = 'Funktionen',
  Gesellschafter = 'Gesellschafter',
  Gisa = 'Gisa',
  Unternehmen = 'Unternehmen',
}
export const FilterBlocksPersonen = {
  [FilterGroupsPersonen.Basisinformationen]: [
    PersonenFeedItemType.PersonenDatenChanged,
  ],
  [FilterGroupsPersonen.Funktionen]: [
    PersonenFeedItemType.FunktionAdded,
    PersonenFeedItemType.FunktionChanged,
    PersonenFeedItemType.FunktionDeleted,
  ],
  [FilterGroupsPersonen.Gesellschafter]: [
    PersonenFeedItemType.GesellschafterAdded,
    PersonenFeedItemType.GesellschafterChanged,
    PersonenFeedItemType.GesellschafterDeleted,
  ],
  [FilterGroupsPersonen.Gisa]: [
    PersonenFeedItemType.GisaAdded,
    PersonenFeedItemType.GisaChanged,
    PersonenFeedItemType.GisaRemoved,
    PersonenFeedItemType.GewerbeGeschäftsführerAdded,
    PersonenFeedItemType.GewerbeGeschäftsführerChanged,
    PersonenFeedItemType.GewerbeGeschäftsführerRemoved,
  ],
  [FilterGroupsPersonen.Unternehmen]: [
    PersonenFeedItemType.UnternehmensWortlautChanged,
    PersonenFeedItemType.UnternehmenGelöscht,
  ],
};

export enum FilterGroupsUnternehmen 
  {
  Basisinformation = 'Basisinformation',
  Funktionen = 'Funktionen',
  Aufsichtsrat = 'Aufsichtsrat',
  Gesellschafter = 'Gesellschafter',
  Rechtstatsachen = 'Rechtstatsachen',
  Jahresabschlüsse = 'Jahresabschlüsse',
  Ots = 'Ots',
  Gewerbeberechtigung = 'Gewerbeberechtigung',
  Marken = 'Marken',
  Patente = 'Patente',
}

export const FilterBlocksUnternehmen = {

  [FilterGroupsUnternehmen.Basisinformation]: [
    UnternehmensFeedItemType.UnternehmenEingetragen,
    UnternehmensFeedItemType.RechtsformChanged,
    UnternehmensFeedItemType.StatusGelöscht,
    UnternehmensFeedItemType.StatusInLiquidation,
    UnternehmensFeedItemType.StatusNichtMehrInLiquidation,
    UnternehmensFeedItemType.NameChanged,
    UnternehmensFeedItemType.AdresseChanged,
    UnternehmensFeedItemType.SitzChanged,
    UnternehmensFeedItemType.AuslandsInfoChanged,
    UnternehmensFeedItemType.VertretungsbefugnisChanged,
    UnternehmensFeedItemType.JabStichtagChanged,
    UnternehmensFeedItemType.KabStichtagChanged,
    UnternehmensFeedItemType.KapitalChanged,
    UnternehmensFeedItemType.AktieChanged,
    UnternehmensFeedItemType.AktieDeleted,
    UnternehmensFeedItemType.GeschäftszweigeChanged,
    UnternehmensFeedItemType.StiftungszweckChanged,
    UnternehmensFeedItemType.ScheinunternehmenAnnouncementAdded,
    UnternehmensFeedItemType.InsolvenzBeschlussAdded,
  ],
  [FilterGroupsUnternehmen.Funktionen]: [
    UnternehmensFeedItemType.FunktionAdded,
    UnternehmensFeedItemType.FunktionChanged,
    UnternehmensFeedItemType.FunktionDeleted,
    UnternehmensFeedItemType.PersonNameChanged,
  ],
  [FilterGroupsUnternehmen.Aufsichtsrat]: [
    UnternehmensFeedItemType.AufsichtsratAdded,
    UnternehmensFeedItemType.AufsichtsratChanged,
    UnternehmensFeedItemType.AufsichtsratDeleted,
  ],
  [FilterGroupsUnternehmen.Gesellschafter]: [
    UnternehmensFeedItemType.GesellschafterChanged,
    UnternehmensFeedItemType.GesellschafterAdded,
    UnternehmensFeedItemType.GesellschafterDeleted,
    UnternehmensFeedItemType.RelatedUnternehmenAdded,
    UnternehmensFeedItemType.RelatedUnternehmenChanged,
    UnternehmensFeedItemType.RelatedUnternehmenDeleted,
    UnternehmensFeedItemType.RelatedUnternehmenWortlautChanged,
  ],
  [FilterGroupsUnternehmen.Rechtstatsachen]: [
    UnternehmensFeedItemType.RechtstatsacheAdded,
  ],
  [FilterGroupsUnternehmen.Jahresabschlüsse]: [
    UnternehmensFeedItemType.JahresabschlussAdded,
  ],
  [FilterGroupsUnternehmen.Ots]: [
    UnternehmensFeedItemType.OtsAussendungPublished,
  ],
  [FilterGroupsUnternehmen.Gewerbeberechtigung]: [
    UnternehmensFeedItemType.GewerbeberechtigungAdded,
    UnternehmensFeedItemType.GewerbeberechtigungChanged,
    UnternehmensFeedItemType.GewerbeberechtigungDeleted,
  ],
  [FilterGroupsUnternehmen.Marken]: [
    UnternehmensFeedItemType.NationaleMarkeAdded,
    UnternehmensFeedItemType.NationaleMarkeChanged,
  ],
  [FilterGroupsUnternehmen.Patente]: [
    UnternehmensFeedItemType.NationalesPatentAdded,
    UnternehmensFeedItemType.NationalesPatentChanged,
    UnternehmensFeedItemType.EuPatentAdded,
    UnternehmensFeedItemType.EuPatentChanged,
  ],


};
