import { Icon } from 'components/atoms';
import React from 'react';

import css from './ZahlungsStatus.module.scss';

interface ZahlungsStatusProps
{
  success: boolean;
  heading: React.ReactNode | React.ReactNode[];
  text: React.ReactNode | React.ReactNode[];
}

const ZahlungsStatus: React.FC<ZahlungsStatusProps> = ({ success, heading, text }) =>
{
  return (
    <div className={css.container}>
      <div className={css.iconContainer}>
        <Icon name={success ? 'check' : 'close'} />
      </div>
      <div>
        <h2>{heading}</h2>
        <p>{text}</p>
      </div>
    </div>
  );
};

export default ZahlungsStatus;
