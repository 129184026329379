import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { ActivityIndicator, Button, Icon } from 'components/atoms';
import { useCombobox, UseComboboxStateChange } from 'downshift';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import type { SearchCount, Suggestion, SuggestResults } from 'types/suche';
import { getIconNameFromReferenz, getLinkFromReferenz, getNatPersonNameAndBirthyear } from 'utils';
import { useDebounce, usePaginationLimit, useTranslation } from 'utils/hooks';

import css from './QuickSearch.module.scss';
import SearchResult from './SearchResult';

export interface QuickSearchProps 
{
  firmenbuch?: boolean;
  suggesterFn: (term: string, signal?: AbortSignal) => Promise<SuggestResults>;
  countFn: (term: string, signal?: AbortSignal) => Promise<SearchCount>;
  onResultClicked?: () => void;
  initialValue?: string;
  autoFocus?: boolean;
}

const minSuggesterInputThreshold = 3;

const QuickSearch: React.FC<QuickSearchProps> = (props) => 
{
  const { firmenbuch = false, autoFocus = true, suggesterFn, countFn, initialValue } = props;
  const { t, r } = useTranslation(['common', 'search', 'formats', 'forms']);
  const router = useRouter();
  const limit = usePaginationLimit();

  const [debouncedInput, , setDebouncedInput, forceDebouncedInput] = useDebounce<string>('', 350);

  const queryClient = useQueryClient();

  const debouncedTrimmedInput = debouncedInput.trim();

  const {
    data: suggestions,
    isFetching: isSuggestionFetching,
  } = useQuery(
    {
      queryKey: ['quicksearch', 'suggestion', debouncedTrimmedInput],
      queryFn: ({ signal }) => suggesterFn(debouncedTrimmedInput, signal),
      enabled: debouncedTrimmedInput.length >= minSuggesterInputThreshold,
      refetchOnWindowFocus: false,
      select: (data) => 
      {
        const unternehmen: Suggestion[] = [...data.unternehmen].splice(0, 5).map((suggestion) => 
        {
          const href = firmenbuch ? `/firmenbuch/${suggestion.ref.id}` : getLinkFromReferenz(suggestion.ref);
          return ({
            href,
            iconName: getIconNameFromReferenz(suggestion.ref),
            text: t('formats:searchResultUnternehmen', {
              name: suggestion.ref.name,
              adresse: suggestion.kurzAdresse,
              fbNr: suggestion.ref.id,
            }),
          });
        });

        const personen: Suggestion[] = [...data.personen].splice(0, 5).map((suggestion) => ({
          href: getLinkFromReferenz(suggestion.ref),
          iconName: getIconNameFromReferenz(suggestion.ref),
          text: getNatPersonNameAndBirthyear(t, suggestion.ref.name, suggestion.geburtsdatum),
        }));

        return [...unternehmen, ...personen];
      },
    },
  );

  const {
    data: count,
    isFetching: isCountFetching,
    isError: isCountError,
  } = useQuery(
    {
      queryKey: ['quicksearch', 'count', debouncedTrimmedInput],
      queryFn: ({ signal }) => countFn(debouncedTrimmedInput, signal),
      enabled: debouncedTrimmedInput.length >= minSuggesterInputThreshold,
      placeholderData: keepPreviousData,
      refetchOnWindowFocus: false,
    },
  );

  const {
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    selectItem,
    setInputValue,
  } = useCombobox<Suggestion | null>({
    items: suggestions ?? [],
    onInputValueChange: onInputValueChange,
    itemToString: (selected) => selected?.text ?? '',
    defaultInputValue: initialValue,
    id: 'quick-search',
  });

  useEffect(onInitialValueChange, [initialValue]);
  useEffect(onDebouncedInputChange, [debouncedInput]);

  // ------------------------------------------------
  // Event handlers
  // ------------------------------------------------

  function onInitialValueChange() 
  {
    setDebouncedInput('');
    queryClient.setQueriesData({ queryKey:['quicksearch'] }, () => undefined);
    setInputValue(initialValue ?? debouncedInput);
  }

  function onDebouncedInputChange() 
  {
    if (debouncedInput.length < minSuggesterInputThreshold) 
    {
      queryClient.setQueriesData({ queryKey:['quicksearch'] }, () => undefined);
    }
  }

  function onResultClicked() 
  {
    if (props.onResultClicked) 
    {
      props.onResultClicked();
    }
    queryClient.cancelQueries({ queryKey:['quicksearch'] });
  }

  function onInputValueChange(changes: UseComboboxStateChange<Suggestion | null>) 
  {
    const { inputValue, type, selectedItem } = changes;

    if (type === useCombobox.stateChangeTypes.InputKeyDownEnter && !!selectedItem) 
    {
      onResultClicked();
      router.push(selectedItem.href);
      return;
    }

    if (type === useCombobox.stateChangeTypes.FunctionOpenMenu) 
    {
      // mouse click on link triggers this type
      return;
    }

    if (inputValue !== undefined) 
    {
      // removing end of line parentheses (fbNr, birthdate) due to tab complete not finding results
      setDebouncedInput(inputValue.replace(/( \(.+\))$/, '').trim());
    }
  }

  function onSearchStatusClick() 
  {
    if (isSuggestionFetching) 
    {
      return;
    }

    if (debouncedInput.length > 0) 
    {
      forceDebouncedInput('');
      queryClient.setQueriesData({ queryKey:['quicksearch'] }, () => undefined);
      selectItem(null);
      queryClient.cancelQueries({ queryKey:['quicksearch'] });
    }
  }

  function onSearchButtonClick(type: 'u' | 'p') 
  {
    queryClient.cancelQueries({ queryKey:['quicksearch'] });

    const params = new URLSearchParams({
      term: debouncedInput,
      page: '1',
      limit: limit.toString(),
    });

    router.push(firmenbuch ? `/firmenbuch/suche?${params.toString()}` : `/suche/${type}?${params.toString()}`);
  }

  // ------------------------------------------------
  // Other functions
  // ------------------------------------------------

  function renderSearchStatusText(): JSX.Element | null 
  {
    if (isSuggestionFetching) 
    {
      return <p>{r('search:quick.creatingSuggestions')}</p>;
    }

    if (debouncedInput.length < 1) 
    {
      return null;
    }

    if (debouncedInput.length < minSuggesterInputThreshold) 
    {
      return <p>{t('forms:errors.minLength', { value: minSuggesterInputThreshold })}</p>;
    }

    if (!suggestions || suggestions.length === 0) 
    {
      return <p>{r('search:quick.noSuggestions', undefined, { term: debouncedInput })}</p>;
    }

    return <p>{r('search:quick.suggestionsForTerm', undefined, {
      count: suggestions.length,
      term: debouncedInput,
    })}</p>;
  }

  // ------------------------------------------------
  // Rendering
  // ------------------------------------------------

  return (
        <div className={css.container}>

            {/* Searchbar */}
          <div className={css.searchBar}>

                <input
                    {...getInputProps({
                      placeholder: t('search:quick.placeholder'),
                      autoFocus,
                      onKeyDown: (event) => 
                      {
                        if (event.key === 'Home' || event.key === 'End') 
                        {
                          // eslint-disable-next-line no-param-reassign
                          (event.nativeEvent as any).preventDownshiftDefault = true;
                        }
                      },
                    })}
                />

            <span
              className={clsx(css.searchStatus, { [css.clickable]: !isSuggestionFetching && debouncedInput.length > 0 })}
              onClick={onSearchStatusClick}
            >
          {!isSuggestionFetching && debouncedInput.length < 1 && <Icon name="search"/>}
              {!isSuggestionFetching && debouncedInput.length > 0 && <Icon name="close"/>}
              {isSuggestionFetching && <ActivityIndicator variant="secondary"/>}
        </span>

          </div>
          {/* End of Searchbar */}


          {/* Search Results */}
            <div
                {...getMenuProps()}
                aria-label={t('common:aria.quicksearch.results')}
                className={debouncedInput.length > 0 ? css.searchContainer : undefined}
            >

                {/* Search Status Text */}
                {renderSearchStatusText()}

                {/* Result List */}
                {!isSuggestionFetching && suggestions && suggestions.length > 0 && (
                    <div className={clsx(css.searchResultContainer, 'scrollbars')}>
                        {suggestions.map((item, index) => (
                            <SearchResult
                                key={item.href + index}
                                icon={item.iconName}
                                isHighlighted={highlightedIndex === index}
                                itemProps={getItemProps({ item, index })}
                                text={item.text}
                                href={item.href}
                                onClick={onResultClicked}
                            />
                        ))}
                    </div>
                )}

                {/* Buttons */}
                {debouncedInput.length >= minSuggesterInputThreshold && (
                    <div className={css.buttonContainer}>

                        <p>
                            {r('search:quick.searchForTerm',
                              { secondary: <span className={css.term}/> },
                              { term: debouncedInput })}
                        </p>

                        {isCountError && <p>{t('search:quick.searchError')}</p>}

                        {!isCountError && (
                            <div className={css.row}>

                                <Button onClick={() => onSearchButtonClick('u')}>
                                    <Icon name="building"/>
                                    {r('search:quick.searchUnternehmen_interval', undefined, {
                                      postProcess: 'interval',
                                      count: isCountFetching ? -1 : (count?.unternehmen ?? 0),
                                    })}
                                    {isCountFetching && <>&nbsp;(&nbsp;<ActivityIndicator variant="auto"
                                                                                          size="xs"/>&nbsp;)</>}
                                </Button>

                                {!firmenbuch && <Button onClick={() => onSearchButtonClick('p')}>
                                    <Icon name="person"/>
                                    {r('search:quick.searchPersonen_interval', undefined, {
                                      postProcess: 'interval',
                                      count: isCountFetching ? -1 : (count?.personen ?? 0),
                                    })}
                                    {isCountFetching && <>&nbsp;(&nbsp;<ActivityIndicator variant="auto"
                                                                                          size="xs"/>&nbsp;)</>}
                                </Button>}

                            </div>
                        )}

                    </div>
                )}

            </div>
            {/* End of Search Results */}

        </div>
  );
};

export default QuickSearch;
