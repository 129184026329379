import clsx from 'clsx';
import React from 'react';

import css from './PageHeading.module.scss';

interface PageHeadingProps
{
  title: string;
  narrow?: boolean;
  description?: string;
  children?: React.ReactNode;
}

const PageHeading: React.FC<PageHeadingProps> = (props) =>
{
  const {
    title,
    narrow,
    description,
    children,
  } = props;

  return (
    <>
      <div className={clsx('full-width-ignore-bounds', css.container)}>
        <div className={clsx(css.content, { [css.narrow]: narrow })}>
          <h1>{title}</h1>
          {children}
        </div>
      </div>

      {description && (
        <div className={clsx('full-width-ignore-bounds', css.descriptionContainer)}>
          <p className={clsx({ [css.narrow]: narrow })}>{description}</p>
        </div>
      )}

    </>
  );
};

export default PageHeading;
