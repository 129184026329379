import { atom, useAtom } from 'jotai';
import { useRouter } from 'next/router';

const showSearchState = atom<boolean>(false);

const useShowSearch = () =>
{
  const router = useRouter();
  const [showSearch, setShowSearch] = useAtom(showSearchState);
  
  const isStartPage = router.pathname === '/';
  const isSuche = router.pathname.startsWith('/suche');

  const show = (() =>
  {
    if (isSuche) return false;
    if (isStartPage) return true;
    return showSearch;
  })();

  return {
    showSearch: show,
    showSearchButton: !isStartPage && !isSuche,
    setShowSearch: setShowSearch,
  };
};

export default useShowSearch;