import clsx from 'clsx';
import { ActivityIndicator, Button, LabeledInput, Show } from 'components/atoms';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'utils/hooks';
import { useSubscribeToNewsletterMutation } from 'utils/hooks/mutations';

import css from '../Startseite.module.scss';

interface NewsletterFields
{
  name: string;
  email: string;
  accept: boolean;
}

const NewsletterTile: React.FC = () =>
{
  const { t } = useTranslation(['startseite', 'forms']);
  const { register, handleSubmit, reset, formState: { errors } } = useForm<NewsletterFields>();

  const mutation = useSubscribeToNewsletterMutation({
    onSuccess: () => reset(),
  });

  const onSubmit: SubmitHandler<NewsletterFields> = (data) =>
  {
    mutation.mutate({ email: data.email, name: data.name.length > 0 ? data.name : undefined });
  };

  return (
    <div className={clsx(css.card, css.newsletter)}>
      <h3>{t('startseite:newsletter.heading')}</h3>
      <p>{t('startseite:newsletter.desc')}</p>

      <form className={css.form} onSubmit={handleSubmit(onSubmit)}>

        <LabeledInput
          id="name"
          label={t('startseite:newsletter.inputs.name.label')}
          variant="grid"
        >
          <input
            type="name"
            placeholder={t('startseite:newsletter.inputs.name.placeholder')}
            {...register('name')}
          />
        </LabeledInput>

        <LabeledInput
          id="email"
          label={t('startseite:newsletter.inputs.email.label')}
          error={errors.email?.message}
          variant="standalone"
        >
          <input
            type="email"
            placeholder={t('startseite:newsletter.inputs.email.placeholder')}
            {...register('email', {
              required: { value: true, message: t('forms:errors.invalidEmail') },
            })}
          />
        </LabeledInput>

        <div className={css.legal}>
          <LabeledInput
            id="accept"
            label={t('startseite:newsletter.inputs.legal.label')}
            error={errors.accept?.message}
            checkbox
            variant="standalone"
          >
            <input
              type="checkbox"
              {...register('accept', {
                required: { value: true, message: t('forms:errors.required') },
              })}
            />
          </LabeledInput>
        </div>

        <Show when={mutation.isError && !mutation.isPending}>
          <p className={css.statusMessage}>{t('startseite:newsletter.onError')}</p>
        </Show>

        <Show when={mutation.isSuccess && !mutation.isPending}>
          <p className={css.statusMessage}>{t('startseite:newsletter.onSuccess')}</p>
        </Show>

        <Button type="submit" disabled={mutation.isPending}>
          {mutation.isPending && <ActivityIndicator variant="auto" size="xs" />}
          {t('startseite:newsletter.button')}
        </Button>

      </form>
    </div>
  );
};

export default NewsletterTile;
