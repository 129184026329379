import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { deserializeQueryParam } from 'utils';

const useAutomaticScroll = () => 
{
  const router = useRouter();

  const scrollToTarget = (target: string) => 
  {
    document.getElementById(target)?.scrollIntoView({
      behavior: 'smooth', // for smooth scrolling
      block: 'start',      // align to the top of the viewport
    });
  };

  useEffect(() => 
  {
    const scrollTarget = deserializeQueryParam(router.query.scroll);

    if (scrollTarget) 
    {
      setTimeout(() => scrollToTarget(scrollTarget), 1);
    }

  }, [router]);
};

export default useAutomaticScroll;
