import React, { useState } from 'react';
import { useTranslation } from 'utils/hooks';

import Icon from '../Icon/Icon';

import css from './ContentLimiter.module.scss';

interface ContentLimiterProps 
{
  children: React.ReactNode;
  stepSize?: number;
  initialLimit?: number;
  tableColSpan?: number;
  showMoreText?: string;
}

const ContentLimiter: React.FC<ContentLimiterProps> = (props) => 
{
  const { t } = useTranslation('common');
  const {
    stepSize = 3,
    initialLimit,
    tableColSpan,
    showMoreText,
    children,
  } = props;

  const [limit, setLimit] = useState<number>(initialLimit ?? stepSize);
  const childNodes = Array.isArray(children) ? children : [children];

  function requestMore() 
  {
    setLimit(stepSize < 0 ? childNodes.length : limit + stepSize);
  }

  return (
        <>
            {[...childNodes].splice(0, limit)}

            {/* Render button normally */}
            {!tableColSpan && limit < childNodes.length && (
                <div className={css.buttonContainer}>
                    <span className={css.line}/>
                    <button type="button" onClick={requestMore}>
                        {showMoreText ? showMoreText : t('common:actions.viewMore', { count: childNodes.length - limit })}
                        <Icon name="chevronDown"/>
                    </button>
                    <span className={css.line}/>
                </div>
            )}

            {/* Render button as table row */}
            {tableColSpan && limit < childNodes.length && (
                <tr>
                    <td colSpan={tableColSpan}>
                        <div className={css.buttonContainer}>
                            <span className={css.line}/>
                            <button type="button" onClick={requestMore}>
                                {showMoreText ? showMoreText : t('common:actions.viewMore', { count: childNodes.length - limit })}
                                <Icon name="chevronDown"/>
                            </button>
                            <span className={css.line}/>
                        </div>
                    </td>
                </tr>
            )}

        </>
  );
};

export default ContentLimiter;
