import { getSearchCount, getSuggestions } from 'api/search';
import { RadioBar, Show } from 'components/atoms';
import { QuickSearch } from 'components/molecules';
import { useSession } from 'next-auth/react';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { GAEvents } from 'utils/events/google';
import { useBrowserStorage, useTranslation } from 'utils/hooks';
import { useShowSearch } from 'utils/hooks/state';

import UnternehmensDetailsuche from '../Detailsuche/UnternehmensDetailsuche';

import css from './Suche.module.scss';

type SearchMode = 'quick' | 'detail';

interface SucheProps 
{
  fixedMode?: SearchMode;
  defaultValues?: { quick?: string; detail?: any };
  autoFocus?: boolean;
}

interface SucheFields
{
  selected: SearchMode;
}

const Suche: React.FC<SucheProps> = (props) =>
{
  const { fixedMode, defaultValues, autoFocus = true } = props;
  const [preferredSearchMode, setPreferredSearchMode] = useBrowserStorage<SearchMode>('wss.search-mode', 'detail', 'localStorage');
  const { status } = useSession();
  const { t, r } = useTranslation();
  const { setShowSearch } = useShowSearch();

  const defaultSelected: SearchMode = (() =>
  {
    if (fixedMode) return fixedMode;
    if (status !== 'authenticated') return 'quick';
    return preferredSearchMode;
  })();

  const { watch, control } = useForm<SucheFields>({ defaultValues: { selected: defaultSelected } });
  const selected = watch('selected');

  useEffect(() => setPreferredSearchMode(selected), [selected]);

  return (
    <div className={css.container}>

      <Show when={!fixedMode}>
        <div className={css.actions}>
          <RadioBar
            control={control}
            name="selected"
            options={[
              { label: t('search:tabs.quicksearch'), value: 'quick', event: GAEvents.SEARCH_TOGGLE_SCHNELLSUCHE },
              { label: t('search:tabs.detail'), value: 'detail', event: GAEvents.SEARCH_TOGGLE_UNTERNEHMENSSUCHE },
            ]}
          />
        </div>
      </Show>

      <Show when={selected === 'quick'}>
        <span className={css.labelQuick}>{r('search:quick.label')}</span>
        <QuickSearch
          suggesterFn={getSuggestions}
          countFn={getSearchCount}
          initialValue={defaultValues?.quick || ''}
          autoFocus={autoFocus}
          onResultClicked={() => setShowSearch(false)}
        />
      </Show>

      <Show when={selected === 'detail'}>
        <span className={css.labelDetail}>{r('search:wizard.cta')}</span>
        <UnternehmensDetailsuche defaultValues={defaultValues?.detail} />
      </Show>

    </div>
  );
};

export default Suche;
