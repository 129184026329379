import React, { ReactNode } from 'react';
import {
  PopoverArrow,
  PopoverDisclosure,
  usePopoverState,
  Popover as RPopover,
} from 'reakit';

interface PopoverProps
{
  disclosure: ReactNode;
  placement?: 'top' | 'bottom' | 'left' | 'right';
  children: React.ReactNode;
}

const Popover: React.FC<PopoverProps> = (props) =>
{
  const { disclosure, placement = 'top', children } = props;
  
  const popover = usePopoverState({ placement });
  
  return (
    <>
      <PopoverDisclosure {...popover} as="div">
        { disclosure }
      </PopoverDisclosure>
      <RPopover {...popover} aria-label="">
        <PopoverArrow {...popover} />
        { children }
      </RPopover>
    </>
  );
};

export default Popover;
