import clsx from 'clsx';
import React from 'react';

import css from './ActivityIndicator.module.scss';

type ActivityIndicatorProps = {
  size?: 'xs' | 'sm' | 'md' | 'lg';
  variant?: 'primary' | 'secondary' | 'primary-foreground' | 'auto';
};

const ActivityIndicator: React.FC<ActivityIndicatorProps> = (props) => 
{
  const { variant = 'primary', size = 'small' } = props;

  return (
        <div className={css[`container-${size}`]}>
            <div className={clsx(css[`bars-${size}`], css[variant])}/>
        </div>
  );
};

export default ActivityIndicator;