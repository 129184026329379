import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Anchor, Icon } from 'components/atoms';
import { useRouter } from 'next/router';
import { signIn, useSession } from 'next-auth/react';
import React from 'react';
import { signOut } from 'utils/functions';
import { useTranslation, useUserTier } from 'utils/hooks';

import css from './Avatar.module.scss';

interface AvatarProps 
{
}

const Avatar: React.FC<AvatarProps> = () => 
{
  const { t } = useTranslation('common');
  const { data: session } = useSession();
  const tier = useUserTier();
  const router = useRouter();

  if (tier === 'free') 
  {
    return <div className={css.actionLinks}>
      <Anchor
        aria-label={t('common:user.login')}
        type="button"
        onClick={() => signIn('azure-ad-b2c')}
        className={css.trigger}
      >
        <span>{t('common:user.login')}</span>
        <Icon name="person"/>
      </Anchor>
    </div>;
  }

  return (
    <DropdownMenu.Root>

      <DropdownMenu.Trigger
        className={css.trigger}
      >
          <span>
            {session?.user.name}
          </span>
        <Icon name="person"/>
      </DropdownMenu.Trigger>

      <DropdownMenu.Content
        className={css.dropdown}
        side="bottom"
        align="end"
        sideOffset={10}
      >

        <DropdownMenu.Item onSelect={() => router.push('/user')}>
          {t('common:user.myProfile')}
          <Icon name="settings"/>
        </DropdownMenu.Item>

        <DropdownMenu.Item onSelect={() => signOut({ signOutIdentityProvider: true })}>
          {t('common:user.logout')}
          <Icon name="logout"/>
        </DropdownMenu.Item>

      </DropdownMenu.Content>
    </DropdownMenu.Root>);

};

export default Avatar;
