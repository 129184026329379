import clsx from 'clsx';
import { Icon, Show } from 'components/atoms';
import React from 'react';


import css from './ProgressLine.module.scss';

interface ProgressLineProps
{
  steps: string[];
  progress: number;
}

const ProgressLine: React.FC<ProgressLineProps> = (props) =>
{
  const { steps, progress } = props;

  return (
  <div className={css.container}>

      <div className={css.line} />

      {steps.map((step, index) => (
        <div
          key={step + index}
          className={clsx(css.step, {
            [css.done]: index < progress,
            [css.active]: index === progress,
          })}
        >
          <div className={css.circle}>
            <Show when={progress > index}>
              <Icon name="check" />
            </Show>
          </div>

          <span>{step}</span>
        </div>
      ))}

    </div>
  );
};

export default ProgressLine;
