import { LabeledInput } from 'components/atoms';
import React, { forwardRef } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { FormErrors } from 'types';
import { UserKontaktDaten } from 'types/user';
import { useTranslation, useUserForm } from 'utils/hooks';
import { UserFormSectionRef } from 'utils/hooks/useUserForm';

import FormActions from './FormActions';
import SectionHeading from './SectionHeading';
import css from './UserDataForm.module.scss';

export interface KontaktDatenFormProps
{
  onFormSubmit: (data: any) => Promise<boolean>;
  initialValues?: UserKontaktDaten;
  reviewMode?: boolean;
  serverSideErrors?: FormErrors;
  mode?: 'register' | 'update' | 'view';
}

const KontaktDatenForm = forwardRef<UserFormSectionRef, KontaktDatenFormProps>((props, ref) =>
{
  const { reviewMode = false, initialValues = {}, onFormSubmit, serverSideErrors, mode = 'register' } = props;
  const { t } = useTranslation(['user', 'forms']);

  const parseDataOnSubmit = (data: UserKontaktDaten) => ({ kontaktDaten: data });

  const {
    editable,
    setEditable,
    cancelEditing,
    form: { handleSubmit, register, formState: { errors } },
  } = useUserForm<UserKontaktDaten>({ ...initialValues }, reviewMode, serverSideErrors, ref, parseDataOnSubmit, mode);

  const onSubmit: SubmitHandler<UserKontaktDaten> = async (data) =>
  {
    const success = await onFormSubmit(parseDataOnSubmit(data));
    if (success) setEditable(!reviewMode);
  };
  
  return (   
    <section id="kontakt" className={css.container}>
      <form onSubmit={handleSubmit(onSubmit)}>

        <SectionHeading
          text={t('user:form.contactData.heading') + (mode === 'view' ? '*' : '')}
          subText={mode === 'view' ? '*' + t('user:form.contactData.fromGoogle') : undefined}
          editable={editable || mode === 'view'}
          setEditable={setEditable}
        />

        <div className={css.gridContainer}>
          <div className={css.grid2} />
          {/* Name */}
          <LabeledInput
            id="vorname"
            label={t('user:form.contactData.name.label')}
            error={errors.name?.message}
            className={css.grid4}
          >
            <input
              type="text"
              readOnly={!editable}
              autoComplete="name"
              placeholder={t('user:form.contactData.name.placeholder')}
              {...register('name', {
                required: { value: true, message: t('forms:errors.required') },
              })}
            />
          </LabeledInput>

          {/* Email */}
          <LabeledInput
            id="email"
            label={t('user:form.contactData.email.label')}
            error={errors.email?.message}
            className={css.grid4}
          >
            <input
              type="email"
              readOnly={!editable}
              autoComplete="email"
              placeholder={t('user:form.contactData.email.placeholder')}
              {...register('email', {
                required: { value: true, message: t('forms:errors.required') },
              })}
            />
          </LabeledInput>

        </div>

        <FormActions
          cancelEditing={cancelEditing}
          visible={reviewMode && editable}
        />
    
      </form>
    </section>
  );
});

export default KontaktDatenForm;
