import { Anchor, Referenz } from 'components/atoms';
import React from 'react';
import { ReferenzType } from 'types';
import type { UnternehmenSearchResult } from 'types/suche';
import { getLinkFromReferenz } from 'utils';
import { useTranslation } from 'utils/hooks';


import css from './QuickSearch.module.scss';
import SearchResult from './SearchResult';
import StatusTag from './StatusTag';

interface GroupedSearchResultProps 
{
  result: UnternehmenSearchResult;
  firmenbuch?: boolean;
}

const GroupedSearchResult: React.FC<GroupedSearchResultProps> = (props) => 
{
  const { result, firmenbuch = false } = props;
  const { t } = useTranslation('formats');

  if (result.ref.type !== ReferenzType.Unternehmen) 
  {
    return (
            <Referenz data={result.ref}>
                {({ ref, href, iconName }) => (
                    <SearchResult
                        key={ref.id}
                        href={href}
                        icon={iconName}
                        isHighlighted={false}
                        text={ref.name}
                    />
                )}
            </Referenz>
    );
  }

  const href = firmenbuch ? `/firmenbuch/${result.ref.id}` : getLinkFromReferenz(result.ref);

  return (
        <div className={css.groupedSearchResult}>

            <div className={css.statusContainer}>
                <StatusTag status={result.status}/>
            </div>

            <div>
                <Anchor href={href}>
                    {t('formats:searchResultUnternehmen', {
                      name: result.ref.name,
                      adresse: result.kurzAdresse,
                      fbNr: result.ref.id,
                    })}
                </Anchor>

                {result.pastNames.length > 0 && (
                    <div className={css.pastNamesContainer}>
                        {result.pastNames.map((past) => (
                            <Anchor key={past.name + past.nameIndex} href={`${href}?referral=${past.nameIndex}`}>
                                {`Vormals: ${past.name}`}
                            </Anchor>
                        ))}
                    </div>
                )}
            </div>

        </div>
  );
};

export default GroupedSearchResult;
