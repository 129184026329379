import React, { useEffect, useRef } from 'react';
import { FieldValues, useController, UseControllerProps } from 'react-hook-form';
import { analyticsEvent, GAEvents } from 'utils/events/google';

import css from './RadioBar.module.scss';

interface RadioBarProps<T extends FieldValues> extends UseControllerProps<T>
{
  options: Array<{ label: string; value: string | number; event?: GAEvents }>;
  disabled?: boolean;
}

function RadioBar<T extends FieldValues>(props: RadioBarProps<T>)
{
  const { options, disabled, ...controllerProps } = props;
  const { field, fieldState: { error } } = useController(props);

  const ref = useRef<HTMLLabelElement>(null);
  const defaultValue = controllerProps.defaultValue ?? (controllerProps.control?._defaultValues as Record<string, string | number>)[controllerProps.name];

  useEffect(() =>
  {
    if (error !== undefined)
    {
      ref.current?.scrollIntoView({ block: 'center' });
    }
  }, [error]);

  return (
    <div className={css.container}>
      <div className={css.options}>
        {options.map(option => (
          <div className={css.option} key={option.value.toString()}>
            <input
              {...field}
              type="radio"
              id={option.value.toString()}
              value={option.value}
              disabled={disabled}
              defaultChecked={option.value === defaultValue}
              onChange={(e) =>
              {
                if (option.event)
                {
                  analyticsEvent(option.event);
                }
                field.onChange(e);
              }}
            />
            <label ref={ref} htmlFor={option.value.toString()}>{option.label}</label>
          </div>
        ))}
      </div>
      {error && <small className={css.error}>{error.message}</small>}
    </div>
  );
}

export default RadioBar;
