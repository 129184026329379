import useBrowserStorage from './useBrowserStorage';


const useAboIntent = () =>
{
  const [get, set] = useBrowserStorage('aboIntent', false, 'sessionStorage');

  const setAboIntent = () =>
  {
    return set(true);
  };

  const clearAboIntent = () =>
  {
    return set(undefined);
  };
  
  return [get, setAboIntent, clearAboIntent] as const;
};

export default useAboIntent;