import { Anchor } from 'components/atoms';
import React from 'react';
import { useTranslation } from 'utils/hooks';
import { usePaymentInfoQuery, useStripePortalSessionQuery, useUserQuery } from 'utils/hooks/queries';
import { TranslationKey } from 'utils/hooks/useTranslation';


import SectionHeading from './SectionHeading';
import css from './UserDataForm.module.scss';

const PremiumAboInfo: React.FC = () =>
{
  const { t, r } = useTranslation();
  const { data: user } = useUserQuery();
  const { data: stripePortalLink } = useStripePortalSessionQuery();
  const { data: paymentInfo } = usePaymentInfoQuery();

  return (
    <section id="premiumAboInfo" className={css.container}>

      <SectionHeading text={t('user:premiumAboInfo.heading')} />

      <p>
        {r('user:premiumAboInfo.currentAbo', undefined, {
          abo: t(`products:${user!.abo}.title` as TranslationKey),
        })}
      </p>
      <p>
        {r('user:premiumAboInfo.aboSince', undefined, { date: user!.aboSince })}
      </p>
      {paymentInfo && paymentInfo.aboCancelled && (
        <p>
          {r('user:premiumAboInfo.aboUntil', undefined, { date: paymentInfo.nextDate })}
        </p>
      )}

      {stripePortalLink && (
        <Anchor className={css.manageAccount} href={stripePortalLink} as="button">
          {t('user:premiumAboInfo.manageAccountViaStripe')}
        </Anchor>
      )}

      {!stripePortalLink && (
        <>
          <p>
            {r('user:premiumAboInfo.closeAccount', undefined, {
              abo: t(`products:${user!.abo}.title` as TranslationKey),
              kundennummer: user?.kundennummer,
            })}
          </p>

          <p>{r('user:premiumAboInfo.contact')}</p>
        </>
      )}

    </section>
  );
};

export default PremiumAboInfo;
