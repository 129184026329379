import * as Tooltip from '@radix-ui/react-tooltip';
import React, { useEffect, useState } from 'react';
import { useBrowserStorage, useTranslation } from 'utils/hooks';
import type { RichText } from 'utils/hooks/useTranslation';

import css from './Hint.module.scss';

interface HintProps 
{
  id: string;
  text: RichText;
  actions: React.ReactNode;
  disabled?: boolean;
  side?: 'top' | 'bottom' | 'left' | 'right';
  align?: 'start' | 'center' | 'end';
  portalContainer?: HTMLElement | null;
  storage?: 'localStorage' | 'sessionStorage';
  children: React.ReactNode;
  delay?: number;
}

const Hint: React.FC<HintProps> = (props) => 
{
  const {
    id,
    children,
    text,
    actions,
    disabled = false,
    side = 'bottom',
    align = 'center',
    storage = 'localStorage',
    delay = 0,
  } = props;

  useEffect(() =>
  {
    if (delay === 0) return;
    const timer = setTimeout(() =>
    {
      setIsDelayed(false);
    }, delay * 1000);
    return () => clearTimeout(timer);
  }, [delay]);

  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useBrowserStorage<boolean>(`wss.hint.${id}`, true, storage);
  const [isDelayed, setIsDelayed] = useState(delay !== 0);

  if (disabled || !isOpen || isDelayed)
  {
    return <>{children}</>;
  }

  return (
        <Tooltip.Root
            delayDuration={0}
            open
        >

            <Tooltip.Trigger asChild>
                {children}
            </Tooltip.Trigger>

            <Tooltip.Content
                className={css.content}
                side={side}
                align={align}
            >
                <Tooltip.Arrow className={css.arrow} width={25} height={10}/>

                <p>{text}</p>

                <div className={css.actions}>
                    <button
                        onClick={() => setIsOpen(false)}
                        className={css.dismiss}
                    >
                        {t('common:actions.dismissHint')}
                    </button>

                    {actions}
                </div>

            </Tooltip.Content>

        </Tooltip.Root>
  );
};

export default Hint;
