import { ProgressLine } from 'components/molecules';
import React from 'react';
import { useTranslation } from 'utils/hooks';


interface RegisterProgressProps
{
  progress: number;
}

const RegisterProgress: React.FC<RegisterProgressProps> = (props) =>
{
  const { progress } = props;
  const { t } = useTranslation('user');

  return (
    <ProgressLine
      steps={t('user:register.steps', { returnObjects: true }) as string[]}
      progress={progress}
    />
  );
};

export default RegisterProgress;
