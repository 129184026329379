/* eslint-disable @typescript-eslint/indent */
import { AboMonatlich, Direktkauf, Product } from 'utils/products';

export enum GAEvents 
{
  SEARCH_GLOBALE_SUCHE = 'search-globale-suche',
  SEARCH_HEADER_SUCHE = 'search-header-suche',
  SEARCH_TOGGLE_SCHNELLSUCHE = 'search-toggle-schnellsuche',
  SEARCH_TOGGLE_UNTERNEHMENSSUCHE = 'search-toggle-unternehmenssuche',
}

export const analyticsEvent = (event: GAEvents, params: { [key: string]: any } = {}) =>
{
  const w: any = window || {};
  w.dataLayer = w.dataLayer || [];
  w.dataLayer.push({ event, ...params });
};

export const gaEventRegisterSuccess = (isUnternehmenskonto: boolean) =>
{
  const w: any = window || {};
  w.dataLayer = w.dataLayer || [];
  w.dataLayer.push({ ecommerce: null });
  w.dataLayer.push({
    event: 'eec.register',
    userType: isUnternehmenskonto ? 'Unternehmen' : 'Privatperson',
  });
};

// ---------------------------------------------------
// ABONNEMENT
// ---------------------------------------------------

export const gaEventAddAboToCart = () =>
{
  const w: any = window || {};
  w.dataLayer = w.dataLayer || [];
  w.dataLayer.push({ ecommerce: null });
  w.dataLayer.push({
    event: 'eec.addToCart',
    ecommerce: {
      currencyCode: 'EUR',
      add: {
        products: [{
          name: AboMonatlich.name,
          brand: 'WS',
          quantity: 1,
        }],
      },
    },
  });
};

export const gaEventRemoveAboFromCart = (product: Product, privatPerson?: boolean) =>
{
  const w: any = window || {};
  w.dataLayer = w.dataLayer || [];
  w.dataLayer.push({ ecommerce: null });
  w.dataLayer.push({
    event: 'eec.removeFromCart',
    userType: privatPerson === undefined ? undefined : privatPerson ? 'Privatperson' : 'Unternehmen',
    ecommerce: {
      currencyCode: 'EUR',
      remove: {
        products: [{
          name: product.name,
          id: product.id,
          price: product.price.toFixed(2),
          brand: 'WS',
          category: product.category,
          quantity: 1,
        }],
      },
    },
  });
};

export const gaEventCheckoutStep = (product: Product, privatPerson: boolean, step: 1 | 2) =>
{
  const w: any = window || {};
  w.dataLayer = w.dataLayer || [];
  w.dataLayer.push({ ecommerce: null });
  w.dataLayer.push({
    event: 'eec.checkout',
    userType: privatPerson ? 'Privatperson' : 'Unternehmen',
    ecommerce: {
      currencyCode: 'EUR',
      checkout: {
        actionField: { 'step': step },
        products: [{
          name: product.name,
          id: product.id,
          price: product.price.toFixed(2),
          brand: 'WS',
          category: product.category,
          quantity: 1,
        }],
      },
    },
  });
};

export const gaEventPurchase = (
  product: Product,
  privatPerson: boolean,
  transactionId: string,
  coupon: string | null,
  taxPaid: string,
  totalPaid: string,
) =>
{
  const w: any = window || {};
  w.dataLayer = w.dataLayer || [];
  w.dataLayer.push({ ecommerce: null });
  w.dataLayer.push({
    event: 'eec.purchase',
    userType: privatPerson ? 'Privatperson' : 'Unternehmen',
    ecommerce: {
      currencyCode: 'EUR',
      purchase: {
        actionField: {
          id: transactionId,
          revenue: totalPaid,
          tax: taxPaid,
          coupon: coupon ?? undefined,
        },
        products: [{
          name: product.name,
          id: product.id,
          price: product.price.toFixed(2),
          brand: 'WS',
          category: product.category,
          quantity: 1,
        }],
      },
    },
  });
};

// ---------------------------------------------------
// DIREKTKAUF
// ---------------------------------------------------

export const gtmDirektkaufAddToCart = (profileId: string) =>
{
  const w: any = window || {};
  w.dataLayer = w.dataLayer || [];
  w.dataLayer.push({ ecommerce: null });
  w.dataLayer.push({
    event: 'eec.addToCart',
    profileId,
    ecommerce: {
      currencyCode: 'EUR',
      add: {
        products: [{
          name: Direktkauf.name,
          id: Direktkauf.id,
          price: Direktkauf.price.toFixed(2),
          brand: 'WS',
          category: Direktkauf.category,
          variant: profileId,
          quantity: 1,
        }],
      },
    },
  });
};

export const gtmDirektkaufRemoveFromCart = (profileId: string) =>
{
  const w: any = window || {};
  w.dataLayer = w.dataLayer || [];
  w.dataLayer.push({ ecommerce: null });
  w.dataLayer.push({
    event: 'eec.removeFromCart',
    profileId,
    ecommerce: {
      currencyCode: 'EUR',
      remove: {
        products: [{
          name: Direktkauf.name,
          id: Direktkauf.id,
          price: Direktkauf.price.toFixed(2),
          brand: 'WS',
          category: Direktkauf.category,
          variant: profileId,
          quantity: 1,
        }],
      },
    },
  });
};

export const gtmDirektkaufCheckout = (profileId: string, step: 1 | 2) =>
{
  const w: any = window || {};
  w.dataLayer = w.dataLayer || [];
  w.dataLayer.push({ ecommerce: null });
  w.dataLayer.push({
    event: 'eec.checkout',
    profileId,
    ecommerce: {
      currencyCode: 'EUR',
      checkout: {
        actionField: { 'step': step },
        products: [{
          name: Direktkauf.name,
          id: Direktkauf.id,
          price: Direktkauf.price.toFixed(2),
          brand: 'WS',
          category: Direktkauf.category,
          variant: profileId,
          quantity: 1,
        }],
      },
    },
  });
};

export const gtmDirektkaufPurchase = (profileId: string, transactionId?: string) =>
{
  const w: any = window || {};
  w.dataLayer = w.dataLayer || [];
  w.dataLayer.push({ ecommerce: null });
  w.dataLayer.push({
    event: 'eec.purchase',
    profileId,
    ecommerce: {
      currencyCode: 'EUR',
      purchase: {
        actionField: {
          id: transactionId,
          revenue: (Direktkauf.price + Direktkauf.taxAmount).toFixed(2),
          tax: Direktkauf.taxAmount.toFixed(2),
        },
        products: [{
          name: Direktkauf.name,
          id: Direktkauf.id,
          price: Direktkauf.price.toFixed(2),
          brand: 'WS',
          category: Direktkauf.category,
          variant: profileId,
          quantity: 1,
        }],
      },
    },
  });
};