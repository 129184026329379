import clsx from 'clsx';
import { Icon } from 'components/atoms';
import React, { useEffect } from 'react';
import { VisibilityContext } from 'react-horizontal-scrolling-menu';


import css from './TabNavigation.module.scss';

interface ArrowProps 
{
  numOfItems: number;
  type: 'next' | 'prev';
  setButtonsVisible: (value: boolean) => void;
}

const Arrow: React.FC<ArrowProps> = ({ numOfItems, type, setButtonsVisible }) => 
{
  const {
    visibleItemsWithoutSeparators,
    isLastItemVisible,
    isFirstItemVisible,
    scrollNext,
    scrollPrev,
  } = React.useContext(VisibilityContext);

  const allItemsVisibleOrEmpty = [0, numOfItems].includes(visibleItemsWithoutSeparators.length);

  useEffect(() => setButtonsVisible(!allItemsVisibleOrEmpty), [visibleItemsWithoutSeparators.length]);

  if (allItemsVisibleOrEmpty) 
  {
    return null;
  }

  if (type === 'prev') 
  {
    return (
            <button
                className={clsx(css.arrow, css.prev)}
                onClick={() => scrollPrev('smooth')}
                disabled={isFirstItemVisible}
            >
                <Icon name="chevronLeft"/>
            </button>
    );
  }

  return (
        <button
            className={clsx(css.arrow, css.next)}
            onClick={() => scrollNext('smooth')}
            disabled={isLastItemVisible}
        >
            <Icon name="chevronRight"/>
        </button>
  );
};

export default Arrow;