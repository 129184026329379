import { keepPreviousData, useQuery, UseQueryResult } from '@tanstack/react-query';
import { createAxiosInstance } from 'api/request';
import { PaginatedResult } from 'types';
import { PersonenSearchResult } from 'types/suche';

type RequestReturnType = PaginatedResult<PersonenSearchResult>;

const request = async (term: string, page: number, limit: number, signal?: AbortSignal) =>
{
  const params = new URLSearchParams({
    term,
    page: page.toString(),
    limit: limit.toString(),
  });

  const response = await createAxiosInstance().get(`/search/personen?${params.toString()}`, { signal });
  return response.data;
};

export const BASE_QUERY_KEY = 'personenSearch';

const usePersonenSearchQuery = (
  term: string,
  page: number,
  limit: number,
): UseQueryResult<RequestReturnType, unknown> =>
{
  return useQuery({
    queryKey: [BASE_QUERY_KEY, term, page, limit],
    queryFn: ({ signal }) => request(term, page, limit, signal),
    placeholderData: keepPreviousData,
  },
  );
};

export default usePersonenSearchQuery;