import { ActivityIndicator, Button, Icon, RadioBar, Show } from 'components/atoms';
import ApplePay from 'components/atoms/Logos/ApplePay';
import GooglePay from 'components/atoms/Logos/GooglePay';
import MasterCard from 'components/atoms/Logos/MasterCard';
import Visa from 'components/atoms/Logos/Visa';
import React, { forwardRef, useEffect, useState } from 'react';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { FormErrors } from 'types';
import { CouponInfo } from 'types/user';
import { useTranslation, useUserForm } from 'utils/hooks';
import { useValidateCouponMutation } from 'utils/hooks/mutations';
import { RichText, TranslationKey } from 'utils/hooks/useTranslation';
import { UserFormSectionRef } from 'utils/hooks/useUserForm';
import PRODUCTS, { ProductKey } from 'utils/products';

import FormActions from './FormActions';
import SectionHeading from './SectionHeading';
import css from './UserDataForm.module.scss';

export interface KostenFormProps
{
  onFormSubmit?: (data: any) => Promise<boolean>;
  onCouponApplied?: (coupon: CouponInfo | undefined) => void;
  onProductKeyChanged?: (abo:  ProductKey) => void;
  mode: 'update' | 'register';
  reviewMode?: boolean;
  productKey?: ProductKey;
  serverSideErrors?: FormErrors;
}

export interface KostenFormFields
{
  abo: ProductKey;
  couponCode: string;
}

const KostenForm = forwardRef<UserFormSectionRef, KostenFormProps>((props, ref) =>
{
  const {
    reviewMode = false,
    mode,
    productKey = 'PremiumPlanYearlyPlus',
    serverSideErrors,
    onFormSubmit = () => {},
    onCouponApplied = () => {},
    onProductKeyChanged = () => {},
  } = props;

  const { t, r } = useTranslation();

  const validateCouponMutation = useValidateCouponMutation();
  const [couponInfo, setCouponInfo] = useState<CouponInfo | undefined>();

  const parseDataOnSubmit = (data: KostenFormFields) =>
  {
    return { abo: data.abo, couponInfo: couponInfo };
  };

  const {
    editable,
    setEditable,
    cancelEditing,
    form,
    form: { handleSubmit, register, control, watch, setValue },
  } = useUserForm<KostenFormFields>({ abo: productKey }, reviewMode, serverSideErrors, ref, parseDataOnSubmit);

  const abo = watch('abo');
  const couponCode = watch('couponCode');
  const product = PRODUCTS[abo];

  useEffect(() =>
  {
    setCouponInfo(undefined);
    setValue('couponCode', '');
    onProductKeyChanged(abo);
  }, [abo]);

  const validateCoupon = async () =>
  {
    setCouponInfo(undefined);
    const response = await validateCouponMutation.mutateAsync({ code: couponCode, product: abo });
    onCouponApplied(response);
    setCouponInfo(response);
  };

  const removeCoupon = () => 
  {
    setCouponInfo(undefined);
    onCouponApplied(undefined);
  };

  const onSubmit: SubmitHandler<KostenFormFields> = async (data) =>
  {
    const success = await onFormSubmit({ abo: data.abo, couponInfo: couponInfo });
    if (success) setEditable(!reviewMode);
  };

  const totalNetPrice = couponInfo?.coupon
    ? couponInfo.coupon.discountedPrice
    : product.price;

  const totalPrice = couponInfo?.coupon
    ? couponInfo.coupon.discountedPrice + couponInfo.coupon.taxAmount
    : product.price + product.taxAmount;

  const infos = [...t(`products:${abo}.description` as TranslationKey, { returnObjects: true }) as RichText[]].splice(1);

  if (couponInfo?.coupon?.durationInMonths)
  {
    infos.splice(0, 0, r('user:form.price.coupon.validInMonths', undefined, { count: couponInfo.coupon.durationInMonths }));
  }

  return (
  // @ts-ignore
    <FormProvider {...form}>
      <section id="kosten" className={css.container}>
        <form onSubmit={handleSubmit(onSubmit)}>

        <Show when={mode === 'register'}>
          <SectionHeading
            text={t('user:form.price.heading' as TranslationKey)}
            editable={editable}
            setEditable={setEditable}
          />
        </Show>

        <div className={css.kostenContainer}>

          <RadioBar
            control={control}
            name='abo'
            disabled={!editable}
            options={[
              { label: PRODUCTS.PremiumPlanYearlyPlus.fullName, value: 'PremiumPlanYearlyPlus' },
              { label: PRODUCTS.PremiumPlanMonthlyPlus.fullName, value: 'PremiumPlanMonthlyPlus' },
            ]}
          />

          <div className={css.banner}>
            <span>{t(`products:${abo}.price` as TranslationKey, { price: totalNetPrice })}</span>
            <div>
              <ul>{infos.map((info, i) => <li key={`info_${i}`}>{info}</li>)}</ul>
              <div className={css.paymentProviders}>
                <MasterCard/>
                <Visa/>
                <ApplePay/>
                <GooglePay/>
              </div>
            </div>
          </div>

          {/* Netto price */}
          <dl className={css.breakdown}>
            <div className={css.row}>
              <dt>{t('user:form.price.breakdown.netto', { productName: product.category })}</dt>
              <dl>{t('formats:euro', { value: product.price })}</dl>
            </div>


            {/* When coupon is set */}
            {couponInfo?.coupon && (
              <div className={css.row}>
                <dt>

                  <span className={css.coupon}>
                    {couponInfo.coupon.code}
                    {editable && (
                      <button type="button" onClick={removeCoupon}>
                        <Icon name="close" />
                      </button>
                    )}
                  </span>

                  {couponInfo.coupon.discountPercentage && (
                    <span className={css.discount}>
                      {couponInfo.coupon.durationInMonths
                        ? t('user:form.price.coupon.discountInMonths', { discountPercentage: couponInfo.coupon.discountPercentage / 100, count: couponInfo.coupon.durationInMonths })
                        : t('user:form.price.coupon.discountForever', { discountPercentage: couponInfo.coupon.discountPercentage / 100 })}
                    </span>
                  )}

                </dt>
                <dl>{t('formats:euro', { value: couponInfo.coupon.discountAmount })}</dl>
              </div>
            )}

            {/* When coupon isn't set */}
            {!couponInfo?.coupon && editable && (
              <div className={css.row}>
                <dt>{t('user:form.price.breakdown.coupon')}</dt>
                <dd className={css.couponInput}>

                <div>
                  <input
                    type="text"
                    placeholder={t('user:form.price.breakdown.coupon')}
                    {...register('couponCode')}
                  />
                  {!validateCouponMutation.isPending && validateCouponMutation.isError && !couponInfo?.error && <small>{t('forms:errors.unknown')}</small>}
                  {!validateCouponMutation.isPending && !validateCouponMutation.isError && couponInfo?.error && <small>{t(`forms:errors.coupon.${couponInfo.error}`)}</small>}
                </div>

                <Button onClick={validateCoupon} disabled={validateCouponMutation.isPending}>
                  {validateCouponMutation.isPending && <ActivityIndicator size="xs" variant="auto" />}
                  {t('user:form.price.coupon.redeem')}
                </Button>

                </dd>
              </div>
            )}

            {/* Tax */}
            <div className={css.row}>
              <dt>{t('user:form.price.breakdown.tax', { taxRate: (couponInfo?.coupon?.taxRate ?? product.taxRate) / 100 })}</dt>
              <dl>{t('formats:euro', { value: couponInfo?.coupon?.taxAmount ?? product.taxAmount })}</dl>
            </div>

            {/* Total */}
            <div className={css.row}>
              <dt>{t('user:form.price.breakdown.total')}</dt>
              <dl>{t('formats:euro', { value: totalPrice })}</dl>
            </div>
          </dl>

        </div>
          <FormActions
            cancelEditing={cancelEditing}
            visible={reviewMode && editable}
          />
        </form>
      </section>
    </FormProvider>
  );
});

export default KostenForm;
