function format(value, formatType, lng)
{
  try 
  {
    switch (formatType)
    {
      default:
        return value;

      case 'intlDate': {
        const v = typeof value === 'string' ? new Date(value) : value;
        return new Intl.DateTimeFormat(lng, { day: '2-digit', month: '2-digit', year: 'numeric' }).format(v);
      }

      case 'intlWeekday': {
        const v = typeof value === 'string' ? new Date(value) : value;
        return new Intl.DateTimeFormat(lng, { weekday: 'long' }).format(v);
      }

      case 'intlYear': {
        const v = typeof value === 'string' ? new Date(value) : value;
        return new Intl.DateTimeFormat(lng, { year: 'numeric' }).format(v);
      }

      case 'intlTime': {
        const v = typeof value === 'string' ? new Date(value) : value;
        return new Intl.DateTimeFormat(lng, { hour: 'numeric', minute: 'numeric' }).format(v);
      }

      case 'intlDateLong': {
        const v = typeof value === 'string' ? new Date(value) : value;
        return new Intl.DateTimeFormat(lng, { day: '2-digit', month: 'long', year: 'numeric' }).format(v);
      }

      case 'intlDateTime': {
        const v = typeof value === 'string' ? new Date(value) : value;
        const opts = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' };
        return new Intl.DateTimeFormat(lng, opts).format(v);
      }

      case 'kurzAdresse': {
        if (!value.ort && !value.plz) return null;
        return `, ${value.plz ?? ''}${value.ort && value.plz ? ' ' : ''}${value.ort ?? ''}`;
      }

      case 'address': {
        return value.plz === '-' ? `${value.straße}, ${value.stadt}` : `${value.straße}, ${value.plz} ${value.stadt}`;
      }

      case 'localeNumber': {
        return (value).toLocaleString(lng);
      }

      case 'germanLocaleNumber': {
        return (value).toLocaleString('de-DE');
      }

      case 'percent': {
        return value.toLocaleString(lng, { style: 'percent', maximumFractionDigits: 2 });
      }

      case 'euro': {
        return value.toLocaleString(lng, { style: 'currency', currency: 'EUR' });
      }

      case 'statistics': {
        const v = parseInt(value, 10);

        if (v < 1000) 
        {
          return `${v}`;
        }

        if (v < 10_000) 
        {
          const s = v.toString();
          return `${s.charAt(0)}.${s.substring(1)}`;
        }

        if (v < 1_000_000) 
        {
          return `${parseInt(v / 1000, 10)} Tsd`;
        }

        return `${(v / 1_000_000).toFixed(2)} Mio`;
      }

      case 'stichtag': {
        const v = new Date(`2020-${value}`);
        return new Intl.DateTimeFormat(lng, { day: '2-digit', month: '2-digit' }).format(v);
      }

      case 'firmenbuchNummer': {
        return `FN ${value.trim()}`;
      }

      case 'arrayList': {
        return value.reduce((list, val, index) => 
        {
          switch (index) 
          {
            case 0:
              return `'${val}'`;
            case value.length - 1:
              return `${list} und '${val}'`;
            default:
              return `${list}, '${val}'`;
          }
        }, '');
      }

    }
  }
  catch (error) 
  {
    return '-';
  }
}

module.exports = format;