import { Anchor, Button, LabeledInput, PremiumCta, Show } from 'components/atoms';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Bundesland, UnternehmensStatus } from 'types';
import { useTranslation, useUserTier } from 'utils/hooks';
import { useShowSearch } from 'utils/hooks/state';

import css from './Detailsuche.module.scss';

interface UnternehmensDetailsucheProps
{
  defaultValues?: Partial<UnternehmensDetailsucheFields>;
}

interface UnternehmensDetailsucheFields
{
  name: string;
  fbNr: string;
  rechtsform: string;
  plz: string;
  ort: string;
  adresse: string;
  status: string;
  bundesland: Bundesland | '';
  branche: string;
}

const resetValues: UnternehmensDetailsucheFields = {
  name: '',
  fbNr: '',
  rechtsform: '',
  plz: '',
  ort: '',
  adresse: '',
  status: '',
  bundesland: '',
  branche: '',
};

const UnternehmensDetailsuche: React.FC<UnternehmensDetailsucheProps> = (props) =>
{
  const { defaultValues } = props;
  const { t } = useTranslation(['common', 'rechtsformen', 'search', 'forms']);
  const router = useRouter();
  const tier = useUserTier();
  const { setShowSearch } = useShowSearch();

  const {
    handleSubmit,
    register,
    reset,
    watch,
  } = useForm<UnternehmensDetailsucheFields>({ defaultValues, mode: 'onChange', reValidateMode: 'onChange' });

  useEffect(() => reset(defaultValues), [defaultValues]);

  const formWatch = watch();
  const rechtsformen = t('rechtsformen:list', { returnObjects: true }) as string[];
  const bundesländer = t('bundesländer:list', { returnObjects: true }) as string[];
  const branchen = t('branchen:list', { returnObjects: true }) as string[];

  const onSubmit: SubmitHandler<UnternehmensDetailsucheFields> = (filters) =>
  {
    const sanitized: Record<string, string> = {};

    Object.entries(filters).forEach(([key, value]) =>
    {
      if (value && value.length > 0)
      {
        sanitized[key] = value;
      }
    });

    if (Object.keys(sanitized).length > 0)
    {
      setShowSearch(false);
      const query = new URLSearchParams({ ...sanitized, view: 'unternehmen', page: '1', limit: '15', scroll: 'results' });
      router.push(`/suche/d?${query}`, undefined, { shallow: false });
    }
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={css.form}
      >

        {/* Name */}
        <LabeledInput
          id="name"
          label={t('search:wizard.unternehmen.name.label')}
          className={css.grid2}
        >
          <input
            type="text"
            placeholder={t('search:wizard.unternehmen.name.placeholder')}
            {...register('name')}
          />
        </LabeledInput>

        {/* FbNr */}
        <LabeledInput
          id="fbNr"
          label={t('search:wizard.unternehmen.fbNr.label')}
          className={css.grid1}
        >
          <input
            type="text"
            placeholder={t('search:wizard.unternehmen.fbNr.placeholder')}
            {...register('fbNr')}
          />
        </LabeledInput>

        {/* Status */}
        <LabeledInput
          id="status"
          label={t('search:wizard.unternehmen.status.label')}
          className={css.grid1}
        >
          <select {...register('status')}>
            <option value="">{t('search:wizard.actions.select')}</option>
            <option value={UnternehmensStatus.Aktiv}>{t('common:profiles.status.Aktiv')}</option>
            <option value={UnternehmensStatus.Gelöscht}>{t('common:profiles.status.Gelöscht')}</option>
            <option value={UnternehmensStatus.InLiquidation}>{t('common:profiles.status.InLiquidation')}</option>
          </select>
        </LabeledInput>

        {/* Rechtsform */}
        <LabeledInput
          id="rechtsform"
          label={t('search:wizard.unternehmen.rechtsform.label')}
          className={css.grid2}
        >
          <select {...register('rechtsform')}>
            <option value="">{t('search:wizard.actions.select')}</option>
            {Object.entries(rechtsformen).map(([key, value]) => (
              <option key={key} value={key}>{value}</option>
            ))}
          </select>
        </LabeledInput>

        {/* Branche */}
        <LabeledInput
          id="branche"
          label={t('search:wizard.unternehmen.branche.label')}
          className={css.grid2}
        >
          <select {...register('branche')}>
            <option value="">{t('search:wizard.actions.select')}</option>
            {Object.entries(branchen).sort((a, b) => a[1].localeCompare(b[1])).map(([key, value]) => (
              <option key={key} value={key}>{value}</option>
            ))}
          </select>
        </LabeledInput>

        {/* Bundesland */}
        <LabeledInput
          id="bundesland"
          label={t('search:wizard.unternehmen.bundesland.label')}
          className={css.grid1}
        >
          <select {...register('bundesland')}>
            <option value="">{t('search:wizard.actions.select')}</option>
            {Object.entries(bundesländer).map(([key, value]) => (
              <option key={key} value={key}>{value}</option>
            ))}
          </select>
        </LabeledInput>

        {/* Adresse */}
        <LabeledInput
          id="sitz"
          label={t('search:wizard.unternehmen.address.label')}
          className={css.grid3}
        >
          <div className={css.sitz}>

            <input
              type="text"
              placeholder={t('forms:address.zip.placeholder')}
              {...register('plz')}
            />

            <input
              type="text"
              placeholder={t('forms:address.city.placeholder')}
              {...register('ort')}
            />

            <input
              type="text"
              placeholder={t('forms:address.adress.placeholder')}
              {...register('adresse')}
            />

          </div>
        </LabeledInput>

        <div className={css.grid4}>
          <div className={css.actions}>
            <Anchor onClick={() => reset({ ...resetValues })}>{t('search:wizard.actions.reset')}</Anchor>
            <Button type="submit" disabled={tier !== 'plus' || !Object.values(formWatch).some(v => !!v)}>
              {t('search:wizard.actions.search')}
            </Button>
          </div>
        </div>

      </form>

      <Show when={tier !== 'plus'}>
        <PremiumCta />
      </Show>
    </>
  );
};

export default UnternehmensDetailsuche;
