export type ProductKey = 'PremiumPlanMonthlyPlus' | 'PremiumPlanYearlyPlus';

export const AboMonatlich: Product = {
  id: '1',
  name: 'PLUS',
  fullName: 'PLUS Monatsabo',
  price: 59.00,
  taxRate: 20,
  taxAmount: 11.80,
  category: 'monatliche Zahlung',
};

export const AboJährlich: Product = {
  id: '2',
  name: 'PLUS',
  fullName: 'PLUS Jahresabo',
  price: 590.00,
  taxRate: 20,
  taxAmount: 118.00,
  category: 'jährliche Zahlung',
};

export const Direktkauf: Product = {
  id: '3',
  name: 'PLUS Direkt',
  fullName: 'PLUS Direkt',
  price: 2.83,
  taxRate: 20,
  taxAmount: 0.57,
  category: 'Direktkauf',
};

export interface Product
{
  id: string;
  name: string;
  fullName: string;
  price: number;
  taxRate: number;
  taxAmount: number;
  category: string;
}

const PRODUCTS: Record<ProductKey, Product> = {
  'PremiumPlanMonthlyPlus': AboMonatlich,
  'PremiumPlanYearlyPlus': AboJährlich,
};

export default PRODUCTS;