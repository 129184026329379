import { IconName } from 'components/atoms/Icon/Icon';
import getConfig from 'next/config';
import { TFunction } from 'next-i18next';
import { Fragment } from 'react';
import { AdresseRM, FormErrors, ISO8601Date, Referenz, ReferenzType } from 'types';
import useCustomTranslation, { RichTextComponent, TranslationKey } from 'utils/hooks/useTranslation';

export function deserializeQueryParam(param: string | string[] | undefined): undefined | string 
{
  return Array.isArray(param) ? param[0] : param;
}

export function scrollToAnchor(id: string, offset: number = 0, smooth: boolean = true) 
{
  const element = document.getElementById(id);
  const yOffset = (element?.getBoundingClientRect().top || 0) + window.scrollY + offset;
  window.scrollTo({ top: yOffset, behavior: smooth ? 'smooth' : 'auto' });
}

export function getRuntimeEnvVar(key: string): string | undefined 
{
  const { publicRuntimeConfig } = getConfig();
  return publicRuntimeConfig ? publicRuntimeConfig[key] : undefined;
}

export function getHostname(): string 
{
  const scope = getRuntimeEnvVar('DEPLOYMENT_SCOPE')?.toUpperCase();
  switch (scope) 
  {
    case 'LOCAL':
      return `http://${process.env.NEXT_PUBLIC_URL_LOCAL}`;
    case 'DEV':
      return `https://${process.env.NEXT_PUBLIC_URL_DEV}`;
    case 'TEST':
      return `https://${process.env.NEXT_PUBLIC_URL_TEST}`;
    case 'STAGE':
      return `https://${process.env.NEXT_PUBLIC_URL_STAGE}`;
    case 'LIVE':
      return `https://${process.env.NEXT_PUBLIC_URL_LIVE}`;
    default:
      return `https://${process.env.NEXT_PUBLIC_URL_LIVE}`;
  }
}

export function isTestEnvironment(): boolean 
{
  const scope = getRuntimeEnvVar('DEPLOYMENT_SCOPE')?.toUpperCase();
  switch (scope) 
  {
    case 'LOCAL':
    case 'DEV':
    case 'TEST':
      return true;
    case 'STAGE':
    case 'LIVE':
      return false;
    default:
      return false;
  }
}

export function getCookieUrl(): string 
{
  const scope = getRuntimeEnvVar('DEPLOYMENT_SCOPE')?.toUpperCase();
  switch (scope) 
  {
    case 'LOCAL':
      return `${process.env.NEXT_PUBLIC_URL_LOCAL}`;
    case 'DEV':
      return `.${process.env.NEXT_PUBLIC_URL_DEV}`;
    case 'TEST':
      return `.${process.env.NEXT_PUBLIC_URL_TEST}`;
    case 'STAGE':
      return `.${process.env.NEXT_PUBLIC_URL_STAGE}`;
    case 'LIVE':
    default:
      return `.${process.env.NEXT_PUBLIC_URL_LIVE?.replace('www.', '')}`;
  }
}

export function groupArrayBy<T>(items: T[], key: string): Record<string, T[]> 
{
  return items.reduce((result: Record<string, T[]>, item: T) => ({
    ...result, [(item as any)[key]]: [...(result[(item as any)[key]] || []), item],
  }), {});
}

export function setEmptyValuesNull(obj: Record<string, any>) 
{
  const replaced = JSON.stringify(obj).replace(/\:\"\"/g, ':null');
  return JSON.parse(replaced);
}

export function clamp(num: number, min: number, max: number) 
{
  return Math.min(Math.max(num, min), max);
}

type TranslationContent = {
  title: string;
  content: Array<string | TranslationContent>;
};

export function renderContentBlock(
  blockKey: TranslationKey,
  translationHook: ReturnType<typeof useCustomTranslation>,
  richComponents: Record<string, RichTextComponent> = {},
  headingLevel: number = 2,
  mode: 'li' | 'p' = 'li',
): JSX.Element[] 
{
  const { t, r } = translationHook;

  const dom: JSX.Element[] = [];
  const content: JSX.Element[] = [];

  const block = t(blockKey, { returnObjects: true }) as TranslationContent | TranslationContent[];

  if (Array.isArray(block)) 
  {
    return block.reduce(
      (prev, _, index) => [...prev, ...renderContentBlock(`${blockKey}.${index}` as unknown as TranslationKey, translationHook, richComponents, headingLevel, mode)],
      [] as JSX.Element[],
    );
  }

  if (block.title) 
  {
    const BlockHeading = `h${headingLevel}` as keyof JSX.IntrinsicElements;
    const blockId = block.title.toLowerCase().replace(/ /g, '-');
    dom.push(<BlockHeading key={blockId} id={blockId}>{block.title}</BlockHeading>);
  }
  else 
  {
    const key = `${blockKey}.text` as unknown as TranslationKey;
    const rich = r(key, richComponents);
    dom.push(<div key={key}>{rich}</div>);
  }
  block.content.forEach((blockContent, index) => 
  {
    const key = `${blockKey}.content.${index}` as unknown as TranslationKey;

    if (typeof blockContent === 'string') 
    {
      const rich = r(key, richComponents);
      content.push(mode === 'li' ? <li key={key}>{rich}</li> : <div key={key}>{rich}</div>);
      return;
    }

    if (Array.isArray(blockContent)) 
    {
      content.push(<ol
                key={key}>{renderContentBlock(key, translationHook, richComponents, headingLevel + 1, mode)}</ol>);
      return;
    }

    content.push(<Fragment
            key={key}>{renderContentBlock(key, translationHook, richComponents, headingLevel + 1, mode)}</Fragment>);
  });

  dom.push(mode === 'li' ? <ol key={'dom.' + blockKey}>{content}</ol> :
        <Fragment key={'dom.' + blockKey}>{content}</Fragment>);
  return [<li key={'li.' + blockKey}>{dom}</li>];
}

export function getLinkFromReferenz(ref: Referenz) 
{
  const prefix = [ReferenzType.Unternehmen, ReferenzType.AusländischesUnternehmen].includes(ref.type) ? 'u' : 'p';
  return `/${prefix}/${ref.id}`;
}

export function getIconNameFromReferenz(ref: Referenz): IconName 
{
  switch (ref.type) 
  {
    case ReferenzType.Unternehmen:
      return 'building';
    case ReferenzType.AusländischesUnternehmen:
      return 'bank';
    case ReferenzType.NatürlichePerson:
      return 'person';
    case ReferenzType.JuristischePerson:
      return 'bank';
    default:
      return 'building';
  }
}

export function getNatPersonNameAndBirthdate(t: TFunction, name: string, geburtsdatum: ISO8601Date | null) 
{
  const formattedDate = geburtsdatum ? t('formats:date', { value: geburtsdatum }) : '-';
  const displayedDate = formattedDate !== '-' ? ` (${formattedDate})` : '';
  return `${name}${displayedDate}`;
}

export function getNatPersonNameAndBirthyear(t: TFunction, name: string, geburtsdatum: ISO8601Date | null) 
{
  const formattedDate = geburtsdatum ? t('formats:year', { value: geburtsdatum }) : '-';
  const displayedDate = formattedDate !== '-' ? ` (${formattedDate})` : '';
  return `${name}${displayedDate}`;
}

export function getGoogleMapsLink(adresse: AdresseRM) 
{
  return `https://maps.google.com/?q=${encodeURIComponent(adresse.full).replace(/%20/g, '+')}`;
}

export function filterServerSideErrors(serverSideErrors: FormErrors, filter: string) 
{
  const filtered: FormErrors = {};

  Object.entries(serverSideErrors).forEach((entry) => 
  {
    const [key, errors] = entry;
    if (key.startsWith(`${filter}.`)) 
    {
      filtered[key.substring(key.indexOf('.') + 1)] = errors;
    }
  });

  return filtered;
}

export function getCanonicalUrl(): string | undefined 
{
  return getRuntimeEnvVar('CANONICAL_URL');
}

export function copyQuery(query: Record<string, string | string[] | undefined>, paramsToKeep?: string[]) 
{
  const newQuery: Record<string, string> = {};

  if (!paramsToKeep) 
  {
    Object.keys(query).forEach((param) => 
    {
      newQuery[param] = query[param]!.toString();
    });
    return newQuery;
  }

  paramsToKeep.forEach((param) => 
  {
    if (query[param] !== undefined) 
    {
      newQuery[param] = query[param]!.toString();
    }
  });

  return newQuery;
}

export function importFromCss(css: { readonly [key: string]: string }) 
{
  return Object.entries(css).reduce<Record<string, number>>((parsed, [key, value]) => ({
    ...parsed,
    [key]: parseFloat(value),
  }), {});
}