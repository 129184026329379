/* eslint-disable consistent-return */
import { RefObject, useCallback, useLayoutEffect, useState } from 'react';
import { Size } from 'types';

function getSize(element: any): Size
{
  return element ? { width: element.offsetWidth, height: element.offsetHeight } : {};
}

function useComponentSize(ref: RefObject<any>, axis: 'x' | 'y' | 'xy' = 'xy'): Size
{
  const [ComponentSize, setComponentSize] = useState(getSize(ref ? ref.current : {}));

  const handleResize = useCallback(() =>
  {
    if (ref.current)
    {
      const size = getSize(ref.current);

      if (
        axis === 'xy'
        || (axis === 'x' && ComponentSize.width !== size.width)
        || (axis === 'y' && ComponentSize.height !== size.height)
      )
      {
        setComponentSize(size);
      }
    }
  },
  [ref]);

  useLayoutEffect(() =>
  {
    if (!ref.current)
    {
      return;
    }

    handleResize();

    if (typeof ResizeObserver === 'function')
    {
      const resizeObserver = new ResizeObserver(() =>
      {
        handleResize();
      });
      resizeObserver.observe(ref.current);
      return () => resizeObserver.disconnect();
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  },
  [ref.current]);

  return ComponentSize;
}

export default useComponentSize;