import clsx from 'clsx';
import { Icon } from 'components/atoms';
import { useRouter } from 'next/router';
import React from 'react';
import { getHostname } from 'utils';
import { copyToClipboard } from 'utils/functions';
import { useToastNotifications, useTranslation, useWindowSize } from 'utils/hooks';

import css from './ShareButtons.module.scss';

const windowFeatures: Record<string, string | number> = {
  width: 500,
  height: 800,
  left: 0,
  top: 0,
};

export interface ShareButtonsProps
{
  size?: 'small' | 'large';
}

const ShareButtons: React.FC<ShareButtonsProps> = (props) =>
{
  const { size = 'small' } = props;

  const router = useRouter();
  const { isMobile } = useWindowSize();
  const { t } = useTranslation('common');
  const [, addToastNotification] = useToastNotifications();

  const url = {
    plain: getHostname() + router.asPath,
    encoded: encodeURIComponent(getHostname() + router.asPath),
  };

  // Links to share services
  const facebookLink = `https://www.facebook.com/sharer/sharer.php?display=popup&u=${url.encoded}`;
  const whatsappLink = `https://web.whatsapp.com/send?text=${url.encoded}`;
  const whatsappMobileLink = `https://api.whatsapp.com/send/?phone&text=${url.encoded}&app_absent=0`;
  const linkedinLink = `https://linkedin.com/shareArticle?url=${url.encoded}`;
  const xingLink = `https://www.xing.com/spi/shares/new?url=${url.encoded}`;

  const parsedWindowFeatures = Object.entries(windowFeatures).map((feature) => `${feature[0]}=${feature[1]}`).join(',');

  function copyLinkToClipboard()
  {
    copyToClipboard(url.plain);
    addToastNotification(t('common:generic.copiedToClipboard'));
  }

  function openLinkInNewWindow(link: string)
  {
    window.open(link, '_blank', parsedWindowFeatures);
  }

  return (
    <div className={clsx(css.container, css[size])}>

      <span>{t('common:actions.share')}</span>

      {/* Copy Link */}
      <button
        onClick={() => copyLinkToClipboard()}
        className={clsx(css.button, css.copy, 'CopyLink')}
        aria-label={t('common:aria.share.copy')}
        type="button"
      >
        <Icon name="link" />
      </button>

      {/* Facebook */}
      <button
        onClick={() => openLinkInNewWindow(facebookLink)}
        className={clsx(css.button, css.facebook, 'Facebook')}
        aria-label={t('common:aria.share.facebook')}
        type="button"
      >
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z">Facebook</path>
        </svg>
      </button>

      {/* Whatsapp */}
      <button
        onClick={() => openLinkInNewWindow(isMobile ? whatsappMobileLink : whatsappLink)}
        className={clsx(css.button, css.whatsapp, 'Whatsapp')}
        aria-label={t('common:aria.share.whatsapp')}
        type="button"
      >
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413Z">Whatsapp</path>
        </svg>
      </button>

      {/* LinkedIn */}
      <button
        onClick={() => openLinkInNewWindow(linkedinLink)}
        className={clsx(css.button, css.linkedin, 'LinkedIn')}
        aria-label={t('common:aria.share.linkedin')}
        type="button"
      >
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z">LinkedIn</path>
        </svg>
      </button>


      {/* XING */}
      <button
        onClick={() => openLinkInNewWindow(xingLink)}
        className={clsx(css.button, css.xing, 'XING')}
        aria-label={t('common:aria.share.xing')}
        type="button"
      >
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M18.188 0c-.517 0-.741.325-.927.66 0 0-7.455 13.224-7.702 13.657.015.024 4.919 9.023 4.919 9.023.17.308.436.66.967.66h3.454c.211 0 .375-.078.463-.22.089-.151.089-.346-.009-.536l-4.879-8.916c-.004-.006-.004-.016 0-.022L22.139.756c.095-.191.097-.387.006-.535C22.056.078 21.894 0 21.686 0h-3.498zM3.648 4.74c-.211 0-.385.074-.473.216-.09.149-.078.339.02.531l2.34 4.05c.004.01.004.016 0 .021L1.86 16.051c-.099.188-.093.381 0 .529.085.142.239.234.45.234h3.461c.518 0 .766-.348.945-.667l3.734-6.609-2.378-4.155c-.172-.315-.434-.659-.962-.659H3.648v.016z">XING</path>
        </svg>
      </button>

    </div>
  );
};

export default ShareButtons;
