import { Button } from 'components/atoms';
import DialogWrapper from 'components/atoms/Dialog/Dialog';
import { useRouter } from 'next/router';
import React from 'react';
import { useTranslation } from 'utils/hooks';
import { useDeleteUserMutation } from 'utils/hooks/mutations';


import css from './UserDataForm.module.scss';


const DeleteAccountButton: React.FC = () => 
{
  const { t } = useTranslation('user');
  const router = useRouter();
  const deleteUserMutation = useDeleteUserMutation({});

  const [confirmationDialog, setConfirmationDialog] = React.useState(false);

  const handleDeleteAccount = async () => 
  {
    await deleteUserMutation.mutateAsync();
    await router.push('/logout');
  };

  return (
    <section className={css.container}>
      <Button
        type="button"
        variant="secondary"
        onClick={() => setConfirmationDialog(true)}
      >
        {t('user:form.actions.deleteAccount')}
      </Button>

      <DialogWrapper
        open={confirmationDialog}
        onOpenChange={setConfirmationDialog}
        header={t('user:form.deleteAccount.heading')}
        actions={
          <>
            <Button variant="secondary"
                    onClick={() => setConfirmationDialog(false)}>{t('user:form.actions.cancel')}</Button>
            <div className={css.dangerButton}><Button variant="primary" onClick={handleDeleteAccount}>{t('user:form.actions.deleteAccount')}</Button></div>
          </>
        }
      >
        <p>{t('user:form.deleteAccount.text')}</p>
      </DialogWrapper>
    </section>
  );
};

export default DeleteAccountButton;
