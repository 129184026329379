import * as Tabs from '@radix-ui/react-tabs';
import { ActivityIndicator } from 'components/atoms';
import React from 'react';
import { useTranslation } from 'utils/hooks';

import css from './TabTable.module.scss';

interface TabTableProps
{
  onTabChange?: (tabId: string) => void;
  defaultValue?: string;
  value?: string;
  loading?: boolean;
  tabs: {
    [key: string]: {
      label: string;
      columns: string[];
      data: any[];
      renderRow: (data: any) => JSX.Element;
      renderNoData?: () => JSX.Element;
    };
  };
}

const TabTable: React.FC<TabTableProps> = (props) =>
{
  const { onTabChange, defaultValue, value, tabs, loading } = props;
  const { t } = useTranslation('common');

  const content = Object.entries(tabs).map(([id, tab]) => (
    <Tabs.Content key={id} value={id} className={css.content}>

      <table className="profileTable">

        <thead>
          <tr>
            {tab.columns.map((column) => <th key={`${id}_${column}`}>{column}</th>)}
          </tr>
        </thead>

        <tbody>
          {(loading || (tab.data.length < 1 && tab.renderNoData)) && (
            <tr>
              <td colSpan={tab.columns.length}>
                {!loading && tab.renderNoData!()}
                {loading && (
                  <div className={css.loadingContainer}>
                    <ActivityIndicator variant="secondary" size="lg" />
                    <span>{t('common:generic.loadingData')}</span>
                  </div>
                )}
              </td>
            </tr>
          )}
          {!loading && tab.data.map((d) => tab.renderRow(d))}
        </tbody>

      </table>

    </Tabs.Content>
  ));

  return (
    <div className={css.container}>
      <Tabs.Root
        orientation="vertical"
        className={css.root}
        onValueChange={onTabChange}
        defaultValue={defaultValue ?? Object.keys(tabs)[0]}
        value={value}
      >
        
        <Tabs.List className={css.list}>
          {Object.entries(tabs).map(([id, tab]) => (
            <Tabs.Trigger
              className={css.trigger}
              key={id}
              value={id}
              asChild={true}
            >
              <div><span>{tab.label}</span></div>
            </Tabs.Trigger>
          ))}
        </Tabs.List>

        {content}
        
      </Tabs.Root>
    </div>
  );
};

export default TabTable;
