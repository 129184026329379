import clsx from 'clsx';
import React, { ReactNode } from 'react';
import { useTranslation } from 'utils/hooks';
import { useStatisticsQuery } from 'utils/hooks/queries';

import css from '../Startseite.module.scss';

interface Statistic 
{
  key: string;
  label: string | ReactNode | ReactNode[];
  value?: number;
  type: 'additive' | 'total' | 'subtractive';
}

const Statistics: React.FC = () => 
{
  const { t, r } = useTranslation(['startseite', 'formats']);
  const { data } = useStatisticsQuery(); 

  const statistics: Statistic[] = [
    {
      key: 'unternehmenChangesLastWeek',
      label: t('startseite:statistics.unternehmenChangesLastWeek'),
      type: 'total',
      value: data?.unternehmenChangesLastWeek,
    },
    {
      key: 'countUnternehmen',
      label: r('startseite:statistics.countUnternehmen'),
      type: 'total',
      value: data?.countUnternehmen,
    },
    {
      key: 'countPersonen',
      label: r('startseite:statistics.countPersonen'),
      type: 'total',
      value: data?.countPersonen,
    },
  ];

  function getFormattedValue(stat: Statistic) 
  {
    if (stat.value === undefined) return '0';

    const prefix = (() => 
    {
      switch (stat.type) 
      {
        case 'additive': return '+ ';
        case 'subtractive': return '- ';
        default: return '';
      }
    })();

    return `${prefix}${t('formats:statistic', { value: stat.value })}`;
  }

  return (
    <section className={css.section}>
      <ul className={clsx('selection-on-muted', css.statistics)}>
        {statistics.map((statistic) => (
          <li key={statistic.key} className={css.statistic}>
            <span className={css.value}>{getFormattedValue(statistic)}</span>
            <span className={css.label}>{statistic.label}</span>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default Statistics;
