import { keepPreviousData, useQuery, UseQueryResult } from '@tanstack/react-query';
import { createAxiosInstance } from 'api/request';
import { PaginatedResult } from 'types';
import { PersonalFeedItem } from 'types/änderungen';

type RequestReturnType = PaginatedResult<PersonalFeedItem>;

const request = async (page: number, limit: number, signal?: AbortSignal) =>
{
  const params = new URLSearchParams({
    page: page.toString(),
    limit: limit.toString(),
  });

  const response = await createAxiosInstance().get(`/feed?${params.toString()}`, { signal });
  return response.data;
};

export const BASE_QUERY_KEY = 'personalFeed';

const usePersonalFeedQuery = (page: number, limit: number): UseQueryResult<RequestReturnType, unknown> =>
{
  return useQuery(
    {
      queryKey: [BASE_QUERY_KEY, page, limit],
      queryFn: ({ signal }) => request(page, limit, signal),
      placeholderData: keepPreviousData,
    },
  );
};

export default usePersonalFeedQuery;