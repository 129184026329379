import React, { useEffect, useState } from 'react';

const useDebounce = <T>(initialValue: T, time: number): [T, T, React.Dispatch<T>, React.Dispatch<T>] => 
{
  const [value, setValue] = useState<T>(initialValue);
  const [debouncedValue, setDebouncedValue] = useState<T>(initialValue);

  function forceValue(v: T) 
  {
    setValue(v);
    setDebouncedValue(v);
  }

  useEffect(() => 
  {
    const debounce = setTimeout(() => 
    {
      setDebouncedValue(value);
    }, time);
    return () => clearTimeout(debounce);
  }, [value, time]);

  return [debouncedValue, value, setValue, forceValue];
};

export default useDebounce;