import * as Tooltip from '@radix-ui/react-tooltip';
import React, { useState } from 'react';

import css from './Tooltip.module.scss';

interface TooltipProps
{
  content: React.ReactNode;
  side?: 'top' | 'right' | 'bottom' | 'left';
  align?: 'start' | 'center' | 'end';
  children: React.ReactNode;
}

const TooltipComp: React.FC<TooltipProps> = (props) =>
{
  const { children, content, side, align } = props;

  const [open, setOpen] = useState(false);

  return (
    <Tooltip.Root delayDuration={0} open={open} onOpenChange={setOpen}>

      <Tooltip.Trigger asChild>
        <span onClick={() => setOpen(true)}>{children}</span>
      </Tooltip.Trigger>

      <Tooltip.Content className={css.content} side={side} align={align}>
        <Tooltip.Arrow className={css.arrow} />
        {content}
      </Tooltip.Content>

    </Tooltip.Root>
  );
};

export default TooltipComp;
