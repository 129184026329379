import { Anchor, LabeledInput } from 'components/atoms';
import React, { forwardRef, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { FormErrors } from 'types';
import { UserZugangsDaten } from 'types/user';
import { useTranslation, useUserForm } from 'utils/hooks';
import { UserFormSectionRef } from 'utils/hooks/useUserForm';

import FormActions from './FormActions';
import SectionHeading from './SectionHeading';
import css from './UserDataForm.module.scss';

export interface ZugangsdatenFormProps
{
  onFormSubmit: (data: any) => Promise<boolean>;
  initialValues?: UserZugangsDaten;
  reviewMode?: boolean;
  allowPasswordReview?: boolean;
  showHeading?: boolean;
  serverSideErrors?: FormErrors;
}

const ZugangsdatenForm = forwardRef<UserFormSectionRef, ZugangsdatenFormProps>((props, ref) =>
{
  const {
    reviewMode = false,
    initialValues = {},
    allowPasswordReview = true,
    onFormSubmit,
    serverSideErrors,
    showHeading = true,
  } = props;
  const { t, r } = useTranslation(['user', 'forms']);

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const parseDataOnSubmit = (data: UserZugangsDaten) => ({ zugangsDaten: data });
  
  const {
    editable,
    setEditable,
    cancelEditing,
    form: { handleSubmit, register, watch, formState: { errors } },
  } = useUserForm<UserZugangsDaten>({ ...initialValues }, reviewMode, serverSideErrors, ref, parseDataOnSubmit);

  const password = watch('password');
  const passwordRepeat = watch('passwordRepeat');

  const onSubmit: SubmitHandler<UserZugangsDaten> = async (data) =>
  {
    const success = await onFormSubmit(parseDataOnSubmit(data));
    if (success) setEditable(!reviewMode);
  };

  function calculatePasswordStrength(pw: string)
  {
    if (pw.length < 8) return t('forms:errors.passwordLength', { value: 8 });
    
    const lower = new RegExp(/[a-z]+/, 'g').test(pw);
    const upper = new RegExp(/[A-Z]+/, 'g').test(pw);
    const digit = new RegExp(/\d+/, 'g').test(pw);
    const symbol = new RegExp(/[\§\$\#\!\*\&\^\%\(\)\~\+\_\-\@\:\;]+/, 'g').test(pw);

    const prefix = 'user:form.credentials.passwordStrength';

    if (pw.length > 10 && lower && upper && digit && symbol)
    {
      return r(`${prefix}.strong`, { b: <b style={{ color: '#297c3b' }} /> });
    }

    if ((pw.length > 10 && lower) || (lower && upper))
    {
      return r(`${prefix}.medium`, { b: <b style={{ color: '#f76808' }} /> });
    }

    return r(`${prefix}.weak`, { b: <b style={{ color: '#db4324' }} /> });
  }

  return (
    <section id="zugangsdaten" className={css.container}>
      <form onSubmit={handleSubmit(onSubmit)}>

        {showHeading && (
          <SectionHeading
            text={t('user:form.credentials.heading')}
            editable={editable}
            setEditable={setEditable}
          />
        )}
        
        <div className={css.gridContainer}>

          {/* Name */}
          <LabeledInput
            id="vorname"
            label={t('user:form.contactData.name.label')}
            error={errors.name?.message}
            className={css.grid2}
          >
            <input
              type="text"
              readOnly={!editable}
              autoComplete="name"
              placeholder={t('user:form.contactData.name.placeholder')}
              {...register('name', {
                required: { value: true, message: t('forms:errors.required') },
              })}
            />
          </LabeledInput>

          {/* Email */}
          <LabeledInput
            id="email"
            label={t('user:form.contactData.email.label')}
            error={errors.email?.message}
            className={css.grid4}
          >
            <input
              type="email"
              readOnly={!editable}
              autoComplete="email"
              placeholder={t('user:form.contactData.email.placeholder')}
              {...register('email', {
                required: { value: true, message: t('forms:errors.required') },
              })}
            />
          </LabeledInput>

          {/* Passwort */}
          {editable && (
            <LabeledInput
              id="password"
              label={t('user:form.credentials.password.label')}
              error={errors.password?.message}
              info={calculatePasswordStrength(password || '')}
              className={css.grid2}
            >
              <input
                type="password"
                autoComplete="new-password"
                placeholder={t('user:form.credentials.password.placeholder')}
                {...register('password', {
                  required: t('forms:errors.required'),
                  minLength: {
                    value: 8,
                    message: t('forms:errors.passwordLength', { value: 8 }),
                  },
                })}
              /> 
            </LabeledInput>
          )}

          {/* Passwort Review*/}
          {!editable && (
            <LabeledInput
              id="password"
              label={t('user:form.credentials.password.label')}
              error={errors.password?.message}
              className={css.grid2}
            >
              <>
                <input
                  type="text"
                  readOnly
                  value={!showPassword ? '*'.repeat(password?.length || 8) : password}
                />
                {allowPasswordReview && (
                  <Anchor onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? t('user:form.credentials.hidePassword') : t('user:form.credentials.showPassword')}
                  </Anchor>
                )}
              </>
            </LabeledInput>
          )}


          {/* Passwort wiederholen */}
          {editable && (
            <LabeledInput
              id="passwordRepeat"
              label={t('user:form.credentials.passwordRepeat.label')}
              error={
                errors.passwordRepeat?.message ||
                (passwordRepeat && passwordRepeat.length > 0 && password !== passwordRepeat) ? t('forms:errors.passwordNotIdentical') : undefined}
              className={css.grid2}
            >
              <input
                type="password"
                autoComplete="new-password"
                placeholder={t('user:form.credentials.passwordRepeat.placeholder')}
                {...register('passwordRepeat', {
                  required: t('forms:errors.required'),
                  validate: {
                    identical: (val) => val === password ? true : t('forms:errors.passwordNotIdentical'), 
                  },
                })}
              />
            </LabeledInput>
          )}

        </div>

        <FormActions
          cancelEditing={cancelEditing}
          visible={reviewMode && editable}
        />

      </form>
    </section>
  );
});

export default ZugangsdatenForm;
