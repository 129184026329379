import React from 'react';

import Icon from '../Icon/Icon';

import css from './Disclaimer.module.scss';

interface DisclaimerProps 
{
  text: string;
  href?: string;
}

const Disclaimer: React.FC<DisclaimerProps> = (props) => 
{
  const { text, href } = props;

  return (
        <a id="disclaimer" className={css.disclaimer} href={href}>
            <Icon name="info"/>
            <p>{text}</p>
        </a>
  );
};

export default Disclaimer;
