import { Anchor, Button } from 'components/atoms';
import React from 'react';
import { useTranslation } from 'utils/hooks';

import css from './SearchWizard.module.scss';

interface ActionBarProps
{
  reset: () => void;
  minimize: () => void;
  disabled?: boolean;
}

const ActionBar: React.FC<ActionBarProps> = (props) =>
{
  const { minimize, reset, disabled } = props;
  const { t } = useTranslation('search');

  return (
    <div className={css.grid4}>
      <div className={css.actions}>
        <Anchor onClick={minimize}>{t('search:wizard.actions.minimize')}</Anchor>
        <Anchor onClick={reset}>{t('search:wizard.actions.reset')}</Anchor>
        <Button
          type="submit"
          disabled={disabled}
        >
          {t('search:wizard.actions.search')}
        </Button>
      </div>
    </div>
  );
};

export default ActionBar;
