import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { useAboIntent } from './index';

const useRedirectOnAboIntent = () =>
{
  const router = useRouter();
  const [aboIntent] = useAboIntent();

  useEffect(() =>
  {
    if (aboIntent && !router.pathname.startsWith('/user'))
    {
      router.push('/user/upgrade');
    }
  }, [aboIntent, router.pathname]);
};

export default useRedirectOnAboIntent;