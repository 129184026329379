import * as Dialog from '@radix-ui/react-dialog';
import clsx from 'clsx';
import { ActivityIndicator, Button, Icon, LabeledInput } from 'components/atoms';
import { RechnungsAdresseDirektkaufForm, RechtlichesForm } from 'components/user';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { ReferenzType } from 'types';
import { setEmptyValuesNull } from 'utils';
import {
  gtmDirektkaufAddToCart,
  gtmDirektkaufCheckout,
  gtmDirektkaufPurchase,
  gtmDirektkaufRemoveFromCart,
} from 'utils/events/google';
import { useTranslation, useUserForm } from 'utils/hooks';
import { useDirektkaufMutation } from 'utils/hooks/mutations';
import { useUserQuery } from 'utils/hooks/queries';
import useDirektkauf from 'utils/hooks/state/useDirektkauf';
import type { UserFormSectionRef } from 'utils/hooks/useUserForm';

import css from './Direktkauf.module.scss';

interface DirektkaufDialogProps
{
  id: string;
  type: ReferenzType;
}

type DirektkaufFormFields = {
  email: string;
};

const DirektkaufDialog: React.FC<DirektkaufDialogProps> = ({ id, type }) =>
{
  const { t, r } = useTranslation();
  const router = useRouter();

  const transaction = router.query.transaction?.toString();

  const user = useUserQuery();
  const email = user?.data?.kontaktDaten?.email;

  const [error, setError] = useState<string | undefined>();

  const [direktkauf, setDirektkauf] = useDirektkauf();

  const formRef = useRef<UserFormSectionRef | null>(null);
  const rechnungsAdresseRef = useRef<UserFormSectionRef | null>(null);
  const rechtlichesRef = useRef<UserFormSectionRef | null>(null);

  const { form: { register, formState: { errors } } } = useUserForm<DirektkaufFormFields>({}, false, {}, formRef, (d) => d);

  const mutation = useDirektkaufMutation({
    onSuccess: ({ url }) => router.push(url),
    onError: () => setError(t('forms:errors.unknown')),
  });

  useEffect(() =>
  {
    switch (direktkauf)
    {
      case 'info':
      {
        gtmDirektkaufAddToCart(id);
        break;
      }
      case 'buy':
      {
        gtmDirektkaufCheckout(id, 1);
        break;
      }
      case 'success':
      {
        gtmDirektkaufPurchase(id, transaction);
        break;
      }
      default: break;
    }
  }, [id, direktkauf, transaction]);

  const submit = async () =>
  {
    const refs = [rechnungsAdresseRef, formRef, rechtlichesRef];
    
    const sections = await Promise.all(refs.map((ref) => ref.current?.submit() ?? null));

    if (!sections.every((section) => section !== null))
    {
      return;
    }

    gtmDirektkaufCheckout(id, 2);

    let data = sections.reduce<Record<string, any>>((combined, current) => ({ ...combined, ...current }), {});
    data = setEmptyValuesNull(data);

    setError(undefined);
    mutation.mutate({
      profileId: id,
      profileType: type,
      email: data.email,
      rechnungsAdresse: data.rechnungsAdresse,
    });
  };

  const onDialogClose = () =>
  {
    switch (direktkauf)
    {
      case 'buy':
      {
        gtmDirektkaufRemoveFromCart(id);
        setDirektkauf(undefined);
        break;
      }
      case 'success':
      {
        setDirektkauf(undefined);
        router.replace({
          pathname: router.pathname,
          query: { id: router.query.id, token: router.query.token },
        });
      }
      default: setDirektkauf(undefined);
    }
  };

  return (
    <Dialog.Root open={!!direktkauf} onOpenChange={onDialogClose}>
      <Dialog.Portal>
        <Dialog.Overlay className={css.overlay}>
          <Dialog.Content className={css.content}>
            <Dialog.Close className={css.close}>
              <Icon name='close' />
            </Dialog.Close>

            {direktkauf === 'info' && (
              <>
                <h2>{t('direktkauf:info.title')}</h2>
                <p>{r('direktkauf:info.description')}</p>
                <p>{r('direktkauf:info.descriptionEmail')}</p>
                <div className={css.actions}>
                  <Button onClick={() => setDirektkauf('buy')}>
                    {t('direktkauf:info.next')}
                  </Button>
                </div>
              </>
            )}

            {direktkauf === 'buy' && (
              <>
                <h2>{t('direktkauf:buy.title')}</h2>
                <p className={css.legal}>{t('direktkauf:buy.description')}</p>

                {error && <p className={css.error}>{error}</p>}

                <div className={clsx('scrollbars', css.innerContent)}>
                  <form
                    className={css.form}
                    onSubmit={(e) =>
                    {
                      e.preventDefault();
                      submit();
                    }}
                  >
                    <LabeledInput
                      id="email"
                      label={t('direktkauf:form.email.label')}
                      error={errors.email?.message}
                    >
                      <input
                        type="email"
                        autoComplete="email"
                        placeholder={t('direktkauf:form.email.placeholder')}
                        {...register('email', {
                          required: t('forms:errors.required'),
                          pattern: {
                            value: /\S+@\S+\.\S+/,
                            message: t('forms:errors.invalidEmail'),
                          },
                        })}
                        defaultValue={email}
                      />
                    </LabeledInput>
                  </form>

                  <RechnungsAdresseDirektkaufForm
                    ref={rechnungsAdresseRef}
                    onFormSubmit={async () => true}
                  />

                  <hr />

                  <RechtlichesForm
                    ref={rechtlichesRef}
                    onFormSubmit={async () => true}
                    isDirektkauf
                  />

                  <div className={css.actions}>
                    <Button
                      onClick={submit}
                      disabled={mutation.isPending}
                    >
                      {mutation.isPending && <ActivityIndicator size='xs' variant='auto' />}
                      {t('direktkauf:buy.next')}
                    </Button>
                  </div>

                </div>
              </>
            )}

            {direktkauf === 'success' && (
              <>
                <h2 className={css.success}>
                  <Icon name='check' />
                  {t('direktkauf:success.title')}
                </h2>
                <p>{r('direktkauf:success.description')}</p>
                <div className={css.actions}>
                  <Button onClick={onDialogClose}>
                    {t('direktkauf:success.close')}
                  </Button>
                </div>
              </>
            )}

          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default DirektkaufDialog;
