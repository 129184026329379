import { signOut as signOutLocally } from 'next-auth/react';

export function downloadBlob(blob: Blob, filename: string)
{
  const objectURL = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = objectURL;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
}

export function partition<T>(array: T[], condition: (element: T) => boolean)
{
  return array.reduce(([pass, fail], elem) => (condition(elem) ? [[...pass, elem], fail] : [pass, [...fail, elem]]), [<T[]>[], <T[]>[]]);
}

export function copyToClipboard(value: string)
{
  if (navigator?.clipboard)
  {
    navigator.clipboard.writeText(value);
  }
}

export async function signOut({ signOutIdentityProvider = true })
{
  await signOutLocally();

  if (signOutIdentityProvider) 
  {
    window.location.assign('/api/auth/signout-idp');
  }
}
