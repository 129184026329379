import React from 'react';
import { useTranslation } from 'utils/hooks';

import css from './Details.module.scss';

interface DetailsProps 
{
  open?: boolean;
  text?: string;
  children?: React.ReactNode;
}

const Details: React.FC<DetailsProps> = (props) => 
{
  const { t } = useTranslation('common');
  const { open = false, text = t('common:actions.viewDetails'), children } = props;

  return (
        <details className={css.details} open={open}>

            {/* Summary Text */}
            <summary>{text}</summary>

            {/* Content */}
            <div className={css.content}>
                {children}
            </div>

        </details>
  );
};

export default Details;
