import clsx from 'clsx';
import Icon, { IconName } from 'components/atoms/Icon/Icon';
import Link from 'next/link';
import React from 'react';
import { UnternehmensStatus } from 'types';

import css from './QuickSearch.module.scss';

interface SearchResultProps 
{
  icon?: IconName;
  nameIndex?: number;
  status?: UnternehmensStatus | 'Umbenannt';
  isHighlighted?: boolean;
  itemProps?: any;
  text: string;
  href: string;
  onClick?: (href?: string) => void;
}

const SearchResult: React.FC<SearchResultProps> = (props) => 
{
  const {
    isHighlighted,
    text,
    href,
    status,
    itemProps,
    icon,
    nameIndex,
    onClick = () => 
    {
    },
  } = props;

  const link = (status === 'Umbenannt' && nameIndex !== undefined) ? `${href}?referral=${nameIndex}` : href;

  return (
        <Link legacyBehavior href={link} shallow={false} passHref>
            <a
                {...itemProps}
                onClick={() => onClick(link)}
                className={clsx(css.searchResult, { [css.highlighted]: isHighlighted })}
            >
        <span className={css.icon}>
          {!!icon && <Icon name={icon}/>}
        </span>
                <span>{text}</span>
            </a>
        </Link>
  );
};

export default SearchResult;
