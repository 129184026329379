import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'utils/hooks';

import css from './OrDivider.module.scss';

interface OrDividerProps
{
  variant?: 'default' | 'secondary';
}

const OrDivider: React.FC<OrDividerProps> = ({ variant = 'default' }) =>
{
  const { t } = useTranslation('common');

  return (
    <div className={clsx(css.textDivider, css[variant])}>
      <span>{t('common:or')}</span>
    </div>
  );
};

export default OrDivider;
