import React from 'react';

import css from './Störer.module.scss';

const Störer: React.FC<React.PropsWithChildren> = ({ children }) => (
  <div className={css.störer}>
    <div className={css.text}>
      {children}
    </div>
    <div className={css.shape} />
  </div>
);

export default Störer;
