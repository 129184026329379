import clsx from 'clsx';
import { Anchor } from 'components/atoms';
import React from 'react';
import { useTranslation } from 'utils/hooks';

import css from '../Startseite.module.scss';

const StepsCta: React.FC = () =>
{
  const { t } = useTranslation();
  return (
    <section className={clsx(css.section, css.stepsCta)}>
      <h2>{t('startseite:steps.heading')}</h2>
      <ol>
          {(t('startseite:steps.list', { returnObjects: true }) as string[]).map((item) => (
          <li key={item}>{item}</li>
          ))}
      </ol>

      <Anchor as="button" href="/produkte">
        {t('startseite:steps.cta')}
      </Anchor>
    </section>
  );
};

export default StepsCta;
