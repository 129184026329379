import { Content } from '@radix-ui/react-tabs';
import { LabeledInput } from 'components/atoms';
import React, { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { UnternehmensStatus } from 'types';
import { useTranslation } from 'utils/hooks';


import ActionBar from './ActionBar';
import { CategoryWizardProps } from './SearchWizard';
import css from './SearchWizard.module.scss';

type FirmenWizardFields =
{
  name: string;
  fbNr: string;
  rechtsform: string;
  plz: string;
  ort: string;
  status: string;
};

const resetValues: FirmenWizardFields = {
  name: '',
  fbNr: '',
  rechtsform: '',
  plz: '',
  ort: '',
  status: '',
};

const FirmenWizard: React.FC<CategoryWizardProps<FirmenWizardFields>> = (props) =>
{
  const { id, defaultValues, onMinimize } = props;
  const { t } = useTranslation(['common', 'rechtsformen', 'search', 'forms']);
  const {
    handleSubmit,
    register,
    reset,
    watch,
  } = useForm<FirmenWizardFields>({ defaultValues, mode: 'onChange', reValidateMode: 'onChange' });

  useEffect(() => reset(defaultValues), [defaultValues]);

  const formWatch = watch();
  const rechtsformen = t('rechtsformen:list', { returnObjects: true }) as string[];

  const onSubmit: SubmitHandler<FirmenWizardFields> = (filters) =>
  {
    props.onSubmit('unternehmen', filters);
  };

  return (
    <Content
      value={id}
      className={css.content}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={css.form}
      >

        {/* Name */}
        <LabeledInput
          id="name"
          label={t('search:wizard.unternehmen.name.label')}
          className={css.grid2}
        >
          <input
            type="text"
            placeholder={t('search:wizard.unternehmen.name.placeholder')}
            {...register('name')}
          />
        </LabeledInput>

        {/* FbNr */}
        <LabeledInput
          id="fbNr"
          label={t('search:wizard.unternehmen.fbNr.label')}
          className={css.grid2}
        >
          <input
            type="text"
            placeholder={t('search:wizard.unternehmen.fbNr.placeholder')}
            {...register('fbNr')}
          />
        </LabeledInput>

        {/* Rechtsform */}
        <LabeledInput
          id="rechtsform"
          label={t('search:wizard.unternehmen.rechtsform.label')}
          className={css.grid2}
        >
          <select {...register('rechtsform')}>
            <option value="">{t('search:wizard.actions.select')}</option>
            {Object.entries(rechtsformen).map(([key, value]) => (
              <option key={key} value={key}>{value}</option>
            ))}
          </select>
        </LabeledInput>

        {/* Status */}
        <LabeledInput
          id="status"
          label={t('search:wizard.unternehmen.status.label')}
          className={css.grid2}
        >
          <select {...register('status')}>
            <option value="">{t('search:wizard.actions.select')}</option>
            <option value={UnternehmensStatus.Aktiv}>{t('common:profiles.status.Aktiv')}</option>
            <option value={UnternehmensStatus.Gelöscht}>{t('common:profiles.status.Gelöscht')}</option>
            <option value={UnternehmensStatus.InLiquidation}>{t('common:profiles.status.InLiquidation')}</option>
          </select>
        </LabeledInput>

        {/* Adresse */}
        <LabeledInput
          id="sitz"
          label={t('search:wizard.unternehmen.address.label')}
          className={css.grid2}
        >
         <div className={css.sitz}>

            <input
              type="text"
              placeholder={t('forms:address.zip.placeholder')}
              {...register('plz')}
            />

            <input
              type="text"
              placeholder={t('forms:address.city.placeholder')}
              {...register('ort')}
            />

         </div>
        </LabeledInput>

        <ActionBar
          disabled={!Object.values(formWatch).some(v => !!v)}
          reset={() => reset({ ...resetValues })}
          minimize={onMinimize}
        />

      </form>
    </Content>
  );
};

export default FirmenWizard;
