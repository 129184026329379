import React, { useEffect } from 'react';

import css from './FullscreenPopup.module.scss';

const FullscreenPopup: React.FC<React.PropsWithChildren> = (props) =>
{
  const { children } = props;

  useEffect(() =>
  {
    document.body.classList.add('no-scroll');
    return () => { document.body.classList.remove('no-scroll'); };
  }, []);

  return (
    <div className={css.popup}>
      { children }
    </div>
  );
};

export default FullscreenPopup;
