import { Anchor } from 'components/atoms';
import React, { ReactNode } from 'react';
import { useTranslation } from 'utils/hooks';

import css from './UserDataForm.module.scss';

interface SectionHeadingProps 
{
  text: string | ReactNode | ReactNode[];
  editable?: boolean;
  istAusgefüllt?: boolean;
  setEditable?: (value: boolean) => void;
  subText?: string | ReactNode | ReactNode[];
}

const SectionHeading: React.FC<SectionHeadingProps> = (props) => 
{
  const { text, editable = true, istAusgefüllt = true, setEditable = () => {}, subText = null } = props;
  const { t } = useTranslation('user');

  return (
    <div className={css.sectionHeading}>
      <div>
        <h2>{text}</h2>
        {subText && <span>{subText}</span>}
      </div>
      {!editable && <Anchor onClick={() => setEditable(true)}>{istAusgefüllt ? t('user:form.actions.edit') : t('user:form.actions.fill')}</Anchor>}
    </div>
  );
};

export default SectionHeading;
