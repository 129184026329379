import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { copyQuery } from 'utils';

const useDirektkaufTokenMemory = () =>
{
  const router = useRouter();

  useEffect(() =>
  {
    const direktkaufSuccess = router.query.direktkaufSuccess?.toString();
    const tokenExpiresAt = router.query.expiresAt?.toString();
    const token = router.query.token?.toString();
    const profileId = router.query.id?.toString();

    if (!profileId)
    {
      return;
    }

    if (direktkaufSuccess === 'true' && token && tokenExpiresAt)
    {
      localStorage.setItem(`wss.direktkauf.${profileId}`, `${tokenExpiresAt};${token}`);
      return;
    }

    const tokenMemory = localStorage.getItem(`wss.direktkauf.${profileId}`);

    if (tokenMemory && !token)
    {
      const [memoryExpiresAt, memoryToken] = tokenMemory.split(';');
      const isTokenExpired = new Date().getTime() > new Date(memoryExpiresAt).getTime();
      if (!isTokenExpired)
      {
        router.replace({
          pathname: router.pathname,
          query: { ...copyQuery(router.query), token: memoryToken },
        });
      }
    }

  }, [router]);
};

export default useDirektkaufTokenMemory;
