import clsx from 'clsx';
import { Anchor, Icon } from 'components/atoms';
import {
  FilterGroupsPersonen,
  FilterGroupsUnternehmen,
  FilterBlocksPersonen,
  FilterBlocksUnternehmen,
} from 'components/profiles/ZeitlicherVerlauf/FilterBlocks';
import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'utils/hooks';
import { TranslationKey } from 'utils/hooks/useTranslation';

import css from './FilterList.module.scss';

interface FilterListProps<T extends (FilterGroupsUnternehmen | FilterGroupsPersonen)>
{
  onActiveFiltersChange: (filterElements: T[]) => void;
  filterType: 'unternehmen' | 'person';
  toggleAble?: boolean;
  activeFilters: T[];
}

function FilterList <T extends (FilterGroupsUnternehmen | FilterGroupsPersonen)>({ onActiveFiltersChange, filterType, toggleAble = false, activeFilters }: FilterListProps<T>)
{
  const { t } = useTranslation(['profiles']);
  const filterBlocks = filterType === 'unternehmen' ? FilterBlocksUnternehmen : FilterBlocksPersonen;
  const [show, setShow] = useState(!toggleAble);

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => 
  {

    const { name, checked } = event.target;
    const k = name as T;

    const index = activeFilters.indexOf(k, 0);

    const newActiveFilters = (()=>
    {
      if (index != -1 && checked)
      {
        return activeFilters.filter(f => f != k);
      }
      if (index == -1 && !checked)
      {
        return [...activeFilters, k];
      }
      return activeFilters;
    })();
    onActiveFiltersChange(newActiveFilters);
  };

  const filterKeys = Object.keys(filterBlocks);
  let even = 0;
  let odd = -1;
  return (
    <>
    {toggleAble && (<div className={css.filterButtonContainer}>
    <button onClick={()=>setShow(!show)} className={clsx(css.filterButton, { [css.selected]:show })}>
      <div className={css.flexLink}>{show ? t('filter.hideFilter') : t('filter.showFilter')}<Icon name={show ? 'filterFill' : 'filterLine'} /></div>
    </button>
    </div>)}
    {show
      && (
      <div className={css.filterContainer}>
        <form className={css.filterForm}>
          {filterKeys.map((key, index) =>
          {
            const firstHalf = index < Math.ceil(filterKeys.length / 2);
            odd = firstHalf ? odd + 2 : odd;
            even = firstHalf ? even : even + 2;
            return (
              <label key={key} className={css.label} style={{ order: firstHalf ? odd : even }}>
                <>
                  <input
                    type="checkbox"
                    name={key}
                    checked={!activeFilters.includes(key as T)}
                    className={clsx('toggle', css.toggle)}
                    onChange={handleCheckboxChange}
                  />
                  {
                  t(`filter.${filterType}.${key}` as TranslationKey)}
                </>
              </label>
            );
          },
          )}
        </form>
        <div className={css.toggleButtonsContainer}>
          <Anchor variant="secondary" onClick={() => onActiveFiltersChange([])}>{t('filter.showAll')}</Anchor>
          <span> | </span>
          <Anchor variant="secondary" onClick={() => onActiveFiltersChange(filterKeys as T[])}>{t('filter.showNone')}</Anchor>
        </div>
      </div>
      )
    }
    </>
  );

}

export default FilterList;