import { useState } from 'react';

/**
 * Hook wrapping the browser's storage options
 * Supports LocalStorage and SessionStorage
 * @param key Key of the item in storage
 * @param initialValue Initial value (is returned before setter is called) 
 * @param storageType Use LocalStorage or SessionStorage
 * @returns Getter and Setter for value
 */
const useBrowserStorage = <T>(
  key: string,
  initialValue: T,
  storageType: 'localStorage' | 'sessionStorage' = 'sessionStorage',
) =>
{
  const [storedValue, setStoredValue] = useState<T>(() => 
  {
    try 
    {
      const item = window[storageType].getItem(key);
      return item ? JSON.parse(item) : initialValue;
    }
    catch (error) 
    {
      return initialValue;
    }
  });
  
  const setValue = (value: T | ((val: T) => T) | undefined) =>
  {
    try 
    {
      if ( value === undefined ) 
      {
        window[storageType].removeItem(key);
        return;
      }
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window[storageType].setItem(key, JSON.stringify(valueToStore));
    }
    catch (error) 
    {
      console.error(error);
    }
  };
  
  return [storedValue, setValue] as const;
};

export default useBrowserStorage;