import React from 'react';

export const PlusPageContext = React.createContext<boolean>(false);

interface PlusPageProviderProps
{
  value?: boolean;
  children: React.ReactNode;
}

export const PlusPageProvider: React.FC<PlusPageProviderProps> = ({ children, value }) =>
{
  return (
    <PlusPageContext.Provider value={value || false}>
      {children}
    </PlusPageContext.Provider>
  );
};