import { useEffect, useState } from 'react';

type Consent = {
  preferences: boolean;
  statistics: boolean;
  marketing: boolean;
};

const defaultConsent: Consent = {
  preferences: false,
  statistics: false,
  marketing: false,
};

function useCookieConsent() 
{
  const [consent, setConsent] = useState<Consent>(defaultConsent);

  const acceptStatistics = () => 
  {
    (window as any).Cookiebot.submitCustomConsent(consent.preferences, true, consent.marketing);
    setConsent((current) => ({ ...current, statistics: true }));
  };

  useEffect(() => 
  {
    function handleAccept() 
    {
      const cookiebotConsent = (window as any)?.Cookiebot?.consent ?? null;
      if (cookiebotConsent === null) 
      {
        setConsent(defaultConsent);
        return;
      }

      const { preferences, statistics, marketing } = cookiebotConsent;
      setConsent({ preferences, statistics, marketing });
    }

    window.addEventListener('CookiebotOnAccept', handleAccept);
    handleAccept();

    return () => window.removeEventListener('CookiebotOnAccept', handleAccept);
  }, []);

  return { ...consent, acceptStatistics };
}

export default useCookieConsent;
