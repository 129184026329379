import { Anchor, Icon, Logo, Show } from 'components/atoms';
import { Avatar, NotificationCountBadge } from 'components/user';
import React from 'react';
import { usePaginationLimit, useTranslation, useUserTier } from 'utils/hooks';
import { useShowSearch } from 'utils/hooks/state';

import css from './Header.module.scss';

interface HeaderProps 
{
}

const Header: React.FC<HeaderProps> = () => 
{
  const { t } = useTranslation('common');
  const { showSearchButton, showSearch, setShowSearch } = useShowSearch();
  const limit = usePaginationLimit();
  const tier = useUserTier();

  return (
    <header className={css.header}>

      <div className={css.content}>
        <Anchor href="/" aria-label="wirtschaft.at">
          <Logo/>
        </Anchor>

        <nav className={css.anchors}>

          {tier !== 'plus' && <Anchor
              aria-label={t('common:user.preise')}
              href={'/produkte'}
          >
              <span>{t('common:user.preise')}</span>
          </Anchor> }

          <Show when={showSearchButton}>
            <button
              onClick={() => setShowSearch(!showSearch)}
              aria-label={t(showSearch ? 'common:header.hideSearch' : 'common:header.search')}
            >
              <span>{t(showSearch ? 'common:header.hideSearch' : 'common:header.search')}</span>
              <Icon name="search"/>
            </button>
          </Show>

          {tier !== 'free' && (<>
            <Anchor
              href={`/user/updates?page=1&limit=${limit}`}
              aria-label={t('common:user.feed')}
            >
              <span>
                <NotificationCountBadge/>
                {t('common:user.feed')}
              </span>
              <Icon name="radar"/>
            </Anchor>
            <Anchor
              href="/user/subscriptions"
              aria-label={t('common:user.mySubscriptions')}
            >
              <span>{t('common:user.mySubscriptions')}</span>
              <Icon name="star"/>
            </Anchor>
          </>)}

          <Avatar/>
        </nav>
      </div>

    </header>
  );
};

export default Header;
