import { useSession } from 'next-auth/react';
import { useMemo } from 'react';
import { UserStatus } from 'types/user';
import { useUserQuery } from 'utils/hooks/queries';


const useUserTier = (): 'free' | 'freemium' | 'plus' =>
{
  const { status : sessionStatus } = useSession();
  const { status: userStatus, data: user } = useUserQuery();

  return  useMemo(() => 
  {
    if (sessionStatus !== 'authenticated' || userStatus != 'success')
    {
      return 'free';
    }

    return [UserStatus.Freemium, UserStatus.FirstPaymentRequired].includes(user?.status) ? 'freemium' : 'plus';
  }, [sessionStatus, userStatus, user?.status]);
};

export default useUserTier;