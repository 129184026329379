import React from 'react';
import { Referenz } from 'types';
import { getIconNameFromReferenz, getLinkFromReferenz } from 'utils';
import { useMinimalUserInteraction } from 'utils/hooks';

import { IconName } from '../Icon/Icon';

interface ReferenzWrapperProps 
{
  data: Referenz;
  children: ({ ref, iconName, href }: { ref: Referenz; iconName: IconName; href: string }) => React.ReactElement | null;
}

const ReferenzWrapper = (props: ReferenzWrapperProps) => 
{
  const { data, children } = props;
  const mui = useMinimalUserInteraction();

  return children({
    ref: data,
    iconName: getIconNameFromReferenz(data),
    href: (data.isIndexed || mui) ? getLinkFromReferenz(data) : '#',
  });
};

export default ReferenzWrapper;
